var cms = {

  onSaveCallbacks : [],

  /**
   * @param selectors - object of arrays
   *                    {dateSelectors:[], timeSelectors:[]}
   */
  reinitDateTimePickers : function ( selectors, _format ) {
    var format = {
      dateFormat : 'dd/mm/yy',
      timeFormat : 'HH:mm:ss',
    };

    if ( _format !== undefined ) {
      format = _format;
    }

    this.destroyDateTimePickers ( selectors );
    this.initDateTimePickers ( selectors, format );
  },

  /**
   * @param selectors - object of arrays
   *                    {dateSelectors:[], timeSelectors:[]}
   */
  initDateTimePickers : function ( selectors, _format ) {
    var format = {
      dateFormat : 'dd/mm/yy',
      timeFormat : 'HH:mm:ss',
    };

    if ( _format !== undefined ) {
      format = _format;
    }
    // console.log(format);

    $( selectors.dateSelectors.join ( ', ' ) ).datepicker({
      dateFormat: format.dateFormat,
    });

    $( selectors.timeSelectors.join ( ', ' ) ).timepicker({
      controlType: 'select',
      oneLine: true,
  	  timeFormat: format.timeFormat,
      timezone: "+0000",
    });
  },

  /**
   * @param selectors - object of arrays
   *                    {dateSelectors:[], timeSelectors:[]}
   */
  destroyDateTimePickers : function ( selectors ) {
    $( selectors.dateSelectors.join ( ', ' ) ).datepicker ( 'destroy' );

    $( selectors.timeSelectors.join ( ', ' ) ).timepicker ( 'destroy' );
  },

  /**
   * @param data - object of values to save
   */
  editRecordProcess : function ( modelIdentifier, id, data ) {

    var DTPropName,
        prop;

    // - PROCESS TIMESTAMPS
    for ( prop in data ) {
      if ( prop.indexOf ( '-DTDate' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-DTDate' ) );
        data[DTPropName] = DateTime.human_to_mysql ( data[DTPropName + '-DTDate'], '/' ) + ' ' + data[DTPropName + '-DTTime'];
        delete data[DTPropName + '-DTDate'];
        delete data[DTPropName + '-DTTime'];
      }
    }

    // - PROCESS DURATIONS
    for ( prop in data ) {
      if ( prop.indexOf ( '-DurDays' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-DurDays' ) );
        data[DTPropName] = data[DTPropName + '-DurDays'] + ':' +
                           data[DTPropName + '-DurHours'] + ':' +
                           data[DTPropName + '-DurMinutes'] + ':00';
        delete data[DTPropName + '-DurDays'];
        delete data[DTPropName + '-DurHours'];
        delete data[DTPropName + '-DurMinutes'];
      }
    }

    // - REMOVE ANY HDCB VALUES
    for ( prop in data ) {
      if ( prop.indexOf ( '-hdcb' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-hdcb' ) );
        delete data[DTPropName + '-hdcb'];
      }
    }

    // console.log(modelIdentifier, id, data);

    $.ajax({
      url: '/AJAX_Cms/edit-record-process/' + modelIdentifier + '/' + id,
      type: 'post',
      data: data,
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log(returnedData);
        //returnedData = JSON.parse ( returnedData );
      },
    });
  },

  /**
   * @param data - object of values to save
   */
  addRecordProcess : function ( modelIdentifier, data ) {

    var DTPropName,
        prop;

    // - PROCESS TIMESTAMPS
    for ( prop in data ) {
      if ( prop.indexOf ( '-DTDate' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-DTDate' ) );
        data[DTPropName] = DateTime.human_to_mysql ( data[DTPropName + '-DTDate'], '/' ) + ' ' + data[DTPropName + '-DTTime'];
        delete data[DTPropName + '-DTDate'];
        delete data[DTPropName + '-DTTime'];
      }
    }

    // - PROCESS DURATIONS
    for ( prop in data ) {
      if ( prop.indexOf ( '-DurDays' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-DurDays' ) );
        data[DTPropName] = data[DTPropName + '-DurDays'] + ':' +
                           data[DTPropName + '-DurHours'] + ':' +
                           data[DTPropName + '-DurMinutes'] + ':00';
        delete data[DTPropName + '-DurDays'];
        delete data[DTPropName + '-DurHours'];
        delete data[DTPropName + '-DurMinutes'];
      }
    }

    // - REMOVE ANY HDCB VALUES
    for ( prop in data ) {
      if ( prop.indexOf ( '-hdcb' ) !== -1 ) {
        DTPropName = prop.substr ( 0, prop.indexOf ( '-hdcb' ) );
        delete data[DTPropName + '-hdcb'];
      }
    }

    //console.log('addRecordProcess', modelIdentifier, data);

    $.ajax({
      url: '/AJAX_Cms/add-record-process/' + modelIdentifier,
      type: 'post',
      data: data,
      dataType: 'text',
      success: function ( returnedData ) {
        //console.log(returnedData);
        returnedData = JSON.parse ( returnedData );

        if ( returnedData.success ) {
          // console.log('successfully added record', modelIdentifier);
          // console.log('returnedData', returnedData);

          if ( returnedData.added_id ) {
            cms.added_id = returnedData.added_id;
          }

          // - SHOW POSITIVE FLASH DATA
          main.flashData.show ( 'Record added successfully', 'positive' );

          cms.callOnSaveCallbacks();
        }
      },
    });
  },

  refreshSelect : function ( selectID, modelName, modelMethod, labelField, valueField, _onAddRefresh, _onAddRefreshArgs ) {
    // - modelMethod is optional, if undefined will get all records
    //console.log(selectID, modelName, modelMethod, labelField, valueField);

    var data = {
      modelName   : modelName,
      modelMethod : modelMethod,
      labelField  : labelField,
      valueField  : valueField
    };
    // console.log('refreshSelect', data);
    // console.log('#'+selectID);
    $.ajax({
      url: '/cms/AJAX_Cms/refresh-select/',
      data: data,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log(returnedData);
        returnedData = JSON.parse ( returnedData );
        // console.log(returnedData);

        $('#'+selectID).empty();
        // console.log(selectID);
        for ( var i = 0; i < returnedData.length; i++ ) {
          // console.log(returnedData[i].name);
          $('#'+selectID).append(
            $('<option></option>')
              .attr ( 'value', returnedData[i][valueField] )
              .text ( returnedData[i][labelField] )
          );
        }
        // console.log('_onAddRefresh', _onAddRefresh);
        if ( _onAddRefresh !== undefined ) {
          if ( _onAddRefreshArgs === undefined ) {
            _onAddRefresh();
          }
          else {
            _onAddRefresh ( _onAddRefreshArgs );
          }
        }

      }
    });
  },

  appendOnSaveCallback : function ( callback ) {
    //console.log('callback added to save');
    cms.onSaveCallbacks.push ( callback );
  },

  callOnSaveCallbacks : function() {
    for ( var i = 0; i < cms.onSaveCallbacks.length; i++ ) {
      cms.onSaveCallbacks[i]();
    }
  },

  clearOnSaveCallBacks : function() {
    cms.onSaveCallbacks = [];
  },

};
