var Institutions = {

  showAdd : function() {
    popup.generatePopup({
      title: 'Adding Organisation Record',
      method: 'ajax',
      ajaxUrl: '/AJAX_Cms/add-record/institution',
      maxWidth: 700,
      success: function() {
        cms.reinitDateTimePickers ({
          dateSelectors : ['.dt-picker',],
          timeSelectors : ['.tm-picker',],
        });
      },
    });
  },

  view : function ( id ) {
    popup.generatePopup({
      title: 'Viewing Organisation Record',
      method: 'ajax',
      ajaxUrl: '/AJAX_Cms/view-record/institution/' + id,
    });
  },

  edit : function ( id ) {
    popup.generatePopup({
      title: 'Editing Organisation Record',
      method: 'ajax',
      ajaxUrl: '/AJAX_Cms/edit-record/institution/' + id,
      success: function() {
        cms.reinitDateTimePickers ({
          dateSelectors : ['.dt-picker',],
          timeSelectors : ['.tm-picker',],
        });
      },
    });
  },

  delete : function ( id ) {
    popup.generatePopup({
      title: 'Deleting Organisation Record',
      method: 'ajax',
      ajaxUrl: '/AJAX_Cms/delete-record/institution/' + id,
    });
  },

};
