;(function( $ ){

	$.fn.select_by_value = function(value) {
		if($(this).prop('tagName').toLowerCase() === 'select') {
			$(this).find('option').each(function(index) {
				if( $(this).attr('value') === String ( value ) ) {
					$(this).prop('selected', true);
        }
			});
		}
		else {
			// console.log('this function can only be applied to select controls');
		}

		return this;
	};

  $.fn.multi_select_by_values = function(values) {
		if($(this).prop('tagName').toLowerCase() === 'select') {
			$(this).find('option').each(function(index, elem) {
        $(elem).prop('selected', false);

				if(values.indexOf($(this).attr('value')) !== -1) {
					$(this).prop('selected', true);
        }
			});
		}
		else {
			// console.log('this function can only be applied to select controls');
		}

		return this;
	};

	$.fn.sortOptions = function() {
		if($(this).prop('tagName').toLowerCase() === 'select') {
			var i, availableOptionsArr = [];
			$(this).find('option').each ( function ( index, elem ) {
				availableOptionsArr.push([
					$(elem).val(),
					$(elem).text()
				]);
			});

			availableOptionsArr.sort ( function ( a, b ) {
				if ( a[1] == b[1] ) {
					return 0;
				}
				return ( a[1] < b[1] ) ? -1 : 1;
			});

			$(this).empty();

			for ( i = 0; i < availableOptionsArr.length; i++ ) {
				$(this).append (
					$('<option></option>')
					  .attr ( 'value', availableOptionsArr[i][0] )
						.text ( availableOptionsArr[i][1] )
				);
			}
		}
		else {
			// console.log('this function can only be applied to select controls');
		}

		return this;
	};

	$.fn.preload = function() {
    this.each(function(){
        $('<img/>')[0].src = this;
    });
	};
	// - USAGE : $(['img1.jpg','img2.jpg','img3.jpg']).preload();

  $.fn.outerHtml = function() {
    return $('<div>').append($(this).clone()).html();
  };

  // - CHANGE CLASS, CHANGES A SINGLE CLASS TO ANOTHER ONE
  // - PARAM ONE - CLASS TO REPLACE
  // - PARAM TWO - CLASS REPLACING WITH
  $.fn.switchClass = function ( replaceClass, withClass ) {
    $(this).removeClass ( replaceClass )
           .addClass ( withClass );
  };

  // - TOGGLE BETWEEN 2 SPECIFIED CLASSES, GOOD FOR ON OFF SWITCHES
  $.fn.toggleClasses = function ( class1, class2 ) {
    $(this).toggleClass ( class1 )
           .toggleClass ( class2 );
  };

  // - HAS ATTRIBUTE
  $.fn.hasAttr = function ( attrName ) {
    return $(this)[0].hasAttribute ( attrName );
	};

  // - TEXT WIDTH REGARDLESS OF TAG
  $.fn.textWidth = function() {
    var text = $(this).html();
    $(this).html('<span>'+text+'</span>');
    var width = $(this).find('span:first').width();
    $(this).html(text);
    return width;
  };

  // - DATE RANGE CONTROL
  $.fn.dateRange = function ( initObj ) {
    $(this).addClass ( 'date-range' );

    if ( $(this).hasAttr ( 'id' ) ) {
      var id = $(this).attr('id');

      if ( initObj === undefined ) {
        initObj = {};
      }

      if ( initObj.mainLabel === undefined ) {
        initObj.mainLabel = 'Date Range:';
      }

      if ( initObj.dateFormat === undefined ) {
        initObj.dateFormat = 'dd/mm/yy';
      }

      if ( initObj.altFormat === undefined ) {
        initObj.altFormat = 'yy-mm-dd';
      }

      if ( $(this).prop('tagName').toLowerCase() === 'div' ) {
        $(this).append (
          $('<div></div>')
            .addClass ( 'group' )
            // - MAIN LABEL
            .append (
              $('<label></label>')
                .addClass ( 'date-range-label' )
                .addClass ( 'db fl 7/8 mb--' )
                .html ( initObj.mainLabel )
            )
            // - Reset Filter
            .append (
              $('<i></i>')
                .attr ( 'title', 'Reset this filter' )
                .addClass ( 'reset-date-range' )
                .addClass ( 'fa fa-history' )
                .addClass ( 'db fr 1/8 pr-- tr' )
                .on ( 'click', function ( evt ) {
                  $(this).closest ( '.date-range' )
                    .find ( '.date-range-filter.from input' ).val('');
                  $(this).closest ( '.date-range' )
                    .find ( '.date-range-filter.to input' ).val('');
                  $(this).closest ( '.date-range' )
                    .find ( '.alt-from' ).val('');
                  $(this).closest ( '.date-range' )
                    .find ( '.alt-to' ).val('');
                  $(this).closest ( '.date-range' )
                    .find ( '.alt-value' ).val('');
                })
            )
        );

        // - FILTER CONTAINER
        $(this).append (
          $('<div></div>')
            .addClass ( 'date-range-filter-container' )
            // - FROM
            .append (
              $('<label></label>')
                .attr ( 'for', id + '-from' )
                .addClass ( 'date-range-filter' )
                .addClass ( 'from' )
                .append (
                  'From:\n',
                  $('<input>')
                    .attr ( 'id', id + '-from' )
                )
            )
            // - TO
            .append (
              $('<label></label>')
                .attr ( 'for', id + '-to' )
                .addClass ( 'date-range-filter' )
                .addClass ( 'to' )
                .append (
                  'To:\n',
                  $('<input>')
                    .attr ( 'id', id + '-to' )
                )
            )
            // - HIDDEN INPUT THAT STORES A DATE FROM ALT FIELD VALUE
            .append (
              $('<input>')
                .attr ( 'type', 'hidden' )
                .attr ( 'id', id + '-alt-from' )
                .attr ( 'name', id + '-alt-from' )
                .addClass ( 'alt-from' )
            )
            // - HIDDEN INPUT THAT STORES A DATE TO ALT FIELD VALUE
            .append (
              $('<input>')
                .attr ( 'type', 'hidden' )
                .attr ( 'id', id + '-alt-to' )
                .attr ( 'name', id + '-alt-to' )
                .addClass ( 'alt-to' )
            )
            // - HIDDEN INPUT THAT STORES A DATE RANGE STRING
            .append (
              $('<input>')
                .attr ( 'type', 'hidden' )
                .attr ( 'id', id + '-value' )
                .attr ( 'name', id + '-value' )
                .addClass ( 'alt-value' )
            )
        );

        $(this).find('.date-range-filter.from input')
          .on ( 'change', function ( evt ) {
            // - SET THE HIDDEN FIELD
            $('#' + id + '-value').val (
              $(this).parent().parent().find('#' + id + '-alt-from').val() + '|' + $(this).parent().parent().find('#' + id + '-alt-to').val()
            );
            // - DESTROY THE DATEPICKER
            $(this).parent().parent().find('.date-range-filter.to input').datepicker ( 'destroy' );
            // - REINITIALISE THE DATEPICKER
            $(this).parent().parent().find('.date-range-filter.to input').datepicker({
              dateFormat : initObj.dateFormat,
              altField   : '#' + id + '-alt-to',
              altFormat  : initObj.altFormat,
              minDate    : $(this).val(),
            });
          });

        $(this).find('.date-range-filter.to input')
          .on ( 'change', function ( evt ) {
            // - SET THE HIDDEN FIELD
            $('#' + id + '-value').val (
              $(this).parent().parent().find('#' + id + '-alt-from').val() + '|' + $(this).parent().parent().find('#' + id + '-alt-to').val()
            );
            // - DESTROY THE DATEPICKER
            $(this).parent().parent().find('.date-range-filter.from input').datepicker ( 'destroy' );
            // - REINITIALISE THE DATEPICKER
            $(this).parent().parent().find('.date-range-filter.from input').datepicker({
              dateFormat : initObj.dateFormat,
              altField   : '#' + id + '-alt-from',
              altFormat  : initObj.altFormat,
              maxDate    : $(this).val(),
            });
          });

        $(this).find('.date-range-filter.from input').datepicker({
          dateFormat : initObj.dateFormat,
          altField   : '#' + id + '-alt-from',
          altFormat  : initObj.altFormat,
        });

        $(this).find('.date-range-filter.to input').datepicker({
          dateFormat : initObj.dateFormat,
          altField   : '#' + id + '-alt-to',
          altFormat  : initObj.altFormat,
        });

      }
    }
    else {
      // console.log('ERROR: ID is required');
    }
  };
  
  $.fn.replaceCB = function ( onClass, offClass ) {
    // console.log('applied', $(this).attr('id'));
		if ( $(this).prop('tagName').toLowerCase() === 'input' && $(this).attr('type') === 'checkbox' ) {
      var cb = this;
      // console.log($(this).prop('tagName'), $(this).attr('type'));
      var spanTag = $('<span></span>')
        .addClass ( 'cb-fa' );
      if ( $(this).prop('checked') ) {
        spanTag.addClass(onClass);
        spanTag.removeClass(offClass);
      }
      else {
        spanTag.addClass(offClass);
        spanTag.removeClass(onClass);
      }

      $(this).after ( spanTag );
      
      // $(cb).on ( 'change', function ( evt ) {
      //   console.log($(cb).prop('checked'));
      //   if ( $(cb).prop('checked') ) {
      //     $(cb).next('.cb-fa').addClass(onClass);
      //     $(cb).next('.cb-fa').removeClass(offClass);
      //     console.log('added', onClass);
      //     console.log('removed', offClass);
      //   }
      //   else {
      //     $(cb).next('.cb-fa').addClass(offClass);
      //     $(cb).next('.cb-fa').removeClass(onClass);
      //     console.log('added', offClass);
      //     console.log('removed', onClass);
      //   }
      // });

      $(cb).parent().on ( 'click', function ( evt ) {
        // console.log($(cb), $(cb).prop('checked'));
        if ( $(cb).prop('checked') ) {
          $(cb).next('.cb-fa').addClass(onClass);
          $(cb).next('.cb-fa').removeClass(offClass);
          // console.log('added', onClass);
          // console.log('removed', offClass);
        }
        else {
          $(cb).next('.cb-fa').addClass(offClass);
          $(cb).next('.cb-fa').removeClass(onClass);
          // console.log('added', offClass);
          // console.log('removed', onClass);
        }
      });
		}
		else {
			// console.log('this function can only be applied to checkbox input controls');
		}

		return this;
	};

})( jQuery );
