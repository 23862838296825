var NumberHelper = {
  addLeadingZeroes : function ( input, length ) {
    var output = '';
    
    if ( input < (Math.pow(10, length)) ) {
      for ( var i = 0; i < length - String(input).length; i++ ) {
        output += '0';
      }
    }
    
    output += input;
    
    return output;
  },
};