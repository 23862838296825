var changeTracker = {
  // - BOOL - ANY CHANGES DETECTED
  unpublishedChanges : false,
  // - ORIGINAL VALUE OF ELEMENT
  origValues : {},
  // - THIS PROPERTY WILL BE POPULATED WITH CHANGES THAT HAVE BEEN MADE, PROPERTY NAME WILL MATCH THE ID OF THE ELEMENT
  changesMade : {},
  // - MAKE A NOTE OF THE SELECTORS FOR USE IN STATIC FUNCTIONS
  selectors : null,
  // - EVENTS
  onChangeDetected : null,
  onNoChangeDetected : null,

  watchChanges : function ( selectors, onChangeDetected, onNoChangeDetected ) {
    changeTracker.selectors = selectors;
    changeTracker.onChangeDetected = onChangeDetected;
    changeTracker.onNoChangeDetected = onNoChangeDetected;

    $(changeTracker.selectors).each ( function ( index, elem ) {
      changeTracker.origValues[$(this).attr('id')] = $(this).val();
    })
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        changeTracker.changeDetected( $(this).attr ( 'id' ) );
      });
  },
  
  changeDetected : function ( id ) {
    // - IF THERE IS NO ENTRY, ADD IT
    if ( changeTracker.origValues[id] === $('#' + id).val() ) {
      delete changeTracker.changesMade[id];
    }
    else {
      changeTracker.changesMade[id] = {change:true, newValue:$('#' + id).val()};
    }
    // - THIS FUNCTION SHOULD FLAG UP WHEN LEAVING THAT CHANGES HAVE BEEN MADE
    changeTracker.unpublishedChanges = changeTracker.anyChangesMade();

    // - PERFORM THE APPROPRIATE EVENT
    if ( changeTracker.unpublishedChanges ) {
      changeTracker.onChangeDetected();
    }
    else {
      changeTracker.onNoChangeDetected();
    }
  },

  anyChangesMade : function() {
    // - ASSUME NO CHANGES TIL WE FIND THEM
    var output = false;
    // - GO THROUGH EACH ELEMENT...
    for ( var id in changeTracker.changesMade ) {
      // - GO THROUGH THE PROPERTIES OF THE ELEMENT...
      for ( var prop in changeTracker.changesMade[id] ) {
        // - IF THERE'S ONE CALLED CHANGE...
        if ( prop === 'change' ) {
          // - OUTPUT TRUE
          output = true;
          break;
        }
      }
    }

    return output;
  },
};