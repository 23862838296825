var lang = {

  selectorInit : function() {
    $('.language-selector li').off ( 'click' );
    $('.language-selector li').on  ( 'click', function ( evt ) {
      $.ajax({
        url: '/AJAX_admin/select-lang',
        data: {lang: $(this).attr ( 'data-lang' )},
        type: 'post',
        dataType: 'json',
        success: function ( returnedData ) {
          if ( returnedData.success ) {
            window.location.href = window.location.href;
          }
        },
      });
    });
  },

};

$(document).ready ( function ( evt ) {
  lang.selectorInit();
});
