var NHMath = {
  correctRoundedPercs : function foo(l, target) {
    // - NH EDIT, DOESN'T WORK IF THERE'S A ZERO AS IT WILL TURN IT INTO -1
    var arrCopy = l.join(',').split(',');
    // - CREATE ARRAY TO MAP NON-ZERO VALUES
    var map = [];
    for ( var j = 0; j < arrCopy.length; j++ ) {
      if ( arrCopy[j] != 0 ) {
        map.push(j);
      }
    }
    // - FILTER THE 0'S OUT
    l = l.filter(function(x) { return x != 0; });
    // console.log(l, map)

    var off = target - _.reduce(l, function(acc, x) { return acc + Math.round(x) }, 0);
    l = _.chain(l).
          sortBy(function(x) { return Math.round(x) - x }).
          map(function(x, i) { return Math.round(x) + (off > i) - (i >= (l.length + off)) }).
          value();
    
    // - USE MAP TO PUT THE PROCESSED VALUES BACK IN
    for ( j = 0; j < l.length; j++ ) {
      arrCopy[map[j]] = l[j];
    }
    return arrCopy;
  }
};