Athena.skins.ilearn = {
  showHeaderMarginTop : '30px',
  showHeaderTop : '80px',
  hideHeaderMarginTop : 0,
  hideHeaderTop : 0,

  init : function() {
    Athena.clickNextToContinue.customShow = function ( delay ) {
      TweenMax.to ( '.click-next-to-continue', 1, {
        bottom : '52px',
        ease: Back.easeOut,
        onUpdate : function() {
          if ( pxValue ( $('.click-next-to-continue').css('bottom') ) >= 65 ) {
            $('.click-next-to-continue').addClass ( 'in-front' );
            $('.click-next-to-continue').removeClass ( 'behind' );
          }
        },
        onComplete : function() {
          Athena.clickNextToContinue.showing = true;
        },
        delay : delay
      });
    };

    Athena.clickNextToContinue.customHide = function ( delay ) {
      TweenMax.to ( '.click-next-to-continue', 1, {
        bottom : '-90px',
        ease: Back.easeIn,
        onUpdate : function() {
          if ( pxValue ( $('.click-next-to-continue').css('bottom') ) >= 65 ) {
            $('.click-next-to-continue').addClass ( 'behind' );
            $('.click-next-to-continue').removeClass ( 'in-front' );
          }
        },
        onComplete : function() {
          Athena.clickNextToContinue.showing = false;
        },
        delay : delay
      });
    };
  },
};
