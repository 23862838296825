OC.enrol = {
  exLocID       : null,
  institutionID : null,
  siteID        : null,
  usersToEnrol  : {},
  mentor        : null,
  assessor      : null,
  tabs          : {},

  init : function ( exLocID, institutionID, siteID, tabs ) {
    OC.enrol.exLocID       = exLocID;
    OC.enrol.institutionID = institutionID;
    OC.enrol.siteID        = siteID;

    OC.enrol.tabs = tabs;

    OC.enrol.steps.init();
  },

  refreshEvents : function() {

  },

  steps : {

    current : 'step-1',
    currentIndex : 0,
    stepIDs : [
      'step-1',
      'step-2',
      'step-3',
    ],

    init : function() {
      // - tabs initiation

      // - tab 1
      OC.enrol.steps.arr['step-1'].init();

      // - tab 2
      if ( OC.enrol.tabs[3] ) {
        OC.enrol.steps.arr['step-2'].init();
      }
      else {
        OC.enrol.steps.arr['step-2'].removeStep();
      }

      // - tab 3
      if ( OC.enrol.tabs[4] ) {
        OC.enrol.steps.arr['step-3'].init();
      }
      else {
        OC.enrol.steps.arr['step-3'].removeStep();
      }

      OC.enrol.steps.setNextStepBtnFunction ( 'step-1' );
      OC.enrol.steps.setSubmitBtnFunction();

      OC.enrol.steps.hideAllBut ( 'step-1' );

      $('#next-step-btn').show();
      $('#back-step-btn').hide();
      $('#submit-btn').hide();

      $('.step[data-index!="0"]').hide();

      if ( OC.enrol.steps.stepIDs.length === 1 ) {
        OC.enrol.steps.removeStepsNav();
      }

      OC.enrol.refreshEvents();
    },

    arr : {
      ///////////////////////////////
      //          STEP 1           //
      ///////////////////////////////
      'step-1' : {
        id : 'step-1',

        nextStepFunction : function() {
          if ( dataTable.tables["DT-users-to-enrol"].tbl.api().rows({selected:true}).count() === 0 ) {
            main.flashData.show ( 'Please Select at least 1 user to enrol', 'negative', -1 );
          }
          else {
            OC.enrol.steps.nextStep();

            main.flashData.hide();
          }
        },

        backStepFunction : function() {
          OC.enrol.steps.backStep();
        },

        init : function() {
          // - DT-users-to-enrol
          dataTable.tables['DT-users-to-enrol'] = {
            tbl : $('#DT-users-to-enrol').dataTable({
              select : 'multi',
              language : {
                search : 'Filter Users',
              },
            }),
          };

          $('#DT-users-to-enrol tbody').on('click', 'tr', function () {
            var table = dataTable.tables["DT-users-to-enrol"].tbl;

            if ( $(this).hasClass ( 'selected' ) ) {
              OC.enrol.usersToEnrol[$(this).attr ( 'data-user-id' )] = {
                data : table.api().row(this).data(),
              };
            }
            else {
              delete OC.enrol.usersToEnrol[$(this).attr ( 'data-user-id' )];
            }

            OC.enrol.steps.arr['step-1'].updateUsersToEnrol();
          });

          // - DT-enrolled-users
          dataTable.tables['DT-enrolled-users'] = {
            tbl : $('#DT-enrolled-users').dataTable({
              language : {
                search : 'Filter Users',
              },
            }),
          };

          $('#DT-enrolled-users .edit-mentor-assessor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.editingMentorAndAssessorOfAttempt = {
                id : Number ( $(this).closest('tr').attr('data-attempt-id') ),
              };

              popup.generatePopup({
                title: 'Select a ' + terms.mentor + ' and an Assessor',
                method: 'ajax',
                ajaxUrl: '/AJAX-occupational-competence/select-buddy-assessor-popup',
                maxWidth: 1000,
                maxHeight: 600,
                data : {
                  tab3       : ( OC.enrol.steps.stepIDs.indexOf('step-2') !== -1 ),
                  tab4       : ( OC.enrol.steps.stepIDs.indexOf('step-3') !== -1 ),
                  mentorID   : $(this).closest('tr').attr('data-mentor'),
                  assessorID : $(this).closest('tr').attr('data-assessor'),
                },
                success: function(returnedData) {
                  // - ADD AN ACTION BUTTON TO THE POPUP
                  OC .enrol.steps.arr['step-1'].selectMentorsAssessorsRefreshEvents();
                },
              });
            });
        },

        onShow : function() {
          OC.enrol.steps.arr['step-1'].updateUsersToEnrol();
        },

        updateUsersToEnrol : function() {
          var table = dataTable.tables["DT-users-to-enrol"].tbl;
          var rowNode;

          $('#DT-users-to-enrol tr').each ( function ( index, elem ) {
            $(this).removeClass('selected');
          });

          for ( var prop in OC.enrol.usersToEnrol ) {
            if ( OC.enrol.usersToEnrol.hasOwnProperty ( prop ) ) {
              rowNode = table.api().row('tr[data-user-id="' + prop + '"]').node();
              $(rowNode).addClass('selected');
            }
          }
        },

        unsetMentorBtns : function() {
          $('#DT-OC-mentors-assessors .set-as-mentor-btn')
            .removeClass ( 'positive-btn' )
            .addClass ( 'neutral-btn' )
            .find ( 'i' )
              .removeClass ( 'fa-check-circle' )
              .addClass ( 'fa-circle' );

          OC.enrol.mentor = null;
        },

        unsetMentor : function() {
          $('.selected-mentor-assessor .selected-mentor-name').text('');
        },

        unsetAssessorBtns : function() {
          $('#DT-OC-mentors-assessors .set-as-assessor-btn')
            .removeClass ( 'positive-btn' )
            .addClass ( 'neutral-btn' )
            .find ( 'i' )
              .removeClass ( 'fa-check-circle' )
              .addClass ( 'fa-circle' );

          OC.enrol.assessor = null;
        },

        unsetAssessor : function() {
          $('.selected-mentor-assessor .selected-assessor-name').text('');
        },

        selectMentorsAssessorsRefreshEvents : function() {

          $('#DT-OC-mentors-assessors .set-as-mentor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-1'].unsetMentorBtns();

              $(this)
                .removeClass ( 'neutral-btn' )
                .addClass ( 'positive-btn' )
                .find ( 'i' )
                  .removeClass ( 'fa-circle' )
                  .addClass ( 'fa-check-circle' );

              OC.enrol.mentor = {
                id        : Number ( $(this).closest('tr').attr('data-id') ),
                firstName : $(this).closest('tr').find('td.first-name').html(),
                surname   : $(this).closest('tr').find('td.surname').html(),
              };

              $('.selected-mentor-name').html (
                OC.enrol.mentor.firstName + ' ' +
                OC.enrol.mentor.surname
              );

              var sendData = {
                mentorID : OC.enrol.mentor.id,
                attemptID : OC.enrol.editingMentorAndAssessorOfAttempt.id,
              };

              $.ajax({
                url: '/AJAX-occupational-competence/update-attempt-mentor',
                type: 'post',
                data: sendData,
                dataType: 'json',
                success: function ( returnedData ) {
                  // console.log(returnedData);
                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .find('td.mentor')
                        .text(returnedData.mentor.first_name + ' ' + returnedData.mentor.surname);

                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .attr('data-mentor', returnedData.mentor.id);
                },
              });
            });

          $('.selected-mentor-assessor .revoke-mentor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-1'].unsetMentorBtns();
              OC.enrol.steps.arr['step-1'].unsetMentor();

              var sendData = {
                mentorID : null,
                attemptID : OC.enrol.editingMentorAndAssessorOfAttempt.id,
              };

              $.ajax({
                url: '/AJAX-occupational-competence/update-attempt-mentor',
                type: 'post',
                data: sendData,
                dataType: 'json',
                success: function ( returnedData ) {
                  // console.log(returnedData);
                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .find('td.mentor')
                        .text('');

                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .attr('data-mentor', null);
                },
              });
            });

          $('#DT-OC-mentors-assessors .set-as-assessor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-1'].unsetAssessorBtns();

              $(this)
                .removeClass ( 'neutral-btn' )
                .addClass ( 'positive-btn' )
                .find ( 'i' )
                  .removeClass ( 'fa-circle' )
                  .addClass ( 'fa-check-circle' );

              OC.enrol.assessor = {
                id        : Number ( $(this).closest('tr').attr('data-id') ),
                firstName : $(this).closest('tr').find('td.first-name').html(),
                surname   : $(this).closest('tr').find('td.surname').html(),
              };

              $('.selected-assessor-name').html (
                OC.enrol.assessor.firstName + ' ' +
                OC.enrol.assessor.surname
              );

              var sendData = {
                assessorID : OC.enrol.assessor.id,
                attemptID : OC.enrol.editingMentorAndAssessorOfAttempt.id,
              };

              $.ajax({
                url: '/AJAX-occupational-competence/update-attempt-assessor',
                type: 'post',
                data: sendData,
                dataType: 'json',
                success: function ( returnedData ) {
                  // console.log(returnedData);
                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .find('td.assessor')
                        .text(returnedData.assessor.first_name + ' ' + returnedData.assessor.surname);

                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .attr('data-assessor', returnedData.assessor.id);
                },
              });
            });

          $('.selected-mentor-assessor .revoke-assessor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-1'].unsetAssessorBtns();
              OC.enrol.steps.arr['step-1'].unsetAssessor();

              var sendData = {
                assessorID : null,
                attemptID : OC.enrol.editingMentorAndAssessorOfAttempt.id,
              };

              $.ajax({
                url: '/AJAX-occupational-competence/update-attempt-assessor',
                type: 'post',
                data: sendData,
                dataType: 'json',
                success: function ( returnedData ) {
                  // console.log(returnedData);
                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .find('td.assessor')
                        .text('');

                  $('#DT-enrolled-users')
                    .find('tr[data-attempt-id="'+OC.enrol.editingMentorAndAssessorOfAttempt.id+'"]')
                      .attr('data-assessor', null);
                },
              });
            });
        },
      },
      ///////////////////////////////
      //          STEP 2           //
      ///////////////////////////////
      'step-2' : {
        id : 'step-2',

        nextStepFunction : function() {
          OC.enrol.steps.nextStep();
        },

        backStepFunction : function() {
          OC.enrol.steps.backStep();
        },

        init : function() {
          dataTable.tables['DT-potential-mentors'] = {
            tbl : $('#DT-potential-mentors').dataTable({
              language : {
                search : 'Filter Mentors',
              },
            }),
          };
        },

        onShow : function() {
          OC.enrol.steps.arr['step-2'].updatePotentialMentors (
            OC.enrol.institutionID, OC.enrol.siteID
          );
        },

        updatePotentialMentors : function ( institutionID, siteID ) {
          var sendData = {
            institutionID : institutionID,
            siteID : siteID,
          };

          $.ajax({
            url: '/AJAX-occupational-competence/get-mentors',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
              if ( returnedData.success ) {
                var i, rowNode;

                var setAsMentorClasses = 'set-as-mentor-btn gen-btn neutral-btn btn-bank';
                var setAsMentorBtn     = '<button class="' + setAsMentorClasses + '">' +
                                             '<i class="fa fa-circle"></i> Set as Mentor' +
                                           '</button>';

                var revokeMentorClasses = 'revoke-mentor-btn gen-btn negative-btn btn-bank';
                var revokeMentorBtn     = '<button class="' + revokeMentorClasses + '">' +
                                              '<i class="fa fa-times"></i>' +
                                            '</button>';

                var btns = '<div class="btn-bank-host">' +
                             setAsMentorBtn + '<!--' +
                             '-->' + revokeMentorBtn +
                           '</div>';

                dataTable.tables['DT-potential-mentors'].tbl.api().clear().draw();

                for ( i = 0; i < returnedData.mentors.length; i++ ) {
                  var id = returnedData.mentors[i].user_id;
                  var table = dataTable.tables['DT-potential-mentors'].tbl;

                  rowNode = table.api().row.add([
                    returnedData.mentors[i].first_name,
                    returnedData.mentors[i].surname,
                    returnedData.mentors[i].email,
                    btns,
                  ]).draw().node();

                  $(rowNode).attr ( 'data-id', id );
                }

                OC.enrol.steps.arr['step-2'].refreshBtns();

                if ( OC.enrol.mentor !== null ) {
                  $('#DT-potential-mentors tr[data-id="' + OC.enrol.mentor + '"]')
                    .find ( '.set-as-mentor-btn' )
                      .click();
                }
              }
            },
          });
        },

        unsetMentorBtns : function () {
          $('#DT-potential-mentors .set-as-mentor-btn')
            .removeClass ( 'positive-btn' )
            .addClass ( 'neutral-btn' )
            .find ( 'i' )
              .removeClass ( 'fa-check-circle' )
              .addClass ( 'fa-circle' );

          OC.enrol.mentor = null;
        },

        refreshBtns : function() {
          $('#DT-potential-mentors .set-as-mentor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-2'].unsetMentorBtns();

              $(this)
                .removeClass ( 'neutral-btn' )
                .addClass ( 'positive-btn' )
                .find ( 'i' )
                  .removeClass ( 'fa-circle' )
                  .addClass ( 'fa-check-circle' );

              OC.enrol.mentor = Number ( $(this).closest('tr').attr ( 'data-id' ) );
            });

          $('#DT-potential-mentors .revoke-mentor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-2'].unsetMentorBtns();
            });
        },

        removeStep : function() {
          for ( var i = 0; i < OC.enrol.steps.stepIDs.length; i++ ) {
            if ( OC.enrol.steps.stepIDs[i] === 'step-2' ) {
              OC.enrol.steps.stepIDs.splice(i,1);
              break;
            }
          }

          $('.steps-nav-container .step-icon[data-index=1]').prev().remove();
          $('.steps-nav-container .step-icon[data-index=1]').remove();
        },
      },
      ///////////////////////////////
      //          STEP 3           //
      ///////////////////////////////
      'step-3' : {
        id : 'step-3',

        nextStepFunction : function() {
          OC.enrol.steps.nextStep();
        },

        backStepFunction : function() {
          OC.enrol.steps.backStep();
        },

        init : function() {
          dataTable.tables['DT-potential-assessors'] = {
            tbl : $('#DT-potential-assessors').dataTable({
              language : {
                search : 'Filter Assessors',
              },
            }),
          };
        },

        onShow : function() {
          OC.enrol.steps.arr['step-3'].updatePotentialAssessors (
            OC.enrol.institutionID, OC.enrol.siteID
          );
        },

        updatePotentialAssessors : function ( institutionID, siteID ) {
          var sendData = {
            institutionID : institutionID,
            siteID : siteID,
          };

          $.ajax({
            url: '/AJAX-occupational-competence/get-assessors',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
              if ( returnedData.success ) {
                var i, rowNode;

                var setAsAssessorClasses = 'set-as-assessor-btn gen-btn neutral-btn btn-bank';
                var setAsAssessorBtn     = '<button class="' + setAsAssessorClasses + '">' +
                                             '<i class="fa fa-circle"></i> Set as Assessor' +
                                           '</button>';

                var revokeAssessorClasses = 'revoke-assessor-btn gen-btn negative-btn btn-bank';
                var revokeAssessorBtn     = '<button class="' + revokeAssessorClasses + '">' +
                                              '<i class="fa fa-times"></i>' +
                                            '</button>';

                var btns = '<div class="btn-bank-host">' +
                             setAsAssessorBtn + '<!--' +
                             '-->' + revokeAssessorBtn +
                           '</div>';

                dataTable.tables['DT-potential-assessors'].tbl.api().clear().draw();

                for ( i = 0; i < returnedData.assessors.length; i++ ) {
                  var id = returnedData.assessors[i].user_id;
                  var table = dataTable.tables['DT-potential-assessors'].tbl;

                  rowNode = table.api().row.add([
                    returnedData.assessors[i].first_name,
                    returnedData.assessors[i].surname,
                    returnedData.assessors[i].email,
                    btns,
                  ]).draw().node();

                  $(rowNode).attr ( 'data-id', id );
                }

                OC.enrol.steps.arr['step-3'].refreshBtns();

                if ( OC.enrol.assessor !== null ) {
                  $('#DT-potential-assessors tr[data-id="' + OC.enrol.assessor + '"]')
                    .find ( '.set-as-assessor-btn' )
                      .click();
                }
              }
            },
          });
        },

        unsetAssessorBtns : function () {
          $('#DT-potential-assessors .set-as-assessor-btn')
            .removeClass ( 'positive-btn' )
            .addClass ( 'neutral-btn' )
            .find ( 'i' )
              .removeClass ( 'fa-check-circle' )
              .addClass ( 'fa-circle' );

          OC.enrol.assessor = null;
        },

        refreshBtns : function() {
          $('#DT-potential-assessors .set-as-assessor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-3'].unsetAssessorBtns();

              $(this)
                .removeClass ( 'neutral-btn' )
                .addClass ( 'positive-btn' )
                .find ( 'i' )
                  .removeClass ( 'fa-circle' )
                  .addClass ( 'fa-check-circle' );

              OC.enrol.assessor = Number ( $(this).closest('tr').attr ( 'data-id' ) );
            });

          $('#DT-potential-assessors .revoke-assessor-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              OC.enrol.steps.arr['step-3'].unsetAssessorBtns();
            });
        },

        removeStep : function() {
          for ( var i = 0; i < OC.enrol.steps.stepIDs.length; i++ ) {
            if ( OC.enrol.steps.stepIDs[i] === 'step-3' ) {
              OC.enrol.steps.stepIDs.splice(i,1);
              break;
            }
          }

          $('.steps-nav-container .step-icon[data-index=2]').prev().remove();
          $('.steps-nav-container .step-icon[data-index=2]').remove();
        },

      },
    },

    setSubmitBtnFunction : function() {

      $('#submit-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {

          var sendData = {
            exLocID      : OC.enrol.exLocID,
            usersToEnrol : OC.enrol.usersToEnrol,
            mentor       : OC.enrol.mentor,
            assessor     : OC.enrol.assessor,
          };

          $.ajax({
            url: '/AJAX-occupational-competence/enrol',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse(returnedData);
              // console.log(returnedData);
              if ( returnedData.success ) {
                main.flashData.show ( 'Users enrolled successfully.', 'positive', 4 );
              }
            },
          });

        });

    },

    setNextStepBtnFunction : function ( stepID ) {
      $('#next-step-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          OC.enrol.steps.arr[stepID].nextStepFunction();
        });
    },

    nextStep : function() {

      OC.enrol.steps.tickStep ( OC.enrol.steps.current );

      OC.enrol.steps.hideStep ( OC.enrol.steps.current );
      OC.enrol.steps.currentIndex++;
      OC.enrol.steps.current = OC.enrol.steps.stepIDs[OC.enrol.steps.currentIndex];
      OC.enrol.steps.showStep ( OC.enrol.steps.current );

      OC.enrol.steps.stepChanged();
    },

    setBackStepBtnFunction : function ( stepID ) {
      $('#back-step-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          OC.enrol.steps.arr[stepID].backStepFunction();
        });
    },

    backStep : function() {

      OC.enrol.steps.untickStep ( OC.enrol.steps.current );

      OC.enrol.steps.hideStep ( OC.enrol.steps.current );
      OC.enrol.steps.currentIndex--;
      OC.enrol.steps.current = OC.enrol.steps.stepIDs[OC.enrol.steps.currentIndex];
      OC.enrol.steps.showStep ( OC.enrol.steps.current );

      OC.enrol.steps.stepChanged();
    },

    getIndex : function ( stepID ) {
      for ( var i = 0; i < OC.enrol.steps.stepIDs.length; i++ ) {
        if ( OC.enrol.steps.stepIDs[i] === stepID ) {
          return i;
        }
      }
    },

    stepChanged : function() {
      if ( OC.enrol.steps.arr[OC.enrol.steps.current].onShow !== undefined ) {
        OC.enrol.steps.arr[OC.enrol.steps.current].onShow();
      }

      var stepIndex = OC.enrol.steps.getIndex ( OC.enrol.steps.current );

      if ( stepIndex < OC.enrol.steps.stepIDs.length - 1 ) {
        $('#next-step-btn').show();
        $('#submit-btn').hide();

        OC.enrol.steps.setNextStepBtnFunction ( OC.enrol.steps.current );
      }
      else {
        $('#next-step-btn').hide();
        $('#submit-btn').show();
      }

      if ( stepIndex > 0 ) {
        $('#back-step-btn').show();

        OC.enrol.steps.setBackStepBtnFunction ( OC.enrol.steps.current );
      }
      else {
        $('#back-step-btn').hide();
      }
    },

    hideStep : function ( stepID ) {
      // console.log('.step[data-step-id="' + stepID + '"]');
      $('.step[data-step-id="' + stepID + '"]').hide();
    },

    showStep : function ( stepID ) {
      $('.step[data-step-id="' + stepID + '"]').show();

      $('.step-icon[data-step-id="' + stepID + '"] .step-status').attr (
        'src', '/assets/graphics/step-you-are-here.png'
      );

      var stepIndex = OC.enrol.steps.getIndex ( stepID );

      $('.step-icon').filter(function() {
        return $(this).attr("data-index") > stepIndex;
      })
      .find('.step-status')
      .attr (
        'src', '/assets/graphics/step-to-do.png'
      );
    },

    // - HIDE ALL BUT STEP (N)
    hideAllBut : function ( stepID ) {
      for ( var step in OC.enrol.steps.arr ) {
        if ( step !== stepID ) {
          $('#'+stepID).hide();
        }
      }
    },

    tickStep : function ( stepIndex ) {
      $('.step-icon[data-index="' + stepIndex + '"] .step-status').attr (
        'src', '/assets/graphics/step-tick.png'
      );

      if ( stepIndex > 0 ) {
        $('.step-icon[data-index="' + stepIndex + '"]').prev()
          .css ( 'border-color', '#76BC76' );
      }
    },

    untickStep : function ( stepIndex ) {
      $('.step-icon[data-index="' + stepIndex + '"] .step-status').attr (
        'src', '/assets/graphics/step-you-are-here.png'
      );

      if ( stepIndex > 0 ) {
        $('.step-icon[data-index="' + (stepIndex-1) + '"]').prev()
          .css ( 'border-color', '#CCC' );
      }
    },

    removeStepsNav : function() {
      $('.steps-nav-container').hide();
      $('#next-step-btn').hide();
      $('#submit-btn').show();
    },

  },
};
