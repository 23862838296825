Roles.assign = {
  roleID : null,
  usersToAssign : {},
  defaultDeadlineNumber : null,
  defaultDeadlineUnit : null,
  globalDeadlineDate : new DateNH(),

  run : function() {
    var sendData = {
      roleID : Roles.assign.roleID,
      users  : Roles.assign.getProcessedUsersToAssign(),
    };

    $.ajax({
      url: '/admin/AJAX-roles/assign-roles',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        if ( returnedData.success ) {
          main.flashData.show ( 'Users where successfully assigned to this role, redirecting...', 'positive', 4 );

          Roles.assign.steps.tickStep ( 2 );

          setTimeout ( function () {
            window.location.href = '/admin/roles';
          }, 4200 );
        }
      },
    });
  },

  copyObj : function ( obj ) {
    var copy = ObjectHelper.clone ( obj );

    for ( var id in copy ) {
      if ( copy[id].deadline.dateObj !== undefined ) {
        copy[id].deadline.dateObj = new DateNH ( copy[id].deadline.dateObj.value.getTime() );
      }
    }

    return copy;
  },

  deadline : {
    getDeadlinePeriodFromRow : function ( rowSelector ) {
      if ( $(rowSelector).find('.deadline-selector').val() === 'custom' ) {
        return '+' + $(rowSelector).find('.custom-deadline-number').val() +
               ' ' + $(rowSelector).find('.custom-deadline-unit').val();
      }
      else {
        return $(rowSelector).find('.deadline-selector').val();
      }
    },

    updateDeadlinePeriodAndDate : function ( obj, id, period ) {
      if ( obj[id] === undefined ) {
        obj[id] = {};
      }

      if ( obj[id].deadline === undefined ) {
        obj[id].deadline = {};
      }

      return {
        period  : Roles.assign.deadline.updateDeadlinePeriod ( obj, id, period ),
        dateObj : Roles.assign.deadline.updateDeadlineDate ( obj, id, period ),
      };
    },

    updateDeadlinePeriod : function ( obj, id, period ) {
      obj[id].deadline.period = period;

      return obj[id].deadline.period;
    },

    updateDeadlineDate : function ( obj, id, period ) {
      obj[id].deadline.dateObj = new DateNH();
      obj[id].deadline.dateObj.modify ( period );

      return obj[id].deadline.dateObj;
    },

    shortenDeadlinesOfUser : function ( userID ) {
      var id;
      // - GO THROUGH THE QUALS
      for ( id in Roles.assign.usersToAssign[userID].QUALS ) {
        // - IF THE USER GENERAL DEADLINE IS SHORTER THAN THE TRAINING ELEMENT DEADLINE, SHORTEN IT
        if ( Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value > Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].QUALS[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE OLs
      for ( id in Roles.assign.usersToAssign[userID].OL ) {
        if ( Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value > Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].OL[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE OCs
      for ( id in Roles.assign.usersToAssign[userID].OC ) {
        if ( Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value > Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].OC[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE ILTs
      for ( id in Roles.assign.usersToAssign[userID].ILT ) {
        if ( Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value > Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].ILT[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }
    },

    extendDeadlinesOfUser : function ( userID ) {
      var id;
      // - GO THROUGH THE QUALS
      for ( id in Roles.assign.usersToAssign[userID].QUALS ) {
        // - IF THE USER GENERAL DEADLINE IS SHORTER THAN THE TRAINING ELEMENT DEADLINE, SHORTEN IT
        if ( Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value !== Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].QUALS[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE OLs
      for ( id in Roles.assign.usersToAssign[userID].OL ) {
        if ( Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value !== Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].OL[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE OCs
      for ( id in Roles.assign.usersToAssign[userID].OC ) {
        if ( Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value !== Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].OC[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }

      // - GO THROUGH THE ILTs
      for ( id in Roles.assign.usersToAssign[userID].ILT ) {
        if ( Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value !== Roles.assign.usersToAssign[userID].deadline.dateObj.value ) {

          Roles.assign.usersToAssign[userID].ILT[id].deadline.period = '+' +  Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit;

          Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value.setTime (
            Roles.assign.usersToAssign[userID].deadline.dateObj.value.getTime()
          );
        }
      }
    },

    cutIndsToRole : function() {
      // console.log('cutIndsToRole');
      var userID, id;

      // - CHANGE THE GLOBAL OBJECT
      for ( id in Roles.assign.QUALS.arr ) {
        if ( Roles.assign.QUALS.arr[id].deadline.dateObj !== undefined && Roles.assign.QUALS.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
          Roles.assign.QUALS.arr[id].deadline.dateObj.value.setTime (
            Roles.assign.globalDeadlineDate.value.getTime()
          );

          Roles.assign.QUALS.arr[id].deadline.period = Roles.assign.globalDeadlinePeriod;
        }
      }

      for ( id in Roles.assign.OL.arr ) {
        if ( Roles.assign.OL.arr[id].deadline.dateObj !== undefined && Roles.assign.OL.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
          Roles.assign.OL.arr[id].deadline.dateObj.value.setTime (
            Roles.assign.globalDeadlineDate.value.getTime()
          );

          Roles.assign.OL.arr[id].deadline.period = Roles.assign.globalDeadlinePeriod;
        }
      }

      for ( id in Roles.assign.OC.arr ) {
        if ( Roles.assign.OC.arr[id].deadline.dateObj !== undefined && Roles.assign.OC.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
          Roles.assign.OC.arr[id].deadline.dateObj.value.setTime (
            Roles.assign.globalDeadlineDate.value.getTime()
          );

          Roles.assign.OC.arr[id].deadline.period = Roles.assign.globalDeadlinePeriod;
        }
      }

      for ( id in Roles.assign.ILT.arr ) {
        if ( Roles.assign.ILT.arr[id].deadline.dateObj !== undefined && Roles.assign.ILT.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
          Roles.assign.ILT.arr[id].deadline.dateObj.value.setTime (
            Roles.assign.globalDeadlineDate.value.getTime()
          );

          Roles.assign.ILT.arr[id].deadline.period = Roles.assign.globalDeadlinePeriod;
        }
      }

      // - GO THROUGH AND CHANGE FOR EACH USER TOO

      for ( userID in Roles.assign.usersToAssign ) {
        for ( id in Roles.assign.usersToAssign[userID].QUALS ) {
          if ( Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
            Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.value.setTime (
              Roles.assign.globalDeadlineDate.value.getTime()
            );

            Roles.assign.usersToAssign[userID].QUALS[id].deadline.period = Roles.assign.globalDeadlinePeriod;
          }
        }

        for ( id in Roles.assign.usersToAssign[userID].OL ) {
          if ( Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
            Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.value.setTime (
              Roles.assign.globalDeadlineDate.value.getTime()
            );

            Roles.assign.usersToAssign[userID].OL[id].deadline.period = Roles.assign.globalDeadlinePeriod;
          }
        }

        for ( id in Roles.assign.usersToAssign[userID].OC ) {
          if ( Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
            Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.value.setTime (
              Roles.assign.globalDeadlineDate.value.getTime()
            );

            Roles.assign.usersToAssign[userID].OC[id].deadline.period = Roles.assign.globalDeadlinePeriod;
          }
        }

        for ( id in Roles.assign.usersToAssign[userID].ILT ) {
          if ( Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
            Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.value.setTime (
              Roles.assign.globalDeadlineDate.value.getTime()
            );

            Roles.assign.usersToAssign[userID].ILT[id].deadline.period = Roles.assign.globalDeadlinePeriod;
          }
        }
      }

      return true;
    },

    extendRoleToLatestInd : function() {
      // console.log('extendRoleToLatestInd');

      var latestTime = Roles.assign.deadline.getLatestIndTrainingElementDeadline();

      Roles.assign.globalDeadlineDate.value.setTime (
        latestTime
      );

      return true;
    },

    getLatestIndTrainingElementDeadline : function() {
      var id, latestTime = 0;

      for ( id in Roles.assign.QUALS.arr ) {
        if ( Roles.assign.QUALS.arr[id].deadline.dateObj !== undefined && Roles.assign.QUALS.arr[id].deadline.dateObj.value.getTime() > latestTime ) {
          latestTime = Roles.assign.QUALS.arr[id].deadline.dateObj.value.getTime();
        }
      }

      for ( id in Roles.assign.OL.arr ) {
        if ( Roles.assign.OL.arr[id].deadline.dateObj !== undefined && Roles.assign.OL.arr[id].deadline.dateObj.value.getTime() > latestTime ) {
          latestTime = Roles.assign.OL.arr[id].deadline.dateObj.value.getTime();
        }
      }

      for ( id in Roles.assign.OC.arr ) {
        if ( Roles.assign.OC.arr[id].deadline.dateObj !== undefined && Roles.assign.OC.arr[id].deadline.dateObj.value.getTime() > latestTime ) {
          latestTime = Roles.assign.OC.arr[id].deadline.dateObj.value.getTime();
        }
      }

      for ( id in Roles.assign.ILT.arr ) {
        if ( Roles.assign.ILT.arr[id].deadline.dateObj !== undefined && Roles.assign.ILT.arr[id].deadline.dateObj.value.getTime() > latestTime ) {
          latestTime = Roles.assign.ILT.arr[id].deadline.dateObj.value.getTime();
        }
      }

      return latestTime;
    },
  },

  checkForDeadlineConflicts : function() {
    var conflicts = {};
    // - CONFLICTS BETWEEN ELEMENTS AND ROLE
    // - CHECK FOR ANY TRAINING ELEMENT DEADLINES THAT HANGS OVER THE END OF THE ROLE DEADLINE
    conflicts['elementsOver'] = Roles.assign.checkForOverhangingElementDeadlines();

    // - CONFLICTS BETWEEN USER ASSIGNMENT AND ELEMENTS / ROLES
    // - CHECK FOR ANY USER WHOSE DEADLINE IS SHORTER THAN ANY ROLE'S / ELEMENT'S
    conflicts['usersShortened'] = Roles.assign.checkForShortenedUserDeadlines();
    // - CHECK FOR ANY USER WHOSE DEADLINE IS EXTENDED BEYOND THE ROLE'S / ELEMENT'S
    conflicts['usersExtended'] = Roles.assign.checkForExtendedUserDeadlines();

    var any = false;

    if ( conflicts['elementsOver'].any || conflicts['usersShortened'].any || conflicts['usersExtended'].any ) {
      any = true;
    }

    return {
      any : any,
      conflicts : conflicts,
    };
  },

  checkForOverhangingElementDeadlines : function() {
    var id;
    var conflicts = {
      any : false,
      data : {
        QUALS : {},
        OL    : {},
        OC    : {},
        ILT   : {},
      },
    };

    // - GO THROUGH THE QUALS
    for ( id in Roles.assign.QUALS.arr ) {
      if ( Roles.assign.QUALS.arr[id].deadline.dateObj !== undefined && Roles.assign.QUALS.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
        // console.log( 'QUALS' );
        // console.log( id );
        // console.log( Roles.assign.QUALS.arr[id].deadline.dateObj.value );
        // console.log( Roles.assign.globalDeadlineDate.value );
        // console.log( '//////' );
        conflicts.data.QUALS[id] = true;
        conflicts.any = true;
      }
    }

    // - GO THROUGH THE OLs
    for ( id in Roles.assign.OL.arr ) {
      if ( Roles.assign.OL.arr[id].deadline.dateObj !== undefined && Roles.assign.OL.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
        // console.log( 'OL' );
        // console.log( id );
        // console.log( Roles.assign.OL.arr[id].deadline.dateObj.value );
        // console.log( Roles.assign.globalDeadlineDate.value );
        // console.log( '//////' );
        conflicts.data.OL[id] = true;
        conflicts.any = true;
      }
    }

    // - GO THROUGH THE OCs
    for ( id in Roles.assign.OC.arr ) {
      if ( Roles.assign.OC.arr[id].deadline.dateObj !== undefined && Roles.assign.OC.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
        // console.log( 'OC' );
        // console.log( id );
        // console.log( Roles.assign.OC.arr[id].deadline.dateObj.value );
        // console.log( Roles.assign.globalDeadlineDate.value );
        // console.log( '//////' );
        conflicts.data.OC[id] = true;
        conflicts.any = true;
      }
    }

    // - GO THROUGH THE ILTs
    for ( id in Roles.assign.ILT.arr ) {
      if ( Roles.assign.ILT.arr[id].deadline.dateObj !== undefined && Roles.assign.ILT.arr[id].deadline.dateObj.value > Roles.assign.globalDeadlineDate.value ) {
        // console.log( 'ILT' );
        // console.log( id );
        // console.log( Roles.assign.ILT.arr[id].deadline.dateObj.value );
        // console.log( Roles.assign.globalDeadlineDate.value );
        // console.log( '//////' );
        conflicts.data.ILT[id] = true;
        conflicts.any = true;
      }
    }

    return conflicts;
  },

  checkForShortenedUserDeadlines : function() {
    var cuts = {
      any : false,
      data : {},
    };

    for ( var id in Roles.assign.usersToAssign ) {
      if ( Roles.assign.usersToAssign[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[id].deadline.dateObj.value.getTime() < Roles.assign.globalDeadlineDate.value.getTime() - (24*60*60*1000) ) {
        // console.log(id);
        // console.log(Roles.assign.usersToAssign[id].deadline.dateObj.value);
        // console.log(Roles.assign.globalDeadlineDate.value);
        // console.log(Roles.assign.usersToAssign[id].deadline.dateObj.value - Roles.assign.globalDeadlineDate.value);
        // console.log( '//////' );
        cuts.data[id] = true;
        cuts.any = true;
      }
    }

    return cuts;
  },

  checkForExtendedUserDeadlines : function() {
    var extensions = {
      any : false,
      data : {},
    };

    for ( var id in Roles.assign.usersToAssign ) {
      if ( Roles.assign.usersToAssign[id].deadline.dateObj !== undefined && Roles.assign.usersToAssign[id].deadline.dateObj.value.getTime() > Roles.assign.globalDeadlineDate.value.getTime() + (24*60*60*1000) ) {
        // console.log(id);
        // console.log(Roles.assign.usersToAssign[id].deadline.dateObj.value);
        // console.log(Roles.assign.globalDeadlineDate.value);
        // console.log(Roles.assign.usersToAssign[id].deadline.dateObj.value + Roles.assign.globalDeadlineDate.value);
        // console.log( '//////' );
        extensions.data[id] = true;
        extensions.any = true;
      }
    }

    return extensions;
  },



  deadlineConflictContent : function() {
    return $('<div></div>')
            .addClass ( 'no-hyphenation' )
            .append (
              $('<p></p>')
                .addClass ( 'em' )
                .text ( 'Warning: Some of the deadlines set for individual training elements in this role are after the deadlines set for the overall role deadline.' )
            )
            .append (
              $('<img>')
                .attr ( 'src', '/assets/graphics/role-deadline-conflict-graphic.svg' )
            );
  },

  individualOverrides : {
    deadlines : {},
    customisations : {},
  },

  getProcessedIndividualOverrides : function() {
    var output = {};

    for ( var id in Roles.assign.individualOverrides.deadlines ) {
      output[id] = {
        period : Roles.assign.individualOverrides.deadlines[id].period,
        date   : Roles.assign.individualOverrides.deadlines[id].dateObj.format('Y-m-d'),
      };
    }

    return output;
  },

  getProcessedUsersToAssign : function() {
    var date, id, output = {}, period;

    for ( var userID in Roles.assign.usersToAssign ) {
      output[userID] = {
        firstName : Roles.assign.usersToAssign[userID].firstName,
        surname   : Roles.assign.usersToAssign[userID].surname,
        deadline  : {
          period  : Roles.assign.usersToAssign[userID].deadline.period,
        },
      };

      if ( Roles.assign.usersToAssign[userID].deadline.dateObj !== undefined ) {
        output[userID].deadline.date = Roles.assign.usersToAssign[userID].deadline.dateObj.format('Y-m-d');
      }

      output[userID].QUALS = {};
      for ( id in Roles.assign.usersToAssign[userID].QUALS ) {
        period = Roles.assign.usersToAssign[userID].QUALS[id].deadline.period;
        date   = null;
        if ( period !== '' ) {
          date = Roles.assign.usersToAssign[userID].QUALS[id].deadline.dateObj.format('Y-m-d');
        }

        output[userID].QUALS[id] = {
          deadline : {
            period : period,
            date   : date,
          },
        };
      }

      output[userID].OL = {};
      for ( id in Roles.assign.usersToAssign[userID].OL ) {
        period = Roles.assign.usersToAssign[userID].OL[id].deadline.period;
        date   = null;
        if ( period !== '' ) {
          date = Roles.assign.usersToAssign[userID].OL[id].deadline.dateObj.format('Y-m-d');
        }

        output[userID].OL[id] = {
          deadline : {
            period : period,
            date   : date,
          },
        };
      }

      output[userID].OC = {};
      for ( id in Roles.assign.usersToAssign[userID].OC ) {
        period = Roles.assign.usersToAssign[userID].OC[id].deadline.period;
        date   = null;
        if ( period !== '' ) {
          date = Roles.assign.usersToAssign[userID].OC[id].deadline.dateObj.format('Y-m-d');
        }

        output[userID].OC[id] = {
          deadline : {
            period : period,
            date   : date,
          },
          mentorUserID : Roles.assign.OC.arr[id].mentorUserID,
          assessorUserID : Roles.assign.OC.arr[id].assessorUserID,
        };
      }

      output[userID].ILT = {};
      for ( id in Roles.assign.usersToAssign[userID].ILT ) {
        period = Roles.assign.usersToAssign[userID].ILT[id].deadline.period;
        date   = null;
        if ( period !== '' ) {
          date = Roles.assign.usersToAssign[userID].ILT[id].deadline.dateObj.format('Y-m-d');
        }

        output[userID].ILT[id] = {
          deadline : {
            period : period,
            date   : date,
          },
        };
      }
    }

    return output;
  },

  init : function ( roleID, defaultDeadlineNumber, defaultDeadlineUnit ) {
    Roles.assign.roleID = roleID;

    Roles.assign.usersToAssign = {};

    if ( defaultDeadlineNumber !== undefined && defaultDeadlineUnit !== undefined ) {
      Roles.assign.defaultDeadlineNumber = defaultDeadlineNumber;
      Roles.assign.defaultDeadlineUnit   = defaultDeadlineUnit;
    }

    Roles.assign.steps.init();
    Roles.assign.steps.arr['step-1'].init();
    Roles.assign.steps.arr['step-2'].init();
    Roles.assign.steps.arr['step-3'].init();
  },
  convertDeadlinePeriod : function ( deadlineSelectVal, number, unit ) {
    return deadlineSelectVal === 'custom' ? '+' + number + ' ' + unit : '';
  },

  steps : new Steps ( Roles.assign, {
    'step-1' : {
      id : 'step-1',
      init: function() {
        // - THIS ONE RE-USES THE POOL FUNCTIONALITY IN THE ROLES BUILDER
        Roles.assign.QUALS.init();
        Roles.assign.QUALS.refreshEvents();
        Roles.assign.OC.init();
        Roles.assign.OC.refreshEvents();
        Roles.assign.ILT.init();
        Roles.assign.ILT.refreshEvents();
        Roles.assign.OL.init();
        Roles.assign.OL.refreshEvents();

        Roles.assign.steps.arr['step-1'].refreshEvents();
      },
      nextStepFunction : function() {
        Roles.assign.steps.tickStep ( 0 );
        Roles.assign.steps.nextStep();
      },
      refreshEvents : function() {
        $('#add-qualifications-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.QUALS.pool.show();
          });

        $('#add-OLs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.OL.pool.show();
          });

        $('#add-OCs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.OC.pool.show();
          });

        $('#add-ILTs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.ILT.pool.show();
          });

        Roles.assign.QUALS.refreshEvents();
        Roles.assign.OC.refreshEvents();
        Roles.assign.OL.refreshEvents();
        Roles.assign.ILT.refreshEvents();
      },
      onShow : function() {

      },
    },
    'step-2' : {
      id : 'step-2',
      init: function() {
        dataTable.tables['DT-assign-users-roles'] = {
          tbl : $('#DT-assign-users-roles').dataTable({
            paging : false,
          }),
        };

        $('#DT-assign-users-roles tbody').on('click', 'tr', function () {
          var table  = dataTable.tables["DT-assign-users-roles"].tbl,
              userID = $(this).attr ( 'data-user-id' );

          // $(this).toggleClass ( 'selected' );
          if ( Roles.assign.usersToAssign[userID] === undefined ) {
            table.api().row('[data-user-id="' + userID + '"]').select();

            var data = table.api().row(this).data();

            // - CREATE USER OBJECT
            Roles.assign.usersToAssign[userID] = {
              firstName : data[1],
              surname   : data[2],
              email     : data[3],
              deadline  : {
                period : {
                  number : Roles.assign.defaultDeadlineNumber,
                  unit   : Roles.assign.defaultDeadlineUnit,
                },
              },
              QUALS : Roles.assign.copyObj ( Roles.assign.QUALS.arr ),
              OL    : Roles.assign.copyObj ( Roles.assign.OL.arr ),
              OC    : Roles.assign.copyObj ( Roles.assign.OC.arr ),
              ILT   : Roles.assign.copyObj ( Roles.assign.ILT.arr ),
            };

            var canSetDate = ( Roles.assign.defaultDeadlineNumber !== '' &&
                               Roles.assign.defaultDeadlineNumber !== null )
                               ||
                             ( Roles.assign.defaultDeadlineUnit   !== '' &&
                               Roles.assign.defaultDeadlineUnit   !== null );

            if ( canSetDate ) {
              // - GET DATE
              Roles.assign.usersToAssign[userID].deadline.dateObj = new DateNH();
              // - MODIFY THE DATE
              Roles.assign.usersToAssign[userID].deadline.dateObj.modify (
                '+' + Roles.assign.usersToAssign[userID].deadline.period.number + ' ' + Roles.assign.usersToAssign[userID].deadline.period.unit
              );
            }
          }
          else {
            table.api().row('[data-user-id="' + userID + '"]').deselect();

            delete Roles.assign.usersToAssign[userID];
          }

          Roles.assign.steps.arr['step-3'].updateUsersToAssign();
        });

        Roles.assign.steps.arr['step-2'].refreshEvents();
      },
      nextStepFunction : function() {
        var table       = dataTable.tables["DT-assign-users-roles"].tbl,
            numSelected = table.api().rows({selected:true}).count();

        if ( numSelected === 0 ) {
          main.flashData.show ( 'Please Select at least 1 user to enrol', 'negative', -1 );
        }
        else {
          Roles.assign.steps.tickStep ( 1 );
          Roles.assign.steps.nextStep();

          main.flashData.hide();
        }
      },
      refreshEvents : function() {

      },
      onShow : function() {
        Roles.assign.steps.arr['step-2'].refreshEvents();
      },
    },
    'step-3' : {
      id : 'step-3',
      init: function() {
        dataTable.tables['DT-assign-users-roles-customise'] = {
          tbl : $('#DT-assign-users-roles-customise').dataTable({
            paging : false,
          }),
        };
      },
      nextStepFunction : function() {
        Roles.assign.steps.tickStep ( 2 );
        Roles.assign.steps.nextStep();
      },
      refreshEvents : function() {
        $('#add-qualifications-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.QUALS.pool.show();
          });

        $('#add-OLs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.OL.pool.show();
          });

        $('#add-OCs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.OC.pool.show();
          });

        $('#add-ILTs-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();

            Roles.assign.ILT.pool.show();
          });

        // - GLOBAL DEADLINES STEP 2
        $('#step-3 #roles-assign-global-controls .deadline-selector')
          .off ( 'change' )
          .on  ( 'change', function ( evt ) {
            if ( $(this).val() === 'custom' ) {
              $(this).next('.custom-deadline').switchClass('dn', 'di');
              Roles.assign.defaultDeadlineNumber = $(this).next('.custom-deadline').find('.custom-deadline-number').val();
              Roles.assign.defaultDeadlineUnit = $(this).next('.custom-deadline').find('.custom-deadline-unit').val();

              $('#step-3  #roles-assign-global-controls .deadline-date-input').prop (
                'disabled', true
              );

              // - SHOW THE DATE MODIFIERS
              $('#step-3  #roles-assign-global-controls .custom-deadline-number')
                .switchClass ( 'dn', 'di' );
              $('#step-3  #roles-assign-global-controls .custom-deadline-unit')
                .switchClass ( 'dn', 'di' );
            }
            else if ( $(this).val() === 'specify-date' ) {
              // - ENABLE THE DATE PICKER
              $('#step-3  #roles-assign-global-controls .deadline-date-input')
                .prop ( 'disabled', false )
                .datepicker({
                  dateFormat : 'dd-mm-yy',
                });

              // - HIDE THE DATE MODIFIERS
              $('#step-3  #roles-assign-global-controls .custom-deadline-number')
                .switchClass ( 'di', 'dn' );
              $('#step-3  #roles-assign-global-controls .custom-deadline-unit')
                .switchClass ( 'di', 'dn' );
            }
            else {
              $(this).next('.custom-deadline').switchClass('di', 'dn');
              Roles.assign.defaultDeadlineNumber = '';
              Roles.assign.defaultDeadlineUnit   = '';

              $('#step-3  #roles-assign-global-controls .deadline-date-input').prop (
                'disabled', true
              );

              // - SHOW THE DATE MODIFIERS
              $('#step-3  #roles-assign-global-controls .custom-deadline-number')
                .switchClass ( 'dn', 'di' );
              $('#step-3  #roles-assign-global-controls .custom-deadline-unit')
                .switchClass ( 'dn', 'di' );
            }

            Roles.assign.steps.arr['step-3'].updateGlobalDeadline();
          });

        $('#step-3 #roles-assign-global-controls .custom-deadline-number')
          .off ( 'click' )
          .off ( 'keyup' )
          .off ( 'change' )
          .on  ( 'keyup change click', function ( evt ) {
            Roles.assign.defaultDeadlineNumber = $(this).val();

            Roles.assign.steps.arr['step-3'].updateGlobalDeadline();
          });

        $('#step-3 #roles-assign-global-controls .custom-deadline-unit')
          .off ( 'change' )
          .on  ( 'change', function ( evt ) {
            Roles.assign.defaultDeadlineUnit = $(this).val();

            Roles.assign.steps.arr['step-3'].updateGlobalDeadline();
          });

        $('#step-3 #roles-assign-global-controls #apply-deadline-to-all-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            evt.preventDefault();
            Roles.assign.steps.arr['step-3'].overrideDeadlinePeriodsCheck();
          });

        // - INDIVIDUAL DEADLINES STEP 3
        $('#step-3 #DT-assign-users-roles-customise .deadline-selector')
          .off ( 'change' )
          .on  ( 'change', function ( evt ) {
            var id = $(this).closest('tr').attr('data-user-id');

            // - IF CUSTOM
            if ( $(this).val() === 'custom' ) {
              $(this).next('.custom-deadline').switchClass('dn', 'di');
            }
            // - IF SPECIFY DATE
            else if ( $(this).val() === 'specify-date' ) {
              $(this).next('.custom-deadline').switchClass('di', 'dn');

              var date = $(this).closest('tr').find('span.deadline-date').text();

              $(this).closest('tr').find('span.deadline-date')
                .empty()
                .append (
                  $('<input>')
                    .addClass ( 'deadline-date-input' )
                    .attr ( 'type', 'text' )
                    .attr ( 'value', date )
                    .css ( 'width', '70px' )
                    .on ( 'change', function ( evt ) {
                      var Y = $(this).val().substr(6);
                      var m = $(this).val().substr(3, 2);
                      var d = $(this).val().substr(0, 2);

                      Roles.assign.usersToAssign[id].deadline = {
                        period : 'specified-date',
                        dateObj : new DateNH ( Y + '-' + m + '-' + d ),
                      };
                    })
                );

              $(this).closest('tr').find('.deadline-date-input').datepicker ({
                dateFormat : 'dd-mm-yy',
              });
            }
            // - IF NOT SET
            else {
              $(this).next('.custom-deadline').switchClass('di', 'dn');
            }

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-assign-users-roles-customise tr[data-user-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.individualOverrides.deadlines, id, period
            );

            Roles.assign.steps.arr['step-3'].updateDeadline ( id );
          });

        $('#step-3 #DT-assign-users-roles-customise .custom-deadline-number')
          .off ( 'click' )
          .off ( 'keyup' )
          .off ( 'change' )
          .on  ( 'click keyup change', function ( evt ) {
            var id = $(this).closest('tr').attr('data-user-id');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-assign-users-roles-customise tr[data-user-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.individualOverrides.deadlines, id, period
            );

            Roles.assign.steps.arr['step-3'].updateDeadline ( id );
          });

        $('#step-3 #DT-assign-users-roles-customise .custom-deadline-unit')
          .off ( 'change' )
          .on  ( 'change', function ( evt ) {
            var id = $(this).closest('tr').attr('data-user-id');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-assign-users-roles-customise tr[data-user-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.individualOverrides.deadlines, id, period
            );

            Roles.assign.steps.arr['step-3'].updateDeadline ( id );
          });

        // - CUSTOMISATION

        // - GLOBAL CUSTOMISATION

        // - INDIVIDUAL CUSTOMISATION
        $('#step-3 .customise-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            var id = $(this).closest('tr').attr('data-user-id');
            Roles.assign.steps.arr['step-3'].showIndividualCustomisationPopup ( id );
          });
      },
      showIndividualCustomisationPopup : function ( userID ) {
        // console.log('showIndividualCustomisationPopup', userID);
        popup.generatePopup({
          method: 'ajax',
          ajaxUrl: '/admin/AJAX-roles/show-individual-customisation',
          maxWidth: 1000,
          maxHeight: 700,
        });
      },
      updateDeadline : function ( id ) {
        var tr = $('#step-3 tr[data-user-id="' + id + '"]');

        if ( $(tr).find('.deadline-selector').val() === 'custom' ) {
          var customNo = $(tr).find('.custom-deadline-number').val(),
              unit     = $(tr).find('.custom-deadline-unit').val(),
              valid    = validate.isInt ( customNo ) && customNo > 0;

          if ( valid ) {
            Roles.assign.usersToAssign[id].deadline.period = {
              number : customNo,
              unit   : unit,
            };

            Roles.assign.usersToAssign[id].deadline.dateObj = new DateNH();
            Roles.assign.usersToAssign[id].deadline.dateObj.modify (
              '+' + customNo + ' ' + unit
            );

            $(tr).find('td:nth-child(5)').text (
              Roles.assign.usersToAssign[id].deadline.dateObj.format (
                'd-m-Y'
              )
            );
          }

          return {
            period : {
              number : customNo,
              unit   : unit,
            },
            dateObj : Roles.assign.usersToAssign[id].deadline.dateObj,
          };
        }
        else {
          Roles.assign.usersToAssign[id].deadline.period = {
            number : '',
            unit   : '',
          };

          return {
            period : {
              number : '',
              unit   : '',
            },
            dateObj : null,
          };
        }

        // console.log('updateDeadline', Roles.assign.usersToAssign[id]);
      },
      updateGlobalDeadline : function() {
        Roles.assign.globalDeadlinePeriod = '+' + $('#step-3 #roles-assign-global-controls .custom-deadline-number').val() + ' ' + $('#step-3 #roles-assign-global-controls .custom-deadline-unit').val();

        Roles.assign.globalDeadlineDate = new DateNH();
        Roles.assign.globalDeadlineDate.modify ( Roles.assign.globalDeadlinePeriod );

        $('#step-3 #roles-assign-global-controls .deadline-date-input').val (
          Roles.assign.globalDeadlineDate.format ( 'd-m-Y' )
        );
      },
      updateUsersToAssign : function() {
        var table = dataTable.tables["DT-assign-users-roles-customise"].tbl,
        deadlineSelectorHTML = '<select class="deadline-selector di">' +
                                 '<option value="">Not Set</option>' +
                                 '<option value="custom">Custom</option>' +
                                 '<option value="specify-date">Specify Date</option>' +
                               '</select>' +
                               '<div class="custom-deadline dn">' +
                                 '<input type="number" class="custom-deadline-number di" min="0" step="1" value="1">' +
                                 '<select class="custom-deadline-unit di">' +
                                   '<option value="days">Day(s)</option>' +
                                   '<option value="weeks">Week(s)</option>' +
                                   '<option value="months">Month(s)</option>' +
                                   '<option value="years">Year(s)</option>' +
                                 '</select>' +
                               '</div>',
        customiseBtnHTML     = '<div class="btn-bank-host">' +
                                 '<a class="customise-btn gen-btn neutral-btn btn-bank">' +
                                   'Customise' +
                                 '</a>' +
                               '</div>';

        table.api().clear();

        for ( var userID in Roles.assign.usersToAssign ) {
          var deadlineDate = '';
          if ( Roles.assign.usersToAssign[userID].deadline.dateObj !== undefined ) {
            deadlineDate = '<span class="deadline-date">' + Roles.assign.usersToAssign[userID].deadline.dateObj.format (
              'd-m-Y'
            ) + '</span>';
          }

          var rowNode = table.api().row.add([
            Roles.assign.usersToAssign[userID].firstName,
            Roles.assign.usersToAssign[userID].surname,
            Roles.assign.usersToAssign[userID].email,
            deadlineSelectorHTML,
            deadlineDate,
            // customiseBtnHTML
          ]).draw().node();

          $(rowNode).attr ( 'data-user-id', userID );

          if ( Roles.assign.usersToAssign[userID].deadline.dateObj === undefined ) {
            // - SET TO NOT SET
            $(rowNode).find('.deadline-selector').select_by_value('');
            $(rowNode).find('.custom-deadline').switchClass ( 'di', 'dn' );
            $(rowNode).find('.custom-deadline-number').val('');
            $(rowNode).find('.custom-deadline-unit').select_by_value('days');
          }
          else {
            $(rowNode).find('.deadline-selector').select_by_value('custom');
            $(rowNode).find('.custom-deadline').switchClass ( 'dn', 'di' );
            $(rowNode).find('.custom-deadline-number').val (
              Roles.assign.usersToAssign[userID].deadline.period.number
            );
            $(rowNode).find('.custom-deadline-unit').select_by_value (
              Roles.assign.usersToAssign[userID].deadline.period.unit
            );
          }
        }
      },
      overrideDeadlinePeriodsCheck : function() {
        if ( ObjectHelper.isEmpty ( Roles.assign.individualOverrides.deadlines ) ) {
          Roles.assign.steps.arr['step-3'].overrideDeadlinePeriods()
        }
        else {
          Roles.assign.steps.arr['step-3'].showOverrideIndividualDeadlinesPopup();
        }
      },
      overrideDeadlinePeriods : function() {
        $('#DT-assign-users-roles-customise tbody tr').each (
          Roles.assign.steps.arr['step-3'].overrideDeadlinePeriod
        );

        // - RESET THE OVERRIDES OBJECT
        Roles.assign.individualOverrides.deadlines = {};
      },
      overrideDeadlinePeriod : function ( index, elem ) {
        if ( $('#step-3 #roles-assign-global-controls .deadline-selector').val() === '' ) {
          $(elem).find('.deadline-selector').select_by_value('');
          $(elem).find('.custom-deadline').switchClass ( 'di', 'dn' );
          $(elem).find('.custom-deadline-number').val('');
          $(elem).find('.custom-deadline-unit').select_by_value('days');
        }
        else if ( $('#step-3 #roles-assign-global-controls .deadline-selector').val() === 'specify-date' ) {
          $(elem).find('.deadline-selector').select_by_value('specify-date');
          $(elem).find('.custom-deadline').switchClass ( 'di', 'dn' );

          $(elem).find('.deadline-date').text (
            $('#step-3 #roles-assign-global-controls .deadline-date-input').val()
          );
        }
        else {
          $(elem).find('.deadline-selector').select_by_value('custom');
          $(elem).find('.custom-deadline').switchClass ( 'dn', 'di' );
          $(elem).find('.custom-deadline-number').val (
            Roles.assign.defaultDeadlineNumber
          );
          $(elem).find('.custom-deadline-unit').select_by_value (
            Roles.assign.defaultDeadlineUnit
          );
        }

        var id = $(elem).attr ( 'data-user-id' );
        Roles.assign.steps.arr['step-3'].updateDeadline ( id );
      },
      showOverrideIndividualDeadlinesPopup : function() {
        var content = 'You have made changes to individual\'s deadlines, if you change this for all, those changes will be overridden. Would you like to proceed?';

        popup.generatePopup({
          title: 'Override Altered Deadlines',
          method: 'literal',
          content: content,
          maxWidth: 400,
          maxHeight: 180,
          success: function() {
            popup.footer(
              [
                $('<button>')
                  .attr ( 'id', 'yes-btn' )
                  .attr ( 'class', 'positive-btn gen-btn p-- mr--' )
                  .text ( 'Yes' ),
                $('<button>')
                  .attr ( 'id', 'no-btn' )
                  .attr ( 'class', 'negative-btn gen-btn p-- mr--' )
                  .text ( 'No' ),
              ],
              function() {
                $('#yes-btn')
                  .off ( 'click' )
                  .on  ( 'click', function ( evt ) {
                    Roles.assign.steps.arr['step-3'].overrideDeadlinePeriods();
                    popup.close();
                  });

                $('#no-btn')
                  .off ( 'click' )
                  .on  ( 'click', popup.close );
              },
              50
            );
          },
        });
      },
      onShow : function() {
        Roles.assign.steps.arr['step-3'].refreshEvents();

        Roles.assign.steps.arr['step-3'].updateGlobalDeadline();
      },

      attemptSubmission : function() {
        var userID;
        var deadlineConflicts = Roles.assign.checkForDeadlineConflicts();

        // - IF ANY CONFLICTS, RUN THROUGH THE POPUPS
        if ( deadlineConflicts.any ) {
          // - IF ANY ELEMENTS OVERHANGING, GIVE USER THE OPTION TO BRING THEM INTO LINE IN 1 OF 2 WAYS
          if ( deadlineConflicts.conflicts.elementsOver.any ) {
            popup.generatePopup({
              title: 'Deadline Conflict',
              method: 'literal',
              content: Roles.assign.deadlineConflictContent(),
              maxWidth: 600,
              maxHeight: 400,
              success: function() {
                popup.footer(
                  [
                    $('<button>')
                      .attr ( 'id', 'cut-inds-to-role-btn' )
                      .attr ( 'class', '1/1 positive-btn gen-btn p-- mr--' )
                      .text ( 'Match overhanging training elements to match the overall role deadline' ),
                    $('<button>')
                      .attr ( 'id', 'extend-role-to-latest-ind-btn' )
                      .attr ( 'class', '1/1 negative-btn gen-btn p-- mt-- mr--' )
                      .text ( 'Extend the role deadline to match the final training element' ),
                  ],
                  function() {
                    $('#cut-inds-to-role-btn')
                      .off ( 'click' )
                      .on  ( 'click', function ( evt ) {
                        Roles.assign.deadline.cutIndsToRole();
                        // console.log(1116,'cutIndsToRole');
                        popup.close();

                        Roles.assign.steps.arr['step-3'].attemptSubmission();
                      });

                    $('#extend-role-to-latest-ind-btn')
                      .off ( 'click' )
                      .on  ( 'click', function ( evt ) {
                        Roles.assign.deadline.extendRoleToLatestInd();
                        // console.log(1126,'extendRoleToLatestInd');
                        popup.close();

                        Roles.assign.steps.arr['step-3'].attemptSubmission();
                      });
                  },
                  100
                );
              },
            });

            return false;
          }

          // - IF ANY USERS HAVE SHORTER DEADLINES THAN THE ROLE, JUST AUTOMATICALLY CLIP THEM AND LET THE USER KNOW THAT'S HAPPENED
          if ( deadlineConflicts.conflicts.usersExtended.any ) {
            for ( userID in deadlineConflicts.conflicts.usersExtended.data ) {
              Roles.assign.deadline.extendDeadlinesOfUser ( userID );
            }

            main.flashData.show (
              'Some of the deadlines for individual users have been extended. For greater precision control over the individual training element deadlines, go to the user\'s ITR.',
              'positive',
              -1
            );
          }

          // - IF ANY USERS HAVE SHORTER DEADLINES THAN THE ROLE, JUST AUTOMATICALLY CLIP THEM AND LET THE USER KNOW THAT'S HAPPENED
          if ( deadlineConflicts.conflicts.usersShortened.any ) {
            for ( userID in deadlineConflicts.conflicts.usersShortened.data ) {
              Roles.assign.deadline.shortenDeadlinesOfUser ( userID );
            }
          }

          Roles.assign.run();
        }
        else {
          Roles.assign.run();
        }
      },
    },
  },
  function () {
    $('form[name="assign-role-form"]').submit ( function ( evt ) {
      evt.preventDefault();

      Roles.assign.steps.arr['step-3'].attemptSubmission();
    });

    $('#submit-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        $('form[name="assign-role-form"]').submit();
      });
  }),

  QUALS : {
    arr : {},

    init : function() {
      Roles.assign.QUALS.initQUALTable();
    },

    initQUALTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP QUALS
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-QUALs-to-role'] = {
        tbl : $('#DT-add-QUALs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | THEN HERE WE DIFFER FROM THE ROLES.BUILDER AS WE NEED TO ADD EVERY QUAL
      | TO THE CORRESPONDING OBJECT
      |--------------------------------------------------------------------------
       */

      $('#DT-add-QUALs-to-role tbody tr').each ( function ( index, elem ) {
        if ( $(elem).hasAttr ('data-qualification-id') ) {
          var id = $(elem).attr('data-qualification-id');

          Roles.assign.QUALS.arr[id] = {
            deadline : {
              period : Roles.assign.convertDeadlinePeriod (
                $(elem).find('.deadline-selector').val(),
                $(elem).find('.custom-deadline-number').val(),
                $(elem).find('.custom-deadline-unit').val()
              )
            },
          };

          if ( Roles.assign.QUALS.arr[id].deadline.period !== '' ) {
            Roles.assign.QUALS.arr[id].deadline.dateObj = new DateNH();

            Roles.assign.QUALS.arr[id].deadline.dateObj.modify (
              Roles.assign.QUALS.arr[id].deadline.period
            );
          }
        }
      });

      /*
      |--------------------------------------------------------------------------
      | ADDING QUALS TO ROLE
      |--------------------------------------------------------------------------
       */

    },

    refreshEvents : function() {
      // - ADD

      // - REMOVE
      $('#DT-add-QUALs-to-role .remove-added-qualification-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var id = $(this).closest('tr').attr('data-qualification-id');
          Roles.assign.QUALS.remove ( id );
        });

      $('#DT-add-QUALs-to-role .deadline-selector')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-qualification-id');
          if ( $(this).val() === 'custom' ) {
            $(this).next('.custom-deadline').switchClass('dn', 'di');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-add-QUALs-to-role tr[data-qualification-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.QUALS.arr, id, period
            );
          }
          else {
            $(this).next('.custom-deadline').switchClass('di', 'dn');
            Roles.assign.deadlinePeriod = '';
          }
        });

      $('#DT-add-QUALs-to-role .custom-deadline-number')
        .off ( 'click' )
        .off ( 'keyup' )
        .off ( 'change' )
        .on  ( 'click keyup change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-qualification-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-QUALs-to-role tr[data-qualification-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.QUALS.arr, id, period
          );
        });

      $('#DT-add-QUALs-to-role .custom-deadline-unit')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-qualification-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-QUALs-to-role tr[data-qualification-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.QUALS.arr, id, period
          );
        });

    },

    pool : {
      show : function() {
        //Roles.builder.QUALS.pool.show
        Roles.builder.show_POOL (
          Roles.assign.QUALS,
          'TPaQs',
          '/admin/AJAX-roles/show-qualifications-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-QUALS-pool',
          '.add-qualification-btn',
          '.remove-qualification-btn',
          Roles.assign.QUALS,
          'data-qualification-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-qualification-btn',
          '.remove-qualification-btn',
          Roles.assign.QUALS,
          'data-qualification-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-QUALS-pool',
        'DT-add-QUALs-to-role',
        '.add-qualification-btn',
        '.remove-qualification-btn',
        'remove-added-qualification-btn',
        Roles.assign.QUALS,
        'data-qualification-id',
        'TPaQ'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-QUALS-pool',
        'DT-add-QUALs-to-role',
        '.add-qualification-btn',
        '.remove-qualification-btn',
        Roles.assign.QUALS,
        'data-qualification-id',
        'TPaQ'
      );
    },
  },

  OL : {
    arr : {},

    init : function() {
      Roles.assign.OL.initOLTable();
    },

    initOLTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP ONLINE LEARNING COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-OLs-to-role'] = {
        tbl : $('#DT-add-OLs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | THEN HERE WE DIFFER FROM THE ROLES.BUILDER AS WE NEED TO ADD EVERY OL
      | TO THE CORRESPONDING OBJECT
      |--------------------------------------------------------------------------
       */

      $('#DT-add-OLs-to-role tbody tr').each ( function ( index, elem ) {
        if ( $(elem).hasAttr ('data-ol-id') ) {
          var id = $(elem).attr('data-ol-id');

          Roles.assign.OL.arr[id] = {
            deadline : {
              period : Roles.assign.convertDeadlinePeriod (
                $(elem).find('.deadline-selector').val(),
                $(elem).find('.custom-deadline-number').val(),
                $(elem).find('.custom-deadline-unit').val()
              ),
            },
          };

          if ( Roles.assign.OL.arr[id].deadline.period !== '' ) {
            Roles.assign.OL.arr[id].deadline.dateObj = new DateNH();

            Roles.assign.OL.arr[id].deadline.dateObj.modify (
              Roles.assign.OL.arr[id].deadline.period
            );
          }
        }
      });

      /*
      |--------------------------------------------------------------------------
      | ADDING ONLINE LEARNING COURSES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    refreshEvents : function() {
      // - ADD

      // - REMOVE
      $('#DT-add-OLs-to-role .remove-added-ol-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          evt.preventDefault();

          var id = $(this).closest('tr').attr('data-ol-id');
          Roles.assign.OL.remove ( id );
        });

      $('#DT-add-OLs-to-role .deadline-selector')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ol-id');
          if ( $(this).val() === 'custom' ) {
            $(this).next('.custom-deadline').switchClass('dn', 'di');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-add-OLs-to-role tr[data-ol-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.OL.arr, id, period
            );
          }
          else {
            $(this).next('.custom-deadline').switchClass('di', 'dn');
            Roles.assign.deadlinePeriod = '';
          }
        });

      $('#DT-add-OLs-to-role .custom-deadline-number')
        .off ( 'click' )
        .off ( 'keyup' )
        .off ( 'change' )
        .on  ( 'click keyup change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ol-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-OLs-to-role tr[data-ol-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.OL.arr, id, period
          );
        });

      $('#DT-add-OLs-to-role .custom-deadline-unit')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ol-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-OLs-to-role tr[data-ol-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.OL.arr, id, period
          );
        });
    },

    pool : {
      show : function() {
        //Roles.builder.OL.pool.show
        Roles.builder.show_POOL (
          Roles.assign.OL,
          'Online Learning Courses',
          '/admin/AJAX-roles/show-ol-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-OL-pool',
          '.add-ol-btn',
          '.remove-ol-btn',
          Roles.assign.OL,
          'data-ol-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-ol-btn',
          '.remove-ol-btn',
          Roles.assign.OL,
          'data-ol-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-OL-pool',
        'DT-add-OLs-to-role',
        '.add-ol-btn',
        '.remove-ol-btn',
        'remove-added-ol-btn',
        Roles.assign.OL,
        'data-ol-id',
        'Online Learning Course'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-OL-pool',
        'DT-add-OLs-to-role',
        '.add-ol-btn',
        '.remove-ol-btn',
        Roles.assign.OL,
        'data-ol-id',
        'Online Learning Course'
      );
    },
  },

  OC : {
    arr : {},

    init : function() {
      Roles.assign.OC.initOCTable();
    },

    initOCTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP OCC COMP EXERCISES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-OCs-to-role'] = {
        tbl : $('#DT-add-OCs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | THEN HERE WE DIFFER FROM THE ROLES.BUILDER AS WE NEED TO ADD EVERY OC
      | TO THE CORRESPONDING OBJECT
      |--------------------------------------------------------------------------
       */

      $('#DT-add-OCs-to-role tbody tr').each ( function ( index, elem ) {
        if ( $(elem).hasAttr ('data-oc-id') ) {
          var id = $(elem).attr('data-oc-id');

          Roles.assign.OC.arr[id] = {
            deadline : {
              period : Roles.assign.convertDeadlinePeriod (
                $(elem).find('.deadline-selector').val(),
                $(elem).find('.custom-deadline-number').val(),
                $(elem).find('.custom-deadline-unit').val()
              ),
            },
            mentorUserID : null,
            assessorUserID : null,
          };

          if ( Roles.assign.OC.arr[id].deadline.period !== '' ) {
            Roles.assign.OC.arr[id].deadline.dateObj = new DateNH();

            Roles.assign.OC.arr[id].deadline.dateObj.modify (
              Roles.assign.OC.arr[id].deadline.period
            );
          }
        }
      });

      /*
      |--------------------------------------------------------------------------
      | ADDING OCC COMP EXERCISES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    refreshEvents : function() {
      // - ADD

      // - REMOVE
      $('#DT-add-OCs-to-role .remove-added-oc-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');
          Roles.assign.OC.remove ( id );
        });

      $('#DT-add-OCs-to-role .deadline-selector')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');
          if ( $(this).val() === 'custom' ) {
            $(this).next('.custom-deadline').switchClass('dn', 'di');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-add-OCs-to-role tr[data-ol-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.OC.arr, id, period
            );
          }
          else {
            $(this).next('.custom-deadline').switchClass('di', 'dn');
            Roles.assign.deadlinePeriod = '';
          }
        });

      $('#DT-add-OCs-to-role .custom-deadline-number')
        .off ( 'click' )
        .off ( 'keyup' )
        .off ( 'change' )
        .on  ( 'click keyup change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-OCs-to-role tr[data-oc-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.OC.arr, id, period
          );
        });

      $('#DT-add-OCs-to-role .custom-deadline-unit')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-OCs-to-role tr[data-oc-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.OC.arr, id, period
          );
        });

      // - MENTORS
      $('.select-mentor')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');

          Roles.assign.OC.arr[id].mentorUserID = $(this).val() === '' ? null : $(this).val();
        });

      // - ASSESSORS
      $('.select-assessor')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-oc-id');

          Roles.assign.OC.arr[id].assessorUserID = $(this).val() === '' ? null : $(this).val();
        });
    },

    pool : {
      show : function() {
        //Roles.builder.OC.pool.show
        Roles.builder.show_POOL (
          Roles.assign.OC,
          'Competencies',
          '/admin/AJAX-roles/show-oc-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-OC-pool',
          '.add-oc-btn',
          '.remove-oc-btn',
          Roles.assign.OC,
          'data-oc-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-oc-btn',
          '.remove-oc-btn',
          Roles.assign.OC,
          'data-oc-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-OC-pool',
        'DT-add-OCs-to-role',
        '.add-oc-btn',
        '.remove-oc-btn',
        'remove-added-oc-btn',
        Roles.assign.OC,
        'data-oc-id',
        'Competency'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-OC-pool',
        'DT-add-OCs-to-role',
        '.add-oc-btn',
        '.remove-oc-btn',
        Roles.assign.OC,
        'data-oc-id',
        'Competency'
      );
    },
  },

  ILT : {
    arr : {},

    init : function() {
      Roles.assign.ILT.initILTTable();
    },

    initILTTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP ILT COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-ILTs-to-role'] = {
        tbl : $('#DT-add-ILTs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | THEN HERE WE DIFFER FROM THE ROLES.BUILDER AS WE NEED TO ADD EVERY ILT
      | TO THE CORRESPONDING OBJECT
      |--------------------------------------------------------------------------
       */

      $('#DT-add-ILTs-to-role tbody tr').each ( function ( index, elem ) {
        if ( $(elem).hasAttr ('data-ilt-id') ) {
          var id = $(elem).attr('data-ilt-id');

          Roles.assign.ILT.arr[id] = {
            deadline : {
              period : Roles.assign.convertDeadlinePeriod (
                $(elem).find('.deadline-selector').val(),
                $(elem).find('.custom-deadline-number').val(),
                $(elem).find('.custom-deadline-unit').val()
              ),
            },
          };

          if ( Roles.assign.ILT.arr[id].deadline.period !== '' ) {
            Roles.assign.ILT.arr[id].deadline.dateObj = new DateNH();

            Roles.assign.ILT.arr[id].deadline.dateObj.modify (
              Roles.assign.ILT.arr[id].deadline.period
            );
          }
        }
      });

      /*
      |--------------------------------------------------------------------------
      | ADDING ILT COURSES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    refreshEvents : function() {
      // - ADD

      // - REMOVE
      $('#DT-add-ILTs-to-role .remove-added-ilt-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ilt-id');
          Roles.assign.ILT.remove ( id );
        });

      $('#DT-add-ILTs-to-role .deadline-selector')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ilt-id');
          if ( $(this).val() === 'custom' ) {
            $(this).next('.custom-deadline').switchClass('dn', 'di');

            var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
              '#DT-add-ILTs-to-role tr[data-ilt-id="' + id + '"]'
            );

            Roles.assign.deadline.updateDeadlinePeriodAndDate (
              Roles.assign.ILT.arr, id, period
            );
          }
          else {
            $(this).next('.custom-deadline').switchClass('di', 'dn');
            Roles.assign.deadlinePeriod = '';
          }
        });

      $('#DT-add-ILTs-to-role .custom-deadline-number')
        .off ( 'click' )
        .off ( 'keyup' )
        .off ( 'change' )
        .on  ( 'click keyup change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ilt-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-ILTs-to-role tr[data-ilt-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.ILT.arr, id, period
          );
        });

      $('#DT-add-ILTs-to-role .custom-deadline-unit')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var id = $(this).closest('tr').attr('data-ilt-id');

          var period = Roles.assign.deadline.getDeadlinePeriodFromRow (
            '#DT-add-ILTs-to-role tr[data-ilt-id="' + id + '"]'
          );

          Roles.assign.deadline.updateDeadlinePeriodAndDate (
            Roles.assign.ILT.arr, id, period
          );
        });

    },

    pool : {
      show : function() {
        //Roles.builder.ILT.pool.show
        Roles.builder.show_POOL (
          Roles.assign.ILT,
          'Competencies',
          '/admin/AJAX-roles/show-ilt-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-ILT-pool',
          '.add-ilt-btn',
          '.remove-ilt-btn',
          Roles.assign.ILT,
          'data-ilt-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-ilt-btn',
          '.remove-ilt-btn',
          Roles.assign.ILT,
          'data-ilt-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-ILT-pool',
        'DT-add-ILTs-to-role',
        '.add-ilt-btn',
        '.remove-ilt-btn',
        'remove-added-ilt-btn',
        Roles.assign.ILT,
        'data-ilt-id',
        'Competency'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-ILT-pool',
        'DT-add-ILTs-to-role',
        '.add-ilt-btn',
        '.remove-ilt-btn',
        Roles.assign.ILT,
        'data-ilt-id',
        'Competency'
      );
    },
  },
};
