var Venues = {

  add : function() {
    var sendData = $('#add-venue-form').serialize();

    $.ajax({
      url: '/AJAX-venues/add-process',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        if ( returnedData.success ) {
          main.flashData.show ( 'Venue added successfully...', 'positive', -1 );
          // console.log('Venues.onAddRefresh', Venues.onAddRefresh);
          cms.refreshSelect(
            "venue_id",
            "Venues_m",
            "getInteractableVenuesDropDown",
            "name",
            "id",
            Venues.onAddRefresh,
            [returnedData.venueID]
          );
        }
      },
    });
    // console.log(sendData);
  },

  onAddRefresh : function() {
    $('#venue_id').select_by_value ( arguments[0] );
    setTimeout ( main.flashData.hide, 2500 );
    setTimeout ( popup.close, 3000 );
  },

};
