var ITR = {
  init : function() {
    $('.elearning-launch-btn').each ( function ( index, elem ) {
      $(elem).removeAttr ( 'href' );
      $(elem).on ( 'click', function ( evt ) {
        Elearning.launchCourse ( $(this).attr('data-user-course-id') );
        if ( DEBUG.on ) {
          JL().info('e-learning-launch-btn: ' + $(this).attr('data-user-course-id'));
        }
      });
    });
    $('.elearning-launch-playall').each ( function ( index, elem ) {
      $(elem).removeAttr ( 'href' );
      $(elem).on ( 'click', function ( evt ) {
        Elearning.launchAssessment ( $(this).attr('data-user-assesment-id'),$(this).attr('playall'));
      });
    });
    $('.elearning-launch-simulate').each ( function ( index, elem ) {
      $(elem).removeAttr ( 'href' );
      $(elem).on ( 'click', function ( evt ) {
        Elearning.launchAssessment ( $(this).attr('data-user-assesment-id') );
      });
    });
    $('.elearning-launch-question').each ( function ( index, elem ) {
      $(elem).removeAttr ( 'href' );
      $(elem).on ( 'click', function ( evt ) {
        Elearning.launchQuestion ( $(this).attr('data-question-id'),$(this).attr('data-user-assesment-id') );
      });
    });

    $('.view-ilt-history-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        ITR.ILT.showHistoricalSessions ( $(this).closest('tr').data ( 'id' ) );
      });

    $('.itr-ilt-assessments-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        ITR.ILT.showUnlockedAssessments ( $(this).attr ( 'data-ilt-session-id' ) );
      });

    ITR.makeDeadlinesEditable();
  },

  refreshCourseInfo : function ( userCourse ) {
    // console.log('refreshCourseInfo',userCourse);
    // - PROGRESS PERC
    $('table.OL tr[data-id="' + userCourse.id + '"]')
      .find ( '.percentage-fill' )
      .css ( 'width', userCourse.progress_perc + '%' );

    $('table.OL tr[data-id="' + userCourse.id + '"]')
      .find ( '.percentage-text' )
      .text ( userCourse.progress_perc + '%' );

    // - STATUS
    $('table.OL tr[data-id="' + userCourse.id + '"]')
      .find ( '.status' )
        .html ( Status.generateStatusTag ( userCourse.status, 'online-learning' ) );

    if ( userCourse.status === 'P' ) {
      $('table.OL tr[data-id="' + userCourse.id + '"]')
        .find ( '.itr-course-btn-bank a.elearning-launch-btn' )
        .off ( 'click' );

      var certDownloadURI = '/elearning-certificate/' +
                            userCourse.course_id + '/' + userCourse.user_id + '/D';


      $('table.OL tr[data-id="' + userCourse.id + '"]')
        .find ( '.itr-course-btn-bank a.elearning-launch-btn' )
          .attr ( 'href', certDownloadURI )
          .removeClass ( 'elearning-launch-btn' )
          .addClass ( 'certificate-btn' )
            .find ( 'i' )
              .removeClass ( 'fa-rocket' )
              .addClass ( 'fa-certificate' );

      $('table.OL tr[data-id="' + userCourse.id + '"]')
        .find ( '.itr-course-btn-bank a.certificate-btn' )
          .find ( 'span' )
            .html ( '&nbsp;' + terms.certificate );
    }
  },

  //////////////////////
  //////////////////////
  // EDITING DEADLINE //

  editingDeadlineDate : {
    itrSection : null,
    id         : null,
    origDate   : null,
  },

  makeDeadlinesEditable : function() {
    ITR.editingDeadlineDate = {
      method        : null,
      itrSection    : null,
      renewalDivTag : null,
      id            : null,
      renewalPeriod : null,
      renewalDate   : null,
    };

    if ( $('button.edit-deadline').length > 0 ) {
      $('button.edit-deadline')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          // console.log($(this).closest('table').data('itr-section'), $(this).closest('table').attr('data-itr-section'));
          ITR.editingDeadlineDate.renewalDivTag = $(this).closest('tr').find('.itr-deadline-date');
          ITR.editingDeadlineDate.itrSection    = $(this).closest('table').data('itr-section');
          ITR.editingDeadlineDate.id            = $(this).closest('tr').data('id');// - SHOULD BE THE LINK ID

          main.flashData.show (
            'By default, deadlines / renewals are not manually set, they are calculated using the completion date + the default renewal period for the particular course.<br>You can override this with a specific date or a different renewal period.',
            'neutral',
            -1
          );

          popup.generatePopup({
            title: 'Override Renewal Date',
            width: 550,
            height: 225,
            method: 'ajax',
            ajaxUrl: '/AJAX_itr/show-renewal-override-popup',
            success: function() {
              if ( $('#edit-deadline-method').length > 0 ) {
                $('#edit-deadline-method')
                  .on ( 'change', function ( evt ) {
                    ITR.editingDeadlineDate.method = $(this).val();

                    $('.edit-deadline-method').addClass('dn');

                    if ( $('#edit-deadline-method-' + ITR.editingDeadlineDate.method).length > 0 ) {
                      $('#edit-deadline-method-' + ITR.editingDeadlineDate.method).removeClass('dn');
                    }
                  });
              }

              $('button#edit-deadline-save')
                .on('click', function() {
                  var sendData = {
                    itrSection : ITR.editingDeadlineDate.itrSection,
                    id         : ITR.editingDeadlineDate.id,
                  };

                  switch ( ITR.editingDeadlineDate.method ) {
                    case 'not-set':
                      sendData.method = 'notSet';
                      sendData.value  = 'Not Set';
                      break;
                    case 'renewal-period':
                      ITR.editingDeadlineDate.renewalPeriod = '+' + $('#edit-deadline-method-renewal-period #renewal-period-num').val() + ' ' + $('#edit-deadline-method-renewal-period #renewal-period-unit').val();

                      sendData.method        = 'renewalPeriod';
                      sendData.returnFormat  = 'd-m-Y';
                      sendData.renewalPeriod = ITR.editingDeadlineDate.renewalPeriod;
                      break;
                    case 'renewal-date':
                      ITR.editingDeadlineDate.renewalDate = $('#edit-deadline-method-renewal-date #renewal-date-ts').val();

                      sendData.method        = 'renewalDate';
                      sendData.returnFormat  = 'd-m-Y';
                      sendData.renewalDate   = ITR.editingDeadlineDate.renewalDate;
                      break;
                  }
                  // console.log('edit renewal', sendData);
                  $.ajax({
                    url: '/AJAX-itr/override-deadline-date',
                    type: 'post',
                    data: sendData,
                    dataType: 'json',
                    success: function ( returnedData ) {
                      // console.log(returnedData);
                      var display = returnedData.result === null ? "Not Set" : returnedData.result;
                      ITR.editingDeadlineDate.renewalDivTag.html ( display );
                      main.flashData.hide();
                      popup.close();
                    },
                  });
                });

            },
          });
        });
    }
  },

  makeDeadlineEditable : function ( itrSection, id ) {
    // - SHOW FLASH MESSAGE
    main.flashData.show (
      'By default, deadlines / renewals are not manually set, they are calculated using the completion date + the default renewal period for the particular course. You can override this with a specific date.<br>If you would like to undo this override, click edit <i class="fa fa-pencil"></i> and then the reset <i class="fa fa-undo"></i> icon',
      'neutral',
      -1
    );

    if ( $('.itr-deadline.editing').length > 0 ) {
      // - IF THERE IS A RENEWAL BEING EDITED, WE NEED TO SET IT BACK TO WHAT IT WAS IN THE MARK-UP
      ITR.cancelEditingDeadline ( false );
    }

    // - ADD AN 'EDITING' CLASS TO THE ONE WE'VE CLICKED
    $('table[data-itr-section="' + itrSection + '"]')
      .find('tr[data-id="' + id + '"]')
      .find('.itr-deadline')
        .addClass ( 'editing' );

    // - NOW THAT WE'VE CLEANED OUR ROOM, POPULATE OUR HOLDING PROPS
    ITR.editingDeadlineDate.itrSection = itrSection;
    ITR.editingDeadlineDate.id         = id;
    ITR.editingDeadlineDate.origDate   = $('table[data-itr-section="' + itrSection + '"]')
                                           .find('tr[data-id="' + id + '"]')
                                           .find('.itr-deadline-date')
                                           .text();

    // - COPY THE CURRENT DEADLINE DATE INTO OUR HOLDING TAG (INVISIBLE TO USER)
    $('#prev-deadline-date-holding').html (
      $('table[data-itr-section="' + itrSection + '"]')
        .find('tr[data-id="' + id + '"]')
        .find('.itr-deadline')
        .html()
    );

    // - REPLACE IT WITH THE TEXT FIELD AND BUTTONS
    $('table[data-itr-section="' + itrSection + '"]')
      .find('tr[data-id="' + id + '"]')
      .find('.itr-deadline')
        .empty()
        .append (
          $('<div></div>')
            .addClass ( 'itr-deadline-editing' )
            .append (
              $('<input>')
                .attr ( 'type', 'text' )
                .attr ( 'id', 'tf-itr-deadline' )
                .val  ( ITR.editingDeadlineDate.origDate ),
              $('<input>')
                .attr ( 'type', 'hidden' )
                .attr ( 'id', 'hd-itr-deadline' ),
              $('<button></button>')
                .addClass ( 'cancel' )
                .append (
                  $('<i></i>')
                    .addClass ( 'fa fa-times' )
                    .attr ( 'title', 'Cancel' )
                ),
              $('<button></button>')
                .addClass ( 'reset' )
                .append (
                  $('<i></i>')
                    .addClass ( 'fa fa-undo' )
                    .attr ( 'title', 'Reset to default deadline' )
                ),
              $('<button></button>')
                .addClass ( 'confirm' )
                .append (
                  $('<i></i>')
                    .addClass ( 'fa fa-check' )
                    .attr ( 'title', 'Override the date' )
                )
            )
        );

    // - ADD FIELD AND BUTTON FUNCTIONALITY
    $('#tf-itr-deadline').datepicker({
      dateFormat: 'dd-mm-yy',
      altField: '#hd-itr-deadline',
      altFormat: 'yy-mm-dd',
    });
    $('#tf-itr-deadline').datepicker( 'setDate', $('#tf-itr-deadline').val() );
    // - CANCEL
    $('.itr-deadline-editing button.cancel')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        ITR.cancelEditingDeadline ( false );
      });
    // - RESET
    $('.itr-deadline-editing button.reset')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        ITR.resetEditingDeadline();
      });
    // - CONFIRM
    $('.itr-deadline-editing button.confirm')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        ITR.confirmEditingDeadline();
      });
  },

  cancelEditingDeadline : function ( getValue ) {
    // - SHOULD WE GET THE VALUE OF THE RENEWAL AGAIN?
    if ( getValue ) {
      // - SET UP SEND DATA
      var sendData = {
        itrSection : ITR.editingDeadlineDate.itrSection,
        id         : ITR.editingDeadlineDate.id,
      };

      $.ajax({
        url: '/AJAX-itr/get-deadline-date',
        type: 'post',
        data: sendData,
        dataType: 'json',
        success: function ( returnedData ) {
          // console.log(returnedData);
        },
      });
    }
    else {
      $('table[data-itr-section="' + ITR.editingDeadlineDate.itrSection + '"]')
        .find('tr[data-id="' + ITR.editingDeadlineDate.id + '"]')
        .find('.itr-deadline')
          .removeClass ( 'editing' )
          .html (
            $('#prev-deadline-date-holding').html()
          );
    }

    ITR.makeDeadlinesEditable();

    main.flashData.hide();
  },

  confirmEditingDeadline : function() {
    // - SET UP SEND DATA
    var sendData = {
      itrSection : ITR.editingDeadlineDate.itrSection,
      id         : ITR.editingDeadlineDate.id,
      newDate    : $('table[data-itr-section="' + ITR.editingDeadlineDate.itrSection + '"]')
                     .find('tr[data-id="' + ITR.editingDeadlineDate.id + '"]')
                     .find('#hd-itr-deadline')
                       .val(),
    };

    $.ajax({
      url: '/AJAX-itr/override-deadline-date',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        if ( returnedData.success ) {
          ITR.cancelEditingDeadline ( false );
        }
      },
    });

    main.flashData.hide();
  },

  resetEditingDeadline : function() {
    // - SET UP SEND DATA
    var sendData = {
      itrSection : ITR.editingDeadlineDate.itrSection,
      id         : ITR.editingDeadlineDate.id,
    };

    $.ajax({
      url: '/AJAX-itr/undo-override-deadline-date',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        if ( returnedData.success ) {
          ITR.cancelEditingDeadline ( true );
        }
      },
    });

    main.flashData.hide();
  },

  ILT : {
    showHistoricalSessions : function ( userCourseID ) {
      popup.generatePopup({
        title: 'Viewing Historical sessions attended',
        method: 'ajax',
        ajaxUrl: '/AJAX-itr/show-historical-sessions',
        maxWidth: 1000,
        maxHeight: 600,
        data: {
          userCourseID : userCourseID,
        },
      });
    },

    showUnlockedAssessments : function ( iltSessionID ) {
      popup.generatePopup({
        title: 'Viewing ILT Assessments',
        method: 'ajax',
        ajaxUrl: '/AJAX-itr/show-ilt-assessments-popup',
        maxWidth: 1000,
        maxHeight: 600,
        data: {
          iltSessionID : iltSessionID,
        },
      });
    },
  },

  filters : {
    init : function() {
      ITR.filters.provider.init();
    },

    provider : {
      init : function() {
        $.fn.dataTable.ext.search.push(
          function( settings, data, dataIndex ) {
            // console.log(settings, data[1], dataIndex);
            // - GETS THE PROVIDER ID BUT THIS DIDN'T WORK MORE THAN ONCE
            // console.log(settings.nTBody.rows[dataIndex].attributes[1].value);
            return data[1] === $('#provider-filter option:selected').text() || $('#provider-filter option:selected').val() === '';
          }
        );

        $('#provider-filter')
          .off ( 'change' )
          .on  ( 'change', function ( evt ) {
            dataTable.tables['DT-online-learning'].tbl.api().draw();
            dataTable.tables['DT-toolbox-talks'].tbl.api().draw();
          });
      },
      apply : function ( providerID ) {
        // providerID
      },
    },
  },
};

$(document).ready ( function ( evt ) {
  ITR.init();
});
