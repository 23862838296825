var Table = {
  addAnotherRowInit : function ( containerSelector, clickableSelector, selectorToClone, options ) {
    /**
     * DOESN'T JUST HAVE TO BE USED WITH TABLE ROWS, ANYTHING THAT REPEATS WILL DO
     * .new-item IS A KEY PART OF THIS FUNCTIONALITY, IT IS A CLASS
     * TEMPORARILY ADDED TO THE NEWLY CREATED ITEM WHICH IS THEN REMOVED
     * THE CALLBACK FUNCTION IS COMPLETED
     */

    // console.log(containerSelector, clickableSelector, selectorToClone, options);

    $(clickableSelector).off ( 'click' );
    $(clickableSelector).on  ( 'click', function ( evt ) {

      // console.log(containerSelector, clickableSelector, 'clicked');

      var clone = $(containerSelector).find(selectorToClone).clone();

      // console.log(clone);
      // console.log(containerSelector);
      // console.log(selectorToClone);
      // console.log('$(\'' + containerSelector + '\').find(\'' + selectorToClone + '\')');
      if ( options !== undefined
           && options !== null
           && options.blank !== undefined ) {
        for ( var i = 0; i < options.blank.length; i++ ) {
          if ( $( options.blank[i] ).prop ( 'tagName' ) === 'INPUT' ) {
            if ( $( options.blank[i] ).attr ( 'type' ) === 'checkbox' ) {
              clone.find ( options.blank[i] ).prop ( 'checked', false );
            }
            else {
              clone.find ( options.blank[i] ).val ( '' );
            }
          }
          else {
            clone.find ( options.blank[i] ).val  ( '' );
            clone.find ( options.blank[i] ).text ( '' );
          }
        }
      }

      clone.addClass ( 'new-item' );

      $(containerSelector).find(selectorToClone).parent().append ( clone );

      if ( options !== undefined
           && options !== null
           && options.onAdd !== undefined
           && options.onAdd !== null ) {
        options.onAdd();
      }

      $(containerSelector).find('.new-item').removeClass ( 'new-item' );
    });
  },
};
