var Elearning = {
  launchCourse : function ( userCourseID ) {
    Athena.loadAthenaWithCourse ( userCourseID );
  },

  launchAssessment : function ( assesmentID, playall ) {
     Athena.loadAthenaAssessment ( assesmentID, null, null, playall );
     $(".menu-item-start-btn").trigger("click");
  },

  launchQuestion : function ( questionID, assesmentID ) {
     Assessment.loadAssessmentQuestion ( questionID, assesmentID );
  },

  unenrol : function ( userCourseID, tableKey ) {
    var sendData = {
      userCourseID : userCourseID,
    };
    
    $.ajax({
      url: 'AJAX-elearning/unenrol',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        if ( returnedData.success ) {
          $('a.unenrol-user-course-btn[data-id="' + userCourseID + '"]')
            .closest ( 'tr' )
            .remove();
        }
      },
    });
  },
};
