var main = {

  userID : null,
  signInStatus : null,

  ready : function ( evt ) {
    $(document).off ( 'click', QuickHelp.hide );
    $(document).on  ( 'click', QuickHelp.hide );

    QuickHelp.init();

    $.ajax({
      url: '/AJAX-main/get-sign-in-status',
      dataType: 'json',
      success: function ( returnedData ) {
        main.userID = returnedData.userID;
        main.signInStatus = returnedData.signInStatus;
      },
    });
  },

  view : function ( url, id, success ) {
    $.ajax({
      url      : url,
      data     : '&id=' + id,
      type     : 'post',
      dataType : 'json',
      success  : success,
    });
  },

  edit : function ( url, id, success ) {
    $.ajax({
      url      : url,
      data     : '&id=' + id,
      type     : 'post',
      dataType : 'json',
      success  : success,
    });
  },

  delete : function ( url, id, success ) {
    $.ajax({
      url      : url,
      data     : '&id=' + id,
      type     : 'post',
      dataType : 'json',
      success  : success,
    });
  },

  /**
   * FLASH DATA
   */

  flashData : {
    isShowing : false,

    show : function ( content, className, _disappear ) {
      var disappear;
      if ( _disappear === undefined ) {
        disappear = 4;
      }
      else if ( _disappear > 0 ) {
        disappear = _disappear;
      }
      else {
        disappear = false;
      }

      $('#flash-data').removeClass ( 'positive' );
      $('#flash-data').removeClass ( 'negative' );
      $('#flash-data').removeClass ( 'neutral' );

      $('#flash-data').addClass ( className );

      $('#flash-data .content').html ( content );

      TweenMax.to ( $('#flash-data'), 0.5, {
        top: 0,
      });

      $('.flash-data .close-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
        main.flashData.hide();
      });

      if ( disappear !== false ) {
        TweenMax.to ( $('#flash-data'), 0.5, {
          top: (-$('#flash-data').outerHeight()-1) + 'px',
          delay: disappear,
          onComplete: function() {
            main.flashData.isShowing = false;
          },
        });
      }

      main.flashData.isShowing = true;
    },

    hide : function() {
      $('.flash-data .close-btn').off ( 'click' );

      TweenMax.killTweensOf ( $('#flash-data') );

      TweenMax.to ( $('#flash-data'), 0.5, {
        top: (-$('#flash-data').outerHeight()-1) + 'px',
      });

      main.flashData.isShowing = false;
    },
  },

  flashButton : function ( selector ) {
    TweenMax.delayedCall ( 0.5, function() {
      $(selector).addClass ( 'flash-button-on' );
    });

    TweenMax.delayedCall ( 1, function() {
      $(selector).removeClass ( 'flash-button-on' );
    });

    TweenMax.delayedCall ( 1.5, function() {
      $(selector).addClass ( 'flash-button-on' );
    });

    TweenMax.delayedCall ( 2, function() {
      $(selector).removeClass ( 'flash-button-on' );
    });
  },

  shuffle : function ( array ) {
    var copy = [], n = array.length, i;

    // While there remain elements to shuffle…
    while (n) {

      // Pick a remaining element…
      i = Math.floor(Math.random() * n--);

      // And move it to the new array.
      copy.push(array.splice(i, 1)[0]);
    }

    return copy;
  },

  // - PERSISTENT SESSION
  checkPersistentSession_int : null,
  TO_countdown_int : null,
  TO_period        : null,// - HOW LONG IS THE PERIOD BEFORE TIMEOUT
  TO_warn_period   : null,// - HOW LONG BEFORE THE TIMEOUT DO WE WARN
  TO_time          : null,// - THE UNIX TIMESTAMP WHEN TIMEOUT WILL OCCUR
  TO_warn          : null,// - THE UNIX TIMESTAMP WHEN THE WARNING SHOULD TRIGGER

  initCheckPersistentSession : function() {
    // main.checkPersistentSession_int = setInterval ( function() {
    //   $.ajax({
    //     url: '/utils/AJAX-get-active-login/check-last-activity',
    //     dataType: 'json',
    //     success: function ( returnedData ) {
    //       // console.log(returnedData);
    //       if ( returnedData.signedIn !== undefined && returnedData.signedIn === false ) {
    //         clearInterval ( main.checkPersistentSession_int );
    //       }
    //       else {
    //         main.TO_period      = Number ( returnedData.TO_period );// - HOW LONG IS THE PERIOD BEFORE TIMEOUT
    //         main.TO_warn_period = Number ( returnedData.TO_warn_period );// - HOW LONG BEFORE THE TIMEOUT DO WE WARN
    //         main.TO_time        = Number ( returnedData.TO_time );// - THE UNIX TIMESTAMP WHEN TIMEOUT WILL OCCUR
    //         main.TO_warn        = Number ( returnedData.TO_warn );// - THE UNIX TIMESTAMP WHEN THE WARNING SHOULD TRIGGER

    //         // console.log(Date.now() / 1000, main.TO_warn, Date.now() / 1000 > main.TO_warn);
    //         if ( Date.now() / 1000 > main.TO_warn && ! main.flashData.isShowing ) {
    //           main.flashData.show (
    //             'WARNING: You will be automatically signed out in <span id="continue-timer" class="continue-timer"></span> due to inactivity. Click <button id="continue-session-btn">here</button> to continue your session.',
    //             'negative',
    //             -1
    //           );

    //           $('#continue-session-btn')
    //             .off ( 'click' )
    //             .on  ( 'click', function ( evt ) {
    //               $.ajax({
    //                 url: '/utils/AJAX-persist-active-login/persist-session',
    //                 dataType: 'json',
    //                 success: function ( returnedData ) {
    //                   // console.log(returnedData);
    //                   main.flashData.hide();
    //                   clearInterval ( main.TO_countdown_int );
    //                 },
    //               });
    //             });

    //           if ( main.TO_countdown_int === null ) {
    //             main.TO_countdown_int = setInterval ( function() {
    //               // console.log(Date.now(),main.TO_time,'-',Math.floor ( (Date.now() / 1000) ),'=',main.TO_time - Math.floor ( (Date.now() / 1000) ));
    //               if ( main.TO_time - Math.floor ( (Date.now() / 1000) ) === 0 ) {
    //                 clearInterval ( main.TO_countdown_int );
    //                 window.location.href = "/sign-out";
    //               }
    //               else {
    //                 $('#continue-timer').text ( time.format (
    //                   main.TO_time - Math.floor((Date.now() / 1000))
    //                 ));
    //               }
    //             }, 1000);
    //           }

    //         }
    //       }
    //     },
    //   });
    // }, 1000);
  },


  getSub : function() {
    return window.location.host.substring ( 0, window.location.host.indexOf ( '.' ) );
  },

};

$(document).ready ( function ( evt ) {
  main.ready ( evt );
  main.initCheckPersistentSession();
});
