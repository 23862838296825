var DateTime = {

  human_to_mysql : function ( dateStr, glue ) {
    var dateArr = dateStr.split ( glue );

    dateArr.reverse();

    return dateArr.join('-');
  },

  isDate : function ( dateStr ) {
    // - FORMAT DD/MM/YYYY
    var dateArr = dateStr.split('/');

    dateArr[1] = Number(dateArr[1])-1;

    var date = new Date ( dateArr[2], dateArr[1], dateArr[0] );

    return date.toString() !== 'Invalid Date';
  },

  isTime : function ( timeStr ) {
    // - FORMAT HH:II:SS
    var timeArr = timeStr.split(':');

    if ( timeArr.length !== 3 ) {
      return false;
    }

    if ( isNaN ( Number ( timeArr[0] ) ) || isNaN ( Number ( timeArr[1] ) ) || isNaN ( Number ( timeArr[2] ) ) ) {
      return false;
    }

    if ( Number ( timeArr[0] ) > 23 ) {
      return false;
    }

    if ( Number ( timeArr[1] ) > 59 ) {
      return false;
    }

    if ( Number ( timeArr[2] ) > 59 ) {
      return false;
    }

    return true;
  },

};
