var locations = {
  /**
   * ON INITIATION ADD THE ID, THE VAR NAME AND THE CALLBACK FUNCTIONS
   * TO THIS OBJECT, KEY IS THE ID OF THE SELECTOR
   */
  selectors : {},

  initSelector : function ( id, callback ) {

    if ( locations.selectors[id] === undefined ) {
      locations.selectors[id] = {
        selected : null,
        callbacks : [
          callback,
        ],
      };
    }

    $('#' + id).off ( 'change' );
    $('#' + id).on  ( 'change', function(evt) {
      var selectedInstsSites = {},
          selectElem         = $(this),
          selectID           = $(selectElem).attr('id');

      // - ITEM SELECTED
      // - IF ANY [INST] > All Sites ARE SELECTED
      // - ADD ALL THAT INSTITUTION'S SITES TO OUTPUT ARRAY
      $(selectElem).find('option:selected').each ( function ( index, elem ) {

        //console.log($(selectElem).val());

        if ( $(elem).val() !== '-' && $(elem).val() !== 'ALL' && $(elem).val() !== 'STANDALONE' ) {
          // console.log($(elem).val());
          var tmpSite,
              selectedObj = JSON.parse ( str.stripslashes ( $(elem).val() ) );

          if ( selectedInstsSites[selectedObj.inst] === undefined ) {
            selectedInstsSites[selectedObj.inst] = {instName:selectedObj.instName};

            if ( selectedInstsSites[selectedObj.inst].sites === undefined ) {
              selectedInstsSites[selectedObj.inst].sites = {};
            }
          }

          // - ALL
          if ( selectedObj.site === 'ALL' ) {
            // - GET ALL THE OPTIONS THAT AREN'T THE ALL SITES OPTION
            $(elem).parent().find('option:not([value*="ALL"])').each ( function (allIndex, allElem ) {
              // - PARSE IT
              tmpSite = JSON.parse ( str.stripslashes ( $(allElem).val() ) );
              // - ONLY ADD IT TO THE SITES ARRAY IF IT'S NOT ALREADY IN THERE
              if ( selectedInstsSites[selectedObj.inst].sites[tmpSite.site] === undefined ) {
                selectedInstsSites[selectedObj.inst].sites[tmpSite.site] = {siteName:tmpSite.siteName};
              }
            });
          }
          else {
            // - ONLY ADD IT TO THE SITES ARRAY IF IT'S NOT ALREADY IN THERE
            if ( selectedInstsSites[selectedObj.inst].sites[selectedObj.site] === undefined ) {
              selectedInstsSites[selectedObj.inst].sites[selectedObj.site] = {siteName:selectedObj.siteName};
            }
          }

        }
      });

      if ( $(selectElem).val().indexOf('STANDALONE') !== -1 ) {
        // - USERS NOT ATTACHED TO COMPANY OR SITE
        selectedInstsSites.standalone = true;
      }

      if ( $(selectElem).val().indexOf('-') !== -1 ) {
        // - NONE SELECTED - LEAVE IT BLANK
        $(selectElem).find('option[value="-"]').prop ( 'selected', false );
      }
      else if ( $(selectElem).val().indexOf('ALL') !== -1 ) {
        selectedInstsSites.all = true;
        selectedInstsSites.standalone = true;
        $(selectElem).find('option:not([value="ALL"])').prop ( 'selected', false );
      }

      locations.selectors[selectID].selected = selectedInstsSites;

      //console.log('selectedInstsSites', selectedInstsSites);
      for ( var i = 0; i < locations.selectors[selectID].callbacks.length; i++ ) {
        locations.selectors[selectID].callbacks[i] ( selectedInstsSites );
      }



    });

  },

};
