// @Ilt-SESSIONS
var IltSessions = {

  sessionID : null,

  removeAndDeleteManualUser : function ( sessionUserLinkID, manualUserID, callback ) {
    //console.log(81, sessionUserLinkID, manualUserID);
    var sendData = {
      sessionUserLinkID : sessionUserLinkID,
      manualUserID : manualUserID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/remove-and-delete-manual-user',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        //console.log('remove-and-delete-manual-user', returnedData);
        callback();
      },
    });
  },

  setInstructors : function ( instructorIDs, sessionID ) {
    var sendData = {
      instructorIDs : instructorIDs,
      sessionID : sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/set-instructors',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log ( returnedData );
        if ( returnedData.success ) {
          main.flashData.show ( 'Instructors for this session have been saved successfully.', 'positive', 5 );
          TweenMax.delayedCall ( 5, popup.close );
        }
      },
    });
  },

  getInstructors : function ( sessionID ) {
    var sendData = {
      sessionID : sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/get-instructors',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log ( returnedData );
      },
    });
  },

  setTrainees : function ( traineeIDs, sessionID ) {
    // console.log ( traineeIDs, sessionID );

    var sendData = {
      traineeIDs : traineeIDs,
      sessionID : sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/set-trainees',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log ( returnedData );
        if ( returnedData.success ) {
          main.flashData.show ( 'Trainees for this session have been saved successfully.', 'positive', 5 );
          TweenMax.delayedCall ( 5, popup.close );
        }
      },
    });
  },

  getTrainees : function ( sessionID ) {
    // console.log ( sessionID );

    var sendData = {
      sessionID : sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/get-trainees',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log ( returnedData );
      },
    });
  },

  enrol : function ( elem, _callback ) {
    var userID = $(elem).closest('tr').attr ( 'data-user-id' );

    var sendData = {
      userID : userID,
      sessionID : IltSessions.sessionID,
    };
    
    $.ajax({
      url: '/admin/AJAX-ilt-sessions/enrol',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        if ( returnedData.success && _callback !== undefined ) {
          _callback ( returnedData );
        }
      },
    });
  },

  unenrol : function ( elem, _callback ) {
    var userID = $(elem).closest('tr').attr ( 'data-user-id' );

    var sendData = {
      userID : userID,
      sessionID : IltSessions.sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/unenrol',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        if ( returnedData.success && _callback !== undefined ) {
          _callback ( returnedData );
        }
      },
    });
  },

  addInstructor : function ( elem, _callback ) {
    var userID = $(elem).closest('tr').attr ( 'data-user-id' );

    var sendData = {
      userID : userID,
      sessionID : IltSessions.sessionID,
    };
    
    $.ajax({
      url: '/admin/AJAX-ilt-sessions/add-instructors',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        if ( returnedData.success && _callback !== undefined ) {
          _callback ( returnedData );
        }
      },
    });
  },

  removeInstructor : function ( elem, _callback ) {
    var userID = $(elem).closest('tr').attr ( 'data-user-id' );

    var sendData = {
      userID : userID,
      sessionID : IltSessions.sessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/remove-instructors',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        if ( returnedData.success && _callback !== undefined ) {
          _callback ( returnedData );
        }
      },
    });
  },

  showAddVenue : function() {
    popup.generatePopup({
      title: 'Adding Venue',
      method: 'ajax',
      ajaxUrl: '/AJAX_Cms/add-record/venue',
      maxWidth: 700,
      success: function() {

      },
    });
  },

  deleteSession : function ( iltSessionID ) {
    var rowNode = $('#DT-ilt-sessions').find('tr[data-id="' + iltSessionID + '"]');

    // var sendData = {
    //   id : id,
    // };

    // $.ajax({
    //   url: '/admin/AJAX-ilt-sessions/delete-session',
    //   data: sendData,
    //   type: 'post',
    //   dataType: 'json',
    //   success: function ( returnedData ) {
    //     if ( returnedData.success ) {
    //       dataTable.tables['DT-ilt-sessions'].tbl.api()
    //         .row ( $(rowNode) )
    //         .remove()
    //         .draw();
    //     }
    //   }
    // });

    popup.generatePopup({
      title: 'Confirm Action',
      method: 'ajax',
      ajaxUrl: '/AJAX-admin/show-confirm-high-level-action-with-password',
      width: 450,
      height: 200,
      success: function ( params ) {
        $('#confirm-action-with-pw-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            var sendData = {
              confirm: $('input[name="confirm-password"]').val(),
              targetMethod: 'delete-ilt-session',
              params: params,
            };
            
            $.ajax({
              url: '/AJAX-admin/confirm-high-level-action-with-password',
              type: 'post',
              data: sendData,
              dataType: 'json',
              success: function ( returnedData ) {
                var msg, msgState;
                // console.log(returnedData);
                if ( returnedData.success ) {
                  msg = "This session has been successfully deleted";
                  msgState = "positive";

                  dataTable.tables['DT-ilt-sessions'].tbl.api().row('[data-id="' + returnedData.iltSessionID + '"]').remove().draw();
                }
                else {
                  msgState = "negative";

                  switch ( returnedData.reason ) {
                    case 'bad-permission':
                      msg = "You don't possess the required permissions to perform this task";
                      break;
                    case 'password-incorrect':
                      msg = "The password you entered was incorrect";
                      break;
                  }
                }
                
                popup.close();

                main.flashData.show ( msg, msgState, 5 );
              },
              error: Errors.ajaxErrorHandler,
            });
            
            // console.log(params.iltSessionID);
          });
      },
      successParams : {
        iltSessionID: iltSessionID,
      },
    });
  },

  refreshLockButtonEvents : function() {
    $('.ilt-assessment-lock-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        if ( $(this).attr ( 'data-state' ) === '1' ) {
          $(this).switchClass ( 'neutral-btn', 'positive-btn' );

          $(this).find('svg').addClass ( 'fa-lock-open' );

          $(this).find('svg').removeClass ( 'fa-lock' );

          $(this).find('span').text ( 'Unlocked' );

          $(this).attr ( 'data-state', '0' );
        }
        else {
          $(this).switchClass ( 'positive-btn', 'neutral-btn' );
          
          $(this).find('svg').addClass ( 'fa-lock' );

          $(this).find('svg').removeClass ( 'fa-lock-open' );

          $(this).find('span').text ( 'Locked' );

          $(this).attr ( 'data-state', '1' );
        }

        var userIDs = [];
        $('#DT-ILT-sessions-delegates tr.system-user').each ( function ( index, elem ) {
          userIDs.push ( $(elem).attr ( 'data-user-id' ) );
        });

        var sendData = {
          id      : $(this).closest('tr').attr ( 'data-id' ),
          locked  : $(this).attr ( 'data-state' ),
          userIDs : userIDs,
        }

        $.ajax({
          url: '/admin/AJAX-ilt-sessions/update-assessment-lock',
          data: sendData,
          type: 'post',
          dataType: 'text',
          success: function ( returnedData ) {
            // console.log(returnedData);
          }
        });
      });
  },

  refreshATUploadBtnEvents : function() {

    $('.upload-ilt-singular-file-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {

        // - IF THERE IS NO DOC ID, CAN UPLOAD...
        var idBlank     = $(this).closest('tr').attr('data-id') === '';
        var idNull      = $(this).closest('tr').attr('data-id') === null;
        var idUndefined = $(this).closest('tr').attr('data-id') === undefined;
        var idAbsent    = idBlank || idNull || idUndefined;

        if ( idAbsent ) {

          var singularFileKey = $(this).closest('tr').attr('data-class');
          var typeVerbose;
          var fileTypes = ['jpg','jpeg','png','gif','doc','docx','xls','xlsx','ppt','pptx','pps','ppsx','pdf','htm','html','txt'];

          switch ( singularFileKey ) {
            case 'joining-instructions':
              typeVerbose = 'Joining Instructions';
              fileTypes = ['pdf'];
              IltSessions.uploadFunctionInUse = IltSessions.getJoiningInstructions;
              break;
            case 'attendance':
              typeVerbose = 'Attendance Record';
              fileTypes = ['pdf'];
              IltSessions.uploadFunctionInUse = IltSessions.getAttendance;
              break;
            case 'feedback':
              typeVerbose = 'Feedback Forms';
              fileTypes = ['pdf'];
              IltSessions.uploadFunctionInUse = IltSessions.getFeedback;
              break;
            case 'written-qs':
              typeVerbose = 'Completed Written Questions';
              fileTypes = ['pdf'];
              IltSessions.uploadFunctionInUse = IltSessions.getWrittenQs;
              break;
          }

          popup.onClose = function() {
            IltSessions.uploadFunctionInUse = null;
          };

          popup.generatePopup ({
            title: typeVerbose + ' Upload',
            method: 'ajax',
            ajaxUrl: '/AJAX-inline-upload/get-markup-for-popup',
            data: {
              repos        : 'ilt-sessions-' + singularFileKey,
              subpath      : IltSessions.sessionID,
              id           : 'inline-upload-' + singularFileKey,
              hostSelector : '#inline-upload-' + singularFileKey,
              fileTypes    : fileTypes,
            },
            maxWidth: 1000,
            maxHeight: 390,
            footer: false,
          });

        }
        else {
          // - ID IS PRESENT SO THROW A BANNER
          main.flashData.show (
            'Please delete the existing file first, then upload its replacement.', 'negative', 4
          );
        }
      });

    $('.remove-ilt-singular-file-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {

        var idNotBlank     = $(this).closest('tr').attr('data-id') !== '';
        var idNotNull      = $(this).closest('tr').attr('data-id') !== null;
        var idNotUndefined = $(this).closest('tr').attr('data-id') !== undefined;
        var idPresent      = idNotBlank && idNotNull && idNotUndefined;

        // - IF THERE IS A DOC ID, CAN REMOVE...
        if ( idPresent ) {

          var singularFileKey = $(this).closest('tr').attr('data-class');
          var singularFileID  = $(this).closest('tr').attr('data-id');

          IltSessions.removeILTSingularFile (
            singularFileKey, singularFileID
          );
        }
        else {
          // - ID NOT PRESENT SO NOTHING TO DELETE, THROW A BANNER
          main.flashData.show (
            'No file has been uploaded yet.', 'negative', 4
          );
        }
      });

  },

  getJoiningInstructions : function ( returnedData ) {
    IltSessions.getILTSingularFile ( 'joining-instructions', returnedData );
  },

  getAttendance : function ( returnedData ) {
    IltSessions.getILTSingularFile ( 'attendance', returnedData );
  },

  getFeedback : function ( returnedData ) {
    IltSessions.getILTSingularFile ( 'feedback', returnedData );
  },

  getWrittenQs : function ( returnedData ) {
    IltSessions.getILTSingularFile ( 'written-qs', returnedData );
  },

  // - AN ILT SINGULAR FILE IS ONE OF THE FILES AT THE BOTTOM OF THE ILT SESSION PAGE
  // - SINGULAR REFERS TO THE FACT THAT ONLY ONE CAN BE UPLOAD FOR EACH DOC CLASS
  getILTSingularFile : function ( docClass, returnedData ) {
    var sendData = {
      sessionID : IltSessions.sessionID,
      docID     : returnedData.files[0].id,
      field     : docClass.split('-').join('_') + '_doc',
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/set-singular-file',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .attr('data-id', returnedData.document.id)
          .find ( 'td[data-field="file-name"]')
            .empty()
            .append (
              $('<a></a>')
                .attr ( 'href', '/uploads/' + main.getSub() + '/' + returnedData.document.path )
                .attr ( 'target', '_blank' )
                .text ( returnedData.document.title )
              );

        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .find ( '.upload-ilt-singular-file-btn' )
            .removeClass ( 'positive-btn' )
            .addClass ( 'disabled-btn' );

        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .find ( '.remove-ilt-singular-file-btn' )
            .removeClass ( 'disabled-btn' )
            .addClass ( 'negative-btn' );
      },
    });
  },

  removeILTSingularFile : function ( docClass, docID ) {
    var sendData = {
      sessionID : IltSessions.sessionID,
      docID     : docID,
      field     : docClass.split('-').join('_') + '_doc',
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/remove-singular-file',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .attr('data-id', null)
          .find ( 'td[data-field="file-name"]')
            .text ( 'No File Uploaded' );

        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .find ( '.upload-ilt-singular-file-btn' )
            .removeClass ( 'disabled-btn' )
            .addClass ( 'positive-btn' );

        $('#DT-AT-ILT-session-uploads tr[data-class="' + docClass + '"]')
          .find ( '.remove-ilt-singular-file-btn' )
            .removeClass ( 'negative-btn' )
            .addClass ( 'disabled-btn' );
      },
    });
  },

  updateDatePassed : function ( sessionUserLinkID, datePassed ) {
    // console.log( 'updateDatePassed', sessionUserLinkID, datePassed );
    var sendData = {
      sessionUserLinkID : sessionUserLinkID,
      datePassed : datePassed,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/ilt-session-update-date-passed',
      data: sendData,
      type: 'post',
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log ( returnedData );
      },
    });

  },

  updateManualUserDatePassed : function ( sessionUserLinkID, datePassed ) {
    //console.log( 'updateDatePassed', sessionUserLinkID, datePassed );
    var sendData = {
      sessionUserLinkID : sessionUserLinkID,
      datePassed : datePassed,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/ilt-session-update-manual-user-date-passed',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        //console.log ( returnedData );
      },
    });

  },

  updateManualUserStatus : function ( sessionUserLinkID, status ) {
    //console.log( 'updateStatus', sessionUserLinkID, status );
    var sendData = {
      sessionUserLinkID : sessionUserLinkID,
      status : status,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/ilt-session-update-manual-user-status',
      data: sendData,
      type: 'post',
      dataType: 'json',
      success: function ( returnedData ) {
        //console.log ( returnedData );
      },
    });

  },

  updateStatus : function ( sessionUserLinkID, status ) {
    //console.log( 'updateStatus', sessionUserLinkID, status );
    var sendData = {
      sessionUserLinkID : sessionUserLinkID,
      status : status,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/ilt-session-update-status',
      data: sendData,
      type: 'post',
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log ( returnedData );
      },
    });

  },

  uploadFunctionInUse : null,

  checkAllAssessmentsPassed : function ( params ) {
    var sendData = {
      iltSessionID : params.IltSessionID,
    };

    $.ajax({
      url: '/admin/AJAX-ilt-sessions/check-all-assessments-passed',
      data: sendData,
      type: 'post',
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log ( returnedData );
        if ( returnedData.success ) {
          if ( returnedData.allPassed ) {
            main.flashData.show (
              "All assessments are now complete. When your instructor confirms your completion you will find a link to your certificate in your Individual Training Record.",
              "positive",
              -1
            );
          }
        }
      },
    });
  },

};
