var QuickHelp = {
  init : function () {
    $('.quick-help-btn').off ( 'click' );
    $('.quick-help-btn').on  ( 'click', function ( evt ) {
      evt.stopPropagation();
      TweenMax.to ( $('.quick-help-text'), 0.5, {autoAlpha: 0} );
      TweenMax.to ( $(this).parent().find('.quick-help-text'), 0.5, {autoAlpha: 1} );
    });

    $('.quick-help-text').off ( 'click' );
    $('.quick-help-text').on  ( 'click', function ( evt ) {
      TweenMax.to ( $(this), 0.5, {autoAlpha: 0} );
    });
  },

  hide : function ( evt ) {
    TweenMax.to ( $('.quick-help-text'), 0.5, {autoAlpha: 0} );
  },
};
