var Status = {
  generateStatusTag : function ( statusCode, context ) {
    var output;

    switch ( context ) {
      case 'occupational-competence':
      case 'online-learning':
        switch ( statusCode ) {
          case 'P':
            output = '<div class="circle-icon passed">' +
                        '<i class="fa fa-check"></i>' +
                      '</div>';
            break;
          case 'I':
            output = '<div class="circle-icon incomplete">' +
                        '<i class="ellipsis"></i>' +
                      '</div>';
            break;
          case 'N':
            output = '<div class="circle-icon not-started red">' +
                        '<i class="dash"></i>' +
                      '</div>';
            break;
          case 'F':
            output = '<div class="circle-icon failed">' +
                        '<i class="fa fa-times"></i>' +
                      '</div>';
            break;
          case 'L':
            output = '<div class="circle-icon locked">' +
                        '<i class="fa fa-lock"></i>' +
                      '</div>';
            break;
          default:
            output = '-';
            break;
        }
        break;
      case 'occupational-competence-small':
        switch ( statusCode ) {
          case 'P':
            output = '<div class="sm-circle-icon passed">' +
                        '<i class="fa fa-check"></i>' +
                      '</div>';
            break;
          case 'I':
            output = '<div class="sm-circle-icon incomplete">' +
                        '<i class="ellipsis"></i>' +
                      '</div>';
            break;
          case 'N':
            output = '<div class="sm-circle-icon not-started">' +
                        '<i class="dash"></i>' +
                      '</div>';
            break;
          case 'F':
            output = '<div class="sm-circle-icon failed">' +
                        '<i class="fa fa-times"></i>' +
                      '</div>';
            break;
          case 'L':
            output = '<div class="sm-circle-icon locked">' +
                        '<i class="fa fa-lock"></i>' +
                      '</div>';
            break;
          default:
            output = '-';
            break;
        }
        break;
      case 'ilt-courses':
        switch ( statusCode ) {
          case 'P':
            output = '<div class="circle-icon passed">' +
                        '<i class="fa fa-check"></i>' +
                      '</div>';
            break;
          case 'I':
            output = '<div class="circle-icon incomplete">' +
                        '<i class="ellipsis"></i>' +
                      '</div>';
            break;
          case 'N':
            output = '<div class="circle-icon not-started red">' +
                        '<i class="dash"></i>' +
                      '</div>';
            break;
          case 'F':
            output = '<div class="circle-icon failed">' +
                        '<i class="fa fa-times"></i>' +
                      '</div>';
            break;
        }
      break;
    case 'printable-itr':
      switch ( statusCode ) {
        case 'P':
          output = '<i class="fa fa-check"></i>';
          break;
        case 'I':
          output = '<i class="fa fa-ellipsis-h"></i>';
          break;
        case 'N':
          output = '<i class="fa fa-minus"></i>';
          break;
        case 'F':
          output = '<i class="fa fa-times"></i>';
          break;
        case 'L':
          output = '<i class="fa fa-lock"></i>';
          break;
        default:
          output = '-';
          break;
      }
      break;
    }

    return output;
  },
};
