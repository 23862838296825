var donutChart = {
  charts : {},
  instantiate : function(svg, id, init) {
    // console.log(svg);
    svg.attr({
      'outer-radius'   : init.outerRadius,
      'inner-radius'   : init.innerRadius,
      'centre-point-x' : init.centrePoint.x,
      'centre-point-y' : init.centrePoint.y,
    });
    
    if ( svg.select('g.segments') !== null ) {
      svg.select('g.segments').remove();
    }

    var group = svg.g().attr({
      class : 'segments',
      opacity: 1,
    });

    if ( init.printout ) {
      var bg = svg.rect().attr({
        'fill'   : '#EEEEEE',
        'width'  : 340,
        'height' : 195,
        'rx'     : 5,
        'ry'     : 5,
        'x'      : -130,
        'y'      : 0,
      });

      svg.append ( bg );
    }

    donutChart.calcDegrees ( init );
    donutChart.createSegments ( group, init );

    donutChart.charts[id] = {
      animPercent : 0,
      init : init,
    };

    if ( init.resetBtn ) {
      donutChart.addResetBtn ( group, init );
    }

    if ( init.animateIn ) {
      TweenMax.to ( donutChart.charts[id], 2, { animPercent : 1,
        onUpdate: function() {

          for ( i = 0; i < init.data.length; i++ ) {
            if ( init.data[i].endD - init.data[i].startD > 0 ) {
              var sectorObj = {
                selector    : init.data[i].selector,
                endD        : init.data[i].endD * donutChart.charts[id].animPercent,
                outerRadius : init.outerRadius,
                innerRadius : init.innerRadius,
                startD      : init.data[i].startD * donutChart.charts[id].animPercent,
                centrePoint : init.centrePoint,
              };

              donutChart.drawSector ( sectorObj );
            }
          }
        },
        onComplete : ( init.onComplete === undefined ) ? null : init.onComplete,
        onCompleteParams : ( init.onCompleteParams === undefined ) ? null : init.onCompleteParams,
      });
    }
    else {
      for ( i = 0; i < init.data.length; i++ ) {
        if ( init.data[i].endD - init.data[i].startD > 0 ) {

          var sectorObj = {
            selector    : init.data[i].selector,
            endD        : init.data[i].endD,
            outerRadius : init.outerRadius,
            innerRadius : init.innerRadius,
            startD      : init.data[i].startD,
            centrePoint : init.centrePoint,
          };

          donutChart.drawSector ( sectorObj );
        }
      }
    }

    svg.append ( group );
  },

  addNoDataGraphic : function ( svg ) {
    var yOffset = 125;
    var xOffset = 0;

    var circle = svg.circle(xOffset+12,yOffset+20,60).attr({
      'fill' : '#DD4444',
    });

    var line1 = svg.line(xOffset-4,yOffset+0,xOffset+30,yOffset+40).attr({
      'stroke'         : '#FFFFFF',
      'stroke-width'   : '13',
      'stroke-linecap' : 'round',
    });

    var line2 = svg.line(xOffset+30,yOffset+0,xOffset-4,yOffset+40).attr({
      'stroke'         : '#FFFFFF',
      'stroke-width'   : '13',
      'stroke-linecap' : 'round',
    });

    var text = svg.text(xOffset-72, yOffset+105,'No Data Returned').attr({
      'font-weight' : 'bold',
      'font-size'   : '20px',
      'text-align'  : 'center',
      'fill'        : '#DD4444',
    });

    svg.append ( line1 );
    svg.append ( line2 );
    svg.append ( text );
  },

  addResetBtn : function ( group, init ) {
    var resetBtnGroup = group.g().attr({
      class : 'chart-reset-btn',
    });

    group.append ( resetBtnGroup );

    var resetBtnBG = resetBtnGroup.circle().attr({
      cx : init.centrePoint.x,
      cy : init.centrePoint.y,
      r  : 30,
      fill : '#FFFFFF',
      stroke : '#DDEEFF',
    });

    resetBtnGroup.append ( resetBtnBG );

    var resetBtnSymbol = Snap.load ( '/assets/graphics/reset-arrow.svg', function ( loadedFragment ) {
      var arrow = loadedFragment.select("g#Layer0_0_FILL");
      arrow.transform ( 't' + (init.centrePoint.x - 13.5) + ',' + (init.centrePoint.y - 13.5) );
      resetBtnGroup.append ( arrow );
    });
  },

  calcDegrees : function ( init ) {
    for ( var i = 0; i < init.data.length; i++ ) {
      if ( i === 0 ) {
        init.data[i].startD       = 0;
        init.data[i].endD         = init.data[i].percent * 360;
        init.data[i].startPercent = 0;
        init.data[i].endPercent   = init.data[i].percent;
      }
      else {
        init.data[i].startD       = init.data[i-1].endD;
        init.data[i].endD         = init.data[i].startD + init.data[i].percent * 360;
        init.data[i].startPercent = init.data[i-1].endPercent;
        init.data[i].endPercent   = init.data[i].startPercent + init.data[i].percent;
      }

      init.data[i].midPercent     = ( init.data[i].startPercent + init.data[i].endPercent ) / 2;
      init.data[i].midD           = init.data[i].midPercent * 360;
    }
  },

  createSegments : function ( group, init ) {
    var legendXOff = 0,
        legendYOff = 25;
    
    if ( init.legendXOff !== undefined )
      legendXOff = init.legendXOff;
    if ( init.legendYOff !== undefined )
      legendYOff = init.legendYOff;
    
    var totalRecords = 0;
    // - CALCULATE TOTAL
    for ( var i = 0; i < init.data.length; i++ ) {
      totalRecords += init.data[i].amount;
    }
    // - CALCULATE PERCENTAGES
    var percentages = [];
    for ( i = 0; i < init.data.length; i++ ) {
      // console.log(init.data[i].amount, Math.round ( init.data[i].amount / totalRecords * 100 ));
      percentages.push ( Math.round ( init.data[i].amount / totalRecords * 100 ) );
    }
    // - CORRECT FOR 101 PERCENT
    percentages = NHMath.correctRoundedPercs ( percentages, 100 );

    for ( i = 0; i < init.data.length; i++ ) {
      var opacity = ( init.data[i].endD - init.data[i].startD === 0 ) ? 0 : 1;
      
      var segmentObj = {
        'data-index'    : i,
        'data-filter'   : init.data[i].filter,
        'class'         : 'donut-sector ' + init.data[i].sectorClass,
        'stroke-width'  : '1',
        'stroke'        : init.data[i].strokeColour,
        'fill'          : init.data[i].colour,
        'start-d'       : init.data[i].startD,
        'end-d'         : init.data[i].endD + 0.000000001,
        'data-order'    : i,
        'd'             : '',
        'data-selected' : '0',
        'opacity'       : opacity,
      };
      
      var segment = group.path().attr ( segmentObj );

      group.append ( segment );

      var outerRadiusLabelFactor = 0.77;
      var labelColour = '#FFFFFF';
      var rotateTextD = init.data[i].midD;

      var segmentLabel;

      if ( init.labelMode === 'central-tangent' ) {
        // if ( init.data[i].percent < 0.1 ) {
          outerRadiusLabelFactor = 1.13;
          labelColour = init.data[i].colour;
        // }

        if ( init.data[i].midD > 90 && init.data[i].midD < 270 ) {
          rotateTextD = init.data[i].midD + 180;
          outerRadiusLabelFactor += 0.09;
        }

        var xCo = ( outerRadiusLabelFactor * init.outerRadius *  Math.sin(init.data[i].midD*(Math.PI/180)) ) + init.centrePoint.x;
        var yCo = ( outerRadiusLabelFactor * init.outerRadius * -Math.cos(init.data[i].midD*(Math.PI/180)) ) + init.centrePoint.y;

        segmentLabel = group.text(xCo, yCo, init.data[i].label).attr({
          'class'       : 'sector-label',
          'font-size'   : 16,
          'text-anchor' : 'middle',
          'fill'        : labelColour,
          'order'       : 10,
          'transform'   : 'rotate(' + rotateTextD + ',' + xCo + ',' + yCo + ')',
        });
      }
      else if ( init.labelMode === 'legend' ) {
        labelColour = init.data[i].labelColour;
        dotColour   = init.data[i].colour;

        var legendPercentageDot = group.rect(-100 + legendXOff - 16, i * 36 + legendYOff - 16, 72, 32, 16).attr({
          'fill'        : donutChart.colours.lighten(dotColour, '10%'),
          'stroke'      : init.data[i].strokeColour,
          'order'       : 9,
        });

        var legendDot = group.circle().attr({
          'r'           : 17,
          'class'       : 'legend-dot sector-' + i,
          'cx'          : -100 + legendXOff,
          'cy'          : i * 36 + legendYOff,
          'fill'        : dotColour,
          'stroke'      : init.data[i].strokeColour,
          'order'       : 10,
        });

        segmentLabel = group.text(-70 + legendXOff + 40, i * 36 + 7 + legendYOff, init.data[i].label).attr({
          'class'       : 'sector-label sector-label-' + i,
          'font-size'   : 16,
          'text-anchor' : 'left',
          'fill'        : labelColour,
          'order'       : 10,
        });

        var dotAmountColour = '#000000';

        if ( dotColour === '#009933' || dotColour === '#FF9900' || dotColour === '#EE0000' ) {
          dotAmountColour = '#FFFFFF';
        }

        var dotAmount = group.text(-100 + legendXOff, i * 36 + 5 + legendYOff, String ( init.data[i].amount )).attr({
          'class'       : 'sector-label',
          'font-size'   : 13,
          'font-weight' : 900,
          'text-anchor' : 'middle',
          'fill'        : dotAmountColour,
          'order'       : 10,
        });

        var dotPercent = group.text(-86 + legendXOff + 32, i * 36 + 5 + legendYOff, String ( percentages[i] ) + '%').attr({
          'class'       : 'sector-label',
          'font-size'   : 13,
          'text-anchor' : 'end',
          'fill'        : dotAmountColour,
          'order'       : 10,
        });

        group.append ( legendDot );
        group.append ( dotAmount );
      }


      group.append ( segmentLabel );

      // segmentLabel.style.opacity = 0;
    }

    // var ring = group.path().attr({
    //   x     : 130,
    //   y     : 100,
    //   d      : 'M 100 100 A 50 50 0 1 0 0.00001 0',
    //   stroke : '#FF0000',
    //   // fill   : '#FF0000',
    //   style  : 'fill: #000000; fill-opacity: 0; stroke-width: 5',
    // });
    //
    // group.append ( ring );
  },

  drawSector : function ( init ) {
    // init => selector, endD, outerRadius, innerRadius, startD, centrePoint
    
    init.startD += 0.01;
    var largeArcFlag = (init.endD - init.startD > 180) ? 1 : 0,
        sweepFlagOuter = 1,
        sweepFlagInner = 0,
        dString,
        startPoint = 'M ' + ( ( init.outerRadius *  Math.sin(init.startD*(Math.PI/180)) ) + init.centrePoint.x ) + ' ' +
                            ( ( init.outerRadius * -Math.cos(init.startD*(Math.PI/180)) ) + init.centrePoint.y ) + ' ';

        outerArc = 'A ' + init.outerRadius + ' ' + init.outerRadius + ' ' +
                   '0 ' +
                   largeArcFlag + ' ' +
                   sweepFlagOuter + ' ' +
                   ( ( init.outerRadius *  Math.sin(init.endD*(Math.PI/180)) ) + init.centrePoint.x ) + ' ' +
                   ( ( init.outerRadius * -Math.cos(init.endD*(Math.PI/180)) ) + init.centrePoint.y ) + ' ',

        cutIn = 'L ' +
                ( ( init.innerRadius *  Math.sin(init.endD*(Math.PI/180)) ) + init.centrePoint.x ) + ' ' +
                ( ( init.innerRadius * -Math.cos(init.endD*(Math.PI/180)) ) + init.centrePoint.y ) + ' ',

        innerArc = 'A ' + init.innerRadius + ' ' + init.innerRadius + ' ' +
                   '0 ' +
                   largeArcFlag + ' ' +
                   sweepFlagInner + ' ' +
                   ( ( init.innerRadius *  Math.sin(init.startD*(Math.PI/180)) ) + init.centrePoint.x ) + ' ' +
                   ( ( init.innerRadius * -Math.cos(init.startD*(Math.PI/180)) ) + init.centrePoint.y ) + ' z';

    dString = startPoint + outerArc + cutIn + innerArc;
    // console.log(selector,dString);
    $(init.selector).attr ( 'd', dString );
    // $(selector).attr ( 'data-selected', 0 );
  },

  initToggleFilter : function ( chartID, toggleHandler ) {

    $('#' + chartID + ' .donut-sector')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {

        var index = $(this).attr('data-index');
        var elem = $(this);
        // console.log('index', index);

        toggleHandler ( chartID, index, elem, function() {
          // console.log('replace with on change');
        });
      });
  },

  initResetBtn : function ( chartID ) {
    $('#' + chartID + ' .chart-reset-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        Reports.pieChart.reset ( chartID );
      });
  },

  colours : {
    componentToHex : function ( c ) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    
    RGBToHex : function ( r, g, b ) {
      var hex = donutChart.colours.componentToHex(r) + donutChart.colours.componentToHex(g) + donutChart.colours.componentToHex(b);

      if ( hex[0] === hex[1] && hex[2] === hex[3] && hex[4] === hex[5] ) {
        hex = hex[0] + hex[2] + hex[4];
      }

      return "#" + hex.toUpperCase();
    },

    hexToRGB : function ( hex ) {
      var result;
      if ( hex.length === 7 ) {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      }
      else {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
        result[1] = result[1] + result[1];
        result[2] = result[2] + result[2];
        result[3] = result[3] + result[3];
      }
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },

    lighten : function ( colour, amount ) {
      if ( typeof colour === 'string' ) {
        // - IT'S HEX - CONVERT TO COLOUR ARRAY
        colour = donutChart.colours.hexToRGB ( colour );
      }

      if ( amount.indexOf ( '%' ) !== -1 ) {
        colour.r = Math.round ( colour.r * ((100 - parseInt(amount)) * 0.01) );
        colour.g = Math.round ( colour.g * ((100 - parseInt(amount)) * 0.01) );
        colour.b = Math.round ( colour.b * ((100 - parseInt(amount)) * 0.01) );
      }

      return donutChart.colours.RGBToHex ( colour.r, colour.g, colour.b );
    },
  },
};
