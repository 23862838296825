function DateNH ( _dt ) {
  if ( _dt === undefined ) {
    this.value = new Date();
  }
  else {
    this.value = new Date ( _dt );
  }
}

DateNH.prototype.modify = function ( modifierStr ) {
  // - BUILD A REGEX CHECK HERE LATER

// console.log('|'+modifierStr+'|');
  // - GET THE DIRECTION MODIFIER
  var direction = modifierStr.substr(0, 1);

  // - GET THE NUMBER MODIFIER;
  var number = Number ( modifierStr.substr ( 1, modifierStr.indexOf(' ') - 1 ) );

  // - GET THE UNIT MODIFIER
  var unitRaw = modifierStr.substr ( modifierStr.indexOf ( ' ' ) + 1 );

  // - DICTIONARY OF MODIFIERS
  var unitModifier = {
    milliseconds : 'millisecond',
    millisecond  : 'millisecond',
    seconds      : 'second',
    second       : 'second',
    minutes      : 'minute',
    minute       : 'minute',
    hours        : 'hour',
    hour         : 'hour',
    days         : 'day',
    day          : 'day',
    weeks        : 'week',
    week         : 'week',
    months       : 'month',
    month        : 'month',
    years        : 'year',
    year         : 'year',
  };

  var unit = unitModifier[unitRaw];

  var workingDate = new Date ( this.value );
  var sinceEpoch  = workingDate.getTime();

  var dirNum      = direction === '+' ? number : -number;
  var modifiedDateEpoch;

  switch ( unit ) {
    case 'millisecond':
      modifiedDateEpoch = sinceEpoch + dirNum;
      break;
    case 'second':
      modifiedDateEpoch = sinceEpoch + (dirNum * 1000);
      break;
    case 'minute':
      modifiedDateEpoch = sinceEpoch + (dirNum * 60 * 1000);
      break;
    case 'hour':
      modifiedDateEpoch = sinceEpoch + (dirNum * 60 * 60 * 1000);
      break;
    case 'day':
      modifiedDateEpoch = sinceEpoch + (dirNum * 24 * 60 * 60 * 1000);
      break;
    case 'week':
      modifiedDateEpoch = sinceEpoch + (dirNum * 7 * 24 * 60 * 60 * 1000);
      break;
    case 'month':
      modifiedDateEpoch = this.modifyMonths ( dirNum );
      break;
    case 'year':
      modifiedDateEpoch = this.modifyYears ( dirNum );
      break;
  }

  this.value = new Date ( modifiedDateEpoch );

  return this.value;
};

DateNH.prototype.modifyMonths = function ( dirNum ) {
  /**
   * CALCULATE THE MODIFIER WE NEED BASED ON CURRENT MONTH AND NUMBER TO CHANGE BY
   * IF IN JULY AND WANT TO ADD 15 MONTHS...
   * JULY = 6 SO WE WOULD SET MONTH AS 6 + 15 OR 21
   * IF IN JULY AND WANT TO SEND BACK 15 MONTHS, SET MONTH AS 6 - 15 OR -9
   */
  this.value.setMonth ( this.value.getMonth() + dirNum );

  return this.value;
};

DateNH.prototype.modifyYears = function ( dirNum ) {
  /**
   * CALCULATE THE MODIFIER WE NEED BASED ON CURRENT YEAR AND NUMBER TO CHANGE BY
   * IF IN JULY AND WANT TO ADD 15 MONTHS...
   * JULY = 6 SO WE WOULD SET YEAR AS 6 + 15 OR 21
   * IF IN JULY AND WANT TO SEND BACK 15 MONTHS, SET YEAR AS 6 - 15 OR -9
   */
  this.value.setFullYear ( this.value.getFullYear() + dirNum );

  return this.value;
};

DateNH.prototype.addLeadingZero = function ( input ) {
  return input < 10 ? String ( '0' + input ) : String ( input );
};

DateNH.prototype.format = function ( formatStr ) {
  var output = formatStr.split(''),
      outputLength = output.length;

  for ( var i = 0; i < outputLength; i++ ) {
    switch ( output[i] ) {
      // - d : date with leading zero
      case 'd':
        output[i] = this.addLeadingZero ( this.value.getDate() );
        break;
      // - D : 3 letter representation of Day of the week
      case 'D':
        var Ds = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        output[i] = Ds[this.value.getDay()];
        break;
      // - j : date without leading zero
      case 'j':
        output[i] = this.value.getDate();
        break;
      // - l : 3 letter representation of Day of the week
      case 'l':
        var ls = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        output[i] = ls[this.value.getDay()];
        break;
      // - S : English ordinal suffix for the day of the month, 2 characters
      case 'S':
        var Ss = ['th', 'st', 'nd', 'rd'];
        var lastDigit;
        var date = this.value.getDate();

        if ( date < 11 || date > 13 ) {
          lastDigit = date % 10;
        }
        else {
          lastDigit = 0;
        }

        output[i] = Ss[lastDigit];
        break;
      // - m : month with leading zero
      case 'm':
        output[i] = this.addLeadingZero ( this.value.getMonth() + 1 );
        break;
        // - M : Short textual representation of the month
      case 'M':
        var Ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        output[i] = Ms[this.value.getMonth()];
        break;
      // - F : Full textual representation of the month
      case 'F':
        var Fs = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        output[i] = Fs[this.value.getMonth()];
        break;
      // - Y : Full Year
      case 'Y':
        output[i] = this.value.getFullYear();
        break;
      // - y : Two-digi Year
      case 'y':
        output[i] = String ( this.value.getFullYear() ).substr( -2 );
        break;
      // - g : hours (12h) without leading zero
      case 'g':
        output[i] = this.value.getHours() % 12;
        break;
      // - G : hours (24h) without leading zero
      case 'G':
        output[i] = this.value.getHours();
        break;
      // - h : hours (12h) with leading zero
      case 'h':
        output[i] = this.addLeadingZero ( this.value.getHours() % 12 );
        break;
      // - H : hours (24h) with leading zero
      case 'H':
        output[i] = this.addLeadingZero ( this.value.getHours() );
        break;
      // - i : minutes with leading zero
      case 'i':
        output[i] = this.addLeadingZero ( this.value.getMinutes() );
        break;
      // - s : seconds with leading zero
      case 's':
        output[i] = this.addLeadingZero ( this.value.getSeconds() );
        break;
      // - a : lowercase am or pm
      case 'a':
        output[i] = this.value.getHours() < 12 ? 'am' : 'pm';
        break;
      // - A : uppercase AM or PM
      case 'A':
        output[i] = this.value.getHours() < 12 ? 'AM' : 'PM';
        break;
      // - w : numerical representation of the day of the week
      case 'w':
        output[i] = this.value.getDay();
        break;
      default:
        // - DO NOTHING
    }
  }

  return output.join('');
};
