var file_uploader = {};

file_uploader.mode = 'series';
//file_uploader.mode = 'parallel';

file_uploader.uploadAsTempFiles = false;
file_uploader.tempExpiry = null;

file_uploader.allowed_ext_types = [
	'jpg',
	'jpeg',
	'png',
	'gif',
	'doc',
	'docx',
	'xls',
	'xlsx',
	'ppt',
	'pptx',
	'pps',
	'ppsx',
	'pdf',
	'htm',
	'html',
	'txt',
];

file_uploader.tmpRoot = '../../tmp/';
file_uploader.root = '';
file_uploader.upload_path = '';


file_uploader.upload_sequence = [];

// Variable to store your files
file_uploader.files = [];

file_uploader.allUploaded = function() {
	var all = true;
	// console.log(file_uploader.upload_sequence);
	for (var i = 0; i < file_uploader.upload_sequence.length; i++) {
		// console.log(i, file_uploader.upload_sequence[i].uploaded);
		if ( !file_uploader.upload_sequence[i].uploaded
		   || file_uploader.upload_sequence[i].uploaded === undefined ) {
			all = false;
			break;
		}
	}
	// console.log('all', all);
	return all;
};

file_uploader.allAddedToDB = function() {
	var all = true;
	// console.log(file_uploader.upload_sequence);
	for (var i = 0; i < file_uploader.upload_sequence.length; i++) {
		// console.log(i, file_uploader.upload_sequence[i].addedToDB);
		if ( !file_uploader.upload_sequence[i].addedToDB
		   || file_uploader.upload_sequence[i].addedToDB === undefined ) {
			all = false;
			break;
		}
	}
	// console.log('allAddedToDB', all);
	return all;
};

file_uploader.on_finish_upload = function() {

};

file_uploader.on_finish_all_uploads = function() {

};

file_uploader.on_finish_all_added_to_db = function() {

};


file_uploader.hostSelector = null;

file_uploader.on_files_selected = function(evt) {
	var f, file_li;
  var msg  = 'file-upload.class.js:85\n';
      msg += JSON.stringify(file_uploader) + '\n';
      msg += file_uploader.hostSelector + '\n';
      msg += file_uploader.hostSelector + ' #files_to_upload' + '\n';
      msg += $(file_uploader.hostSelector + ' #files_to_upload').css('display') + '\n';
      msg += $(file_uploader.hostSelector + ' .upload_btn').css('display') + '\n';
  JL().info(msg);
	$(file_uploader.hostSelector + ' #files_to_upload').empty();
	$(file_uploader.hostSelector + ' #files_to_upload').show();
	$(file_uploader.hostSelector + ' .upload_btn').show();

	$(file_uploader.hostSelector + ' .upload_btn')
	  .off ( 'click' )
		.on  ( 'click', function ( evt ) {
			$(file_uploader.hostSelector + ' .upload_btn').hide();
		});

	for(f = 0; f < $(this).prop('files').length; f++) {//console.log($(this).prop('files')[f]);
		file_li  = 	'<li data-index="' + f + '" class="uploading_file file">';
		file_li += 		'<div class="uploading_file_name file_name">' + $(this).prop('files')[f].name + '</div>';
		file_li +=		'<div class="upload_remove_btn delete_btn gen-btn negative-btn">Remove</div>';
		file_li += 		'<div class="uploading_progress">';
		file_li +=			'<div class="uploading_progress_bar"></div>';
		file_li +=		'</div>';
		file_li +=		'<div class="clear"></div>';
		file_li += '</li>';

		$(file_uploader.hostSelector + ' #files_to_upload').append(file_li);
	}

	setTimeout(function() {
		$(file_uploader.hostSelector + ' #files_to_upload li').css('height', '70px');
		$(file_uploader.hostSelector + ' #files_to_upload li').css('padding', '18px');
	}, 300);

	$(file_uploader.hostSelector + ' #files_to_upload li .upload_remove_btn').bind('click', function(evt) {
		var li = $(this).parent();
		file_uploader.remove_uploading_file(li);
	});
};

file_uploader.hide_files_to_upload_if_empty = function() {
	if($(file_uploader.hostSelector + ' #files_to_upload li').length === 0)
		$(file_uploader.hostSelector + ' #files_to_upload').hide();
};

// Grab the files and set them to our variable
file_uploader.prepare_upload = function(event) {
	file_uploader.files = event.target.files;
};

file_uploader.sanitiseFileName = function ( name ) {
	var swapObj = {
		'&' : 'and',
		'[' : '-',
		'(' : '-',
		')' : '-',
	};

	for ( var key in swapObj ) {
		name = name.split ( key )
		    .join  ( swapObj[key] );
	}

	return name;
};

// Catch the form submit and upload the files
file_uploader.upload_files = function(event) {
	event.stopPropagation(); // Stop stuff happening
	event.preventDefault(); // Totally stop stuff happening

	// START A LOADING SPINNER HERE

	// Create a formdata object and add the files
	var data;

	file_uploader.upload_sequence = [];

	$.each(file_uploader.files, function(key, value) 	{
		value.name = file_uploader.sanitiseFileName ( value.name );
    data = new FormData();
		data.append(key, value);
		data.append('upload_path', file_uploader.upload_path);
		data.append('repos_path', file_uploader.repos_path);
		data.append('repos', file_uploader.repos);
		data.append('file_name', value.name);
		data.append('ext', value.name.substr(value.name.lastIndexOf('.') + 1) );
		data.append('user_id', file_uploader.user_id);
		data.append('site_id', file_uploader.site_id);
		data.append('inst_id', file_uploader.inst_id);

		// console.log ( 'file_uploader.user_id', file_uploader.user_id );
		// console.log ( 'file_uploader.site_id', file_uploader.site_id );
		// console.log ( 'file_uploader.inst_id', file_uploader.inst_id );

		file_uploader.add_to_upload_sequence(data, key, value.name);
	});

	if(file_uploader.mode === 'series')
		file_uploader.start_upload_series();
	else
		file_uploader.start_upload_parallel();

	$('#file_upload').val('');
};

file_uploader.ajax_upload_parallel = function(data, index, file_name) {
	var percentage, ext = file_uploader.curr_file.file_name.substr(file_uploader.curr_file.file_name.lastIndexOf('.') + 1);

	//$('#files_to_upload li[data-index="' + index + '"] .upload_remove_btn').hide();
	if(file_uploader.allowed_ext_types.indexOf(ext) === -1) {
		file_uploader.mark_not_uploaded(index, 'File Type Not Allowed');
		file_uploader.upload_next_file();
	}
	else {
		file_uploader.upload_sequence[index].ajax = $.ajax({
			url: '/repository/AJAX-repository/ajax-file-upload',
			type: 'POST',
			data: data,
			xhr: function() {  // Custom XMLHttpRequest
				var myXhr = $.ajaxSettings.xhr();
				if(myXhr.upload)
				{ // Check if upload property exists
					myXhr.upload.addEventListener('progress', function on_progress(e)
					{
						if(e.lengthComputable)
						{
							percentage = (100 * e.loaded / e.total);

							$(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + index + '"] .uploading_progress_bar').css('width', percentage + '%');

							if(percentage === 100)
								file_uploader.hide_uploading_file( $(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + index + '"]') );
						}
					}, false); // For handling the progress of the upload
				}
				return myXhr;
			},
			cache: false,
			dataType: 'json',
			processData: false, // Don't process the files
			contentType: false, // Set content type to false as jQuery will tell the server its a query string request
			success: function(data, textStatus, jqXHR) {

				if(typeof data.error === 'undefined') {
					// Success so call function to process the form
					file_uploader.process_form(event, data);

					file_uploader.add_file_to_db();

					file_uploader.on_finish_upload();

					if ( file_uploader.curr_file_index === file_uploader.upload_sequence.length - 1 ) {
						file_uploader.on_finish_all_uploads();
					}
				}
				else {
					// Handle errors here
					// console.log('ERRORS: ' + data.error);
				}
			},
			error: function(jqXHR, textStatus, errorThrown) {
        // Handle errors here
				// console.log('ERRORS: ' + textStatus);
				// STOP LOADING SPINNER
			}
		});
	}
};

file_uploader.ajax_upload_series = function(data, index, file_name) {
	var _data = data,
  ext = file_uploader.curr_file.file_name.substr(file_uploader.curr_file.file_name.lastIndexOf('.') + 1),
  percentage;
	// console.log(181, data);
	// console.log(file_uploader.allowed_ext_types, ext);
	if(file_uploader.allowed_ext_types.indexOf(ext) === -1) {
		file_uploader.mark_not_uploaded(index, 'File Type Not Allowed');
		file_uploader.upload_next_file();
	}
	else {
		file_uploader.upload_sequence[index].ajax = $.ajax({
			url: '/repository/AJAX-repository/ajax-file-upload',
			type: 'POST',
			data: data,
			xhr: function() {  // Custom XMLHttpRequest
				var myXhr = $.ajaxSettings.xhr();
				if(myXhr.upload) { // Check if upload property exists
					myXhr.upload.addEventListener('progress', function on_progress(e) {
						if(e.lengthComputable) {
							percentage = (100 * e.loaded / e.total);

							$(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + index + '"] .uploading_progress_bar').css('width', percentage + '%');

							if(percentage === 100) {
                file_uploader.hide_uploading_file( $(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + index + '"]') );
								file_uploader.upload_next_file();
              }
						}
					}, false); // For handling the progress of the upload
				}
				return myXhr;
			},
			cache: false,
			dataType: 'text',
			processData: false, // Don't process the files
			contentType: false, // Set content type to false as jQuery will tell the server its a query string request
			success: function(data, textStatus, jqXHR) {
        // console.log(data, textStatus);
				if(typeof data.error === 'undefined') {
					file_uploader.upload_sequence[index].uploaded = true;
					// console.log('success', index, file_uploader.upload_sequence, file_uploader.upload_sequence[index]);
					// console.log('series success');
					// Success so call function to process the form
					file_uploader.process_form(event, data);

					file_uploader.add_file_to_db(index);

					file_uploader.on_finish_upload();

					if ( file_uploader.allUploaded() ) {
						file_uploader.on_finish_all_uploads();
					}
				}
				else {
					// Handle errors here
					// console.log('ERRORS: ' + data.error);
				}
			},
			error: function(jqXHR, textStatus, errorThrown) {
        // console.log(225);
				// Handle errors here
				// console.log('ERRORS: ' + textStatus);
				// console.log('errorThrown: ', errorThrown);
				// STOP LOADING SPINNER
			}
		});
	}
};

file_uploader.add_file_to_db = function(_index) {
	var sendData = file_uploader.upload_sequence[_index].data;
	sendData.append ( 'uploadAsTempFiles', file_uploader.uploadAsTempFiles );
	sendData.append ( 'tempExpiry', file_uploader.tempExpiry );

	// console.log('add_file_to_db:file', file_uploader.upload_sequence[_index]);
	// console.log('add_file_to_db:sendData', sendData);

	$.ajax({
		url         : '/repository/AJAX-repository/ajax-add-file-to-db',
		type        : 'post',
		data        : sendData,
		processData : false,
		contentType : false,
		dataType    : 'text',
		success     : function(returnedData) {
			// console.log('add_file_to_db:returned', returnedData);
			returnedData = JSON.parse ( returnedData );
      // console.log('add_file_to_db:returnedJSON', returnedData);
			file_uploader.upload_sequence[_index].addedToDB = true;
			file_uploader.upload_sequence[_index].docID = returnedData.id;

			if ( file_uploader.allAddedToDB() ) {
				var files = [];

				for (var i = 0; i < file_uploader.upload_sequence.length; i++) {
					files.push ({
						id: file_uploader.upload_sequence[i].docID,
						file_name : file_uploader.upload_sequence[i].file_name,
					});
				}

				var obj = {
					files : files,
				};

				file_uploader.on_finish_all_added_to_db ( obj );
			}
		}
	});
};

file_uploader.add_to_upload_sequence = function(_data, _index, _file_name) {
	file_uploader.upload_sequence.push({data:_data, index:_index, file_name:_file_name});
};

file_uploader.start_upload_series = function() {
	file_uploader.curr_file_index = 0;
	file_uploader.curr_file = file_uploader.upload_sequence[file_uploader.curr_file_index];

	if(file_uploader.curr_file.skip === true) {
    file_uploader.upload_next_file();
  }
	else {
    file_uploader.ajax_upload_series(file_uploader.curr_file.data, file_uploader.curr_file.index, file_uploader.curr_file.file_name);
  }
};

file_uploader.upload_next_file = function() {
	if(file_uploader.curr_file_index < file_uploader.upload_sequence.length - 1) {
    file_uploader.curr_file_index++;
		file_uploader.curr_file = file_uploader.upload_sequence[file_uploader.curr_file_index];

		if(file_uploader.curr_file.skip === true) {
			file_uploader.upload_next_file();
    }
		else {
			file_uploader.ajax_upload_series(file_uploader.curr_file.data, file_uploader.curr_file.index, file_uploader.curr_file.file_name);
    }
	}
};

file_uploader.start_upload_parallel = function() {
	var i;

	for(i = 0; i < file_uploader.upload_sequence.length; i++) {
		file_uploader.curr_file = file_uploader.upload_sequence[i];
		file_uploader.ajax_upload_parallel(file_uploader.curr_file.data, file_uploader.curr_file.index, file_uploader.curr_file.file_name);
	}
};

file_uploader.process_form = function(event, data) {
	// Create a jQuery object from the form
	$form = $(event.target);

	// Serialize the form data
	var formData = $form.serialize();

	// You should sterilise the file names
	$.each(data.files, function(key, value) {
		formData = formData + '&filenames[]=' + value;
	});

	$.ajax({
		url: '/repository/AJAX-repository/ajax-file-upload-process-form',
		type: 'POST',
		data: formData,
		cache: false,
		dataType: 'json',
		success: function(data, textStatus, jqXHR) {
      // console.log(301, data, textStatus);
			if(typeof data.error === 'undefined') {
				// Success so call function to process the form
				// console.log('SUCCESS: ' + data.success);
			}
			else {
				// Handle errors here
				// console.log('ERRORS: ' + data.error);
			}
		},
		error: function(jqXHR, textStatus, errorThrown) {
      // console.log(313);
			// Handle errors here
			// console.log('ERRORS: ' + textStatus);
		},
		complete: function() {
			// STOP LOADING SPINNER
		}
	});
};

file_uploader.remove_uploading_file = function(li) {
	var index = li.attr('data-index');

	li.css('height', '0');
	li.css('padding', '0 20px');

	setTimeout(function() {
		li.remove();

		if(file_uploader.upload_sequence[index] !== undefined) {
			if(file_uploader.upload_sequence[index].ajax !== undefined) {
				file_uploader.upload_sequence[index].ajax.abort();
      }
			file_uploader.upload_sequence[index].skip = true;
		}

		file_uploader.hide_files_to_upload_if_empty();
	}, 500);
};

file_uploader.hide_uploading_file = function(li) {
	var index = li.attr('data-index');

	li.css('height', '0');
	li.css('padding', '0 20px');

	setTimeout(function() {
		li.remove();
		file_uploader.hide_files_to_upload_if_empty();
	}, 500);
};

file_uploader.mark_not_uploaded = function(_index, _message) {
	$(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + _index + '"] .file_name').html(file_uploader.upload_sequence[_index].file_name + ' not uploaded: ' + _message);
	$(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + _index + '"] .file_name').addClass('file_error');

	setTimeout(function() {
		file_uploader.hide_uploading_file( $(file_uploader.hostSelector + ' #files_to_upload li[data-index="' + _index + '"]') );
	}, 3500);
};

// NOW IN THE POPUP
// $(document).ready(function(e) {
// 	// Add events
// 	$('input[type=file]').on ( 'change', file_uploader.prepare_upload );
// 	$('form').on ( 'submit', file_uploader.upload_files );
// 	$('#file_upload').on ( 'change', file_uploader.on_files_selected );
// });
