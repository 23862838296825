var dropdown = {
  makeImpossibleToSelectDuplicates : function ( selectInputs, availableOptions, varName, unselectedText ) {
    var i, id, j, removeIDs = [];
    // console.log(selectInputs, availableOptions);

    for ( i = 0; i < selectInputs.length; i++ ) {
      $(selectInputs[i]).attr ( 'data-' + varName, $(selectInputs[i]).val() );
      // - RESET THE SELECT
      $(selectInputs[i]).empty();
      for ( id in availableOptions ) {
        $(selectInputs[i]).append(
          $('<option></option>')
            .attr ( 'value', id )
            .text ( availableOptions[id] )
        );
      }
    }

    for ( i = 0; i < selectInputs.length; i++ ) {
      // - ADD THE CURRENT SELECTIONS TO AN ARRAY
      removeIDs.push ( $(selectInputs[i]).attr ( 'data-' + varName ) );
    }

    for ( i = 0; i < selectInputs.length; i++ ) {
      // - GO THROUGH THE DROPDOWNS AND REMOVE OPTIONS THAT ARE
      // - ALREADY SELECTED UNLESS SELECTED BY THAT ONE

      //console.log($(selectInputs[i]).attr('name'), $(selectInputs[i]).attr('data-site'));
      for ( j = 0; j < removeIDs.length; j++ ) {
        //console.log($(selectInputs[i]).attr('name'), $(selectInputs[i]).val(), removeIDs[j]);
        if ( Number ( $(selectInputs[i]).attr ( 'data-' + varName ) ) !== Number ( removeIDs[j] ) ) {
          // console.log('removed:', removeIDs[j], 'from:', $(selectInputs[i]).attr('data-site'));
          // console.log('using selector:', 'option[value="' + removeIDs[j] + '"]');
          $(selectInputs[i]).find('option[value="' + removeIDs[j] + '"]').remove();
        }
      }
    }

    // - SORT THE OPTIONS
    for ( i = 0; i < selectInputs.length; i++ ) {
      $(selectInputs[i]).sortOptions();

      if ( unselectedText !== null ) {
        $(selectInputs[i]).prepend (
          $('<option></option>')
            .attr ( 'value', '-' )
            .text ( unselectedText )
        );
      }
      $(selectInputs[i]).select_by_value ( $(selectInputs[i]).attr ( 'data-' + varName ) );
    }
  },
};
