;(function($) {

  $.fn.inlineUpload = function ( _options ) {
    // - OPTIONS
    var options = ( _options === undefined ) ? {} : _options;

    // var settings = $.extend({
    //   onInitComplete         : options.onInitComplete,
    //   onInitCompleteArgs     : options.onInitCompleteArgs,
    //   onFinishUpload         : options.onFinishUpload,
    //   onFinishUploadArgs     : options.onFinishUploadArgs,
    //   onFinishAllUploads     : options.onFinishAllUploads,
    //   onFinishAllUploadsArgs : options.onFinishAllUploadsArgs,
    //   onFinishAllAddedToDB   : options.onFinishAllAddedToDB,
    // }, options);

    var container = $(this);

    var sendData = {
      hostSelector : options.hostSelector,
      temp         : options.temp,
      expiry       : options.expiry,
      repos        : options.repos,
      name         : options.name,
      subpath      : options.subpath,
      photo        : options.photo,
    };

    if ( typeof ( options.subpath ) === 'string'
      && options.subpath.indexOf ( 'TEMP_RANDOM_STRING' ) !== -1 ) {
      var length       = options.subpath.split('::')[1];
      this.tempFolder  = str.randomString ( length, 'a#' );
      sendData.subpath = options.subpath = 'tmp/' + this.tempFolder;
    }

    if ( options.forceSingleSelect ) {
      sendData.forceSingleSelect = true;
    }

    if ( options.siteID ) {
      sendData.siteID = options.siteID;
    }
    else {
      sendData.siteID = null;
    }

    $.post ( '/AJAX-inline-upload/get-markup', sendData, function ( html ) {
      container.html ( html );

      // - ON INIT COMPLETE
      container.refreshInlineUploadInputHeight();

      if ( options.onInitComplete ) {
        if ( options.onInitCompleteArgs ) {
          options.onInitComplete ( options.onInitCompleteArgs );
        }
        else {
          options.onInitComplete();
        }
      }

      // - SET ON FINISH UPLOAD
      if ( options.onFinishUpload ) {

        if ( options.onFinishUploadArgs ) {
          file_uploader.on_finish_upload = function() {
            options.onFinishUpload ( options.onFinishUploadArgs );
          };
        }
        else {
          file_uploader.on_finish_upload = options.onFinishUpload;
        }
      }

      // - SET ON FINISH UPLOAD
      if ( options.onFinishAllUploads ) {
        if ( options.onFinishAllUploadsArgs ) {
          file_uploader.on_finish_all_uploads = function() {
            options.onFinishAllUploads ( options.onFinishAllUploadsArgs );
          };
        }
        else {
          file_uploader.on_finish_all_uploads = options.onFinishAllUploads;
        }
      }

      // - SET ON FINISHED ADDING FILE DATA TO DB
      if ( options.onFinishAllAddedToDB ) {
        file_uploader.on_finish_all_added_to_db = function ( data ) {
          options.onFinishAllAddedToDB ( data );
        };
      }

      if ( options.onFileSelectionChange ) {
        container.find('input[type=file]')
      	  .on ( 'change', function ( evt ) {
            file_uploader.repos             = options.repos;
            file_uploader.repos_path        = options.repos + '/';
            if ( options.subpath !== undefined ) {
              file_uploader.repos_path     += options.subpath + '/';
            }
            file_uploader.upload_path       = file_uploader.root + file_uploader.repos_path;
            file_uploader.allowed_ext_types = options.fileTypes;

            if ( options.temp ) {
              file_uploader.uploadAsTempFiles = 'true';
              // file_uploader.tempExpiry = options.temp_expiry_ts;
            }

            options.onFileSelectionChange();
            file_uploader.prepare_upload(evt);
          });
      }

      // - THIS UPDATES OBJECT OF INLINE UPLOADS THAT ARE LOADED AND CALLS inlineUploadLoaded EACH TIME ONE IS DONE, WE USE THIS AMONG OTHER THINGS TO MAKE SURE ALL ARE LOADED BEFORE WE RUN A TOURGUIDE.
      if ( window.inlineUploadsLoadedObj === undefined ) {
        window.inlineUploadsLoadedObj = {
          allLoaded : false,
          inlineUploadIDs : {},
          total : 0,
          onLoaded : function() {
            if ( window.inlineUploadsLoadedObj.total === $('.inline-upload').length ) {
              // console.log('all loaded');
              window.inlineUploadsLoadedObj.allLoaded = true;
            }
            else {
              // console.log('another loaded');
            }
          },
        };
      }

      window.inlineUploadsLoadedObj.inlineUploadIDs[options.hostSelector] = $(options.hostSelector);
      window.inlineUploadsLoadedObj.total++;
      window.inlineUploadsLoadedObj.onLoaded();

    }, 'html');

    return this;
  };

  $.fn.refreshInlineUploadInputHeight = function() {

    var container = $(this);

    var labelHeight = container.find('input[name="file_upload"]').closest('label').outerHeight();

    container.find('input[name="file_upload"]').height (
      '100%'
    );
  };

})(jQuery);
