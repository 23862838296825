// - dataTable - declared in assets/js/init.js

dataTable.ready = function ( evt ) {
  // - dataTable.toInitOnReady declared in assets/js/init.js
  if ( dataTable.toInitOnReady.length > 0 ) {
    for ( var i = 0; i < dataTable.toInitOnReady.length; i++ ) {
      dataTable.tables[dataTable.toInitOnReady[i].id] = {};
      dataTable.tables[dataTable.toInitOnReady[i].id].tbl = $( '#' + dataTable.toInitOnReady[i].id ).DataTable();
    }
  }

  $('.auto-dt').each ( function ( index, elem ) {
    //console.log($(this).attr('id'));
    var paging = ( $(this).hasClass('no-paging') ) ? false : true;
    dataTable.tables[$(this).attr('id')] = {
      tbl : $(this).DataTable({
        paging: paging,
        language : {
          search : 'Filter Records',
        },
      }),
    };
  });
};

$(document).ready ( function ( evt ) {
  dataTable.ready ( evt );
});

dataTable.initTable = function ( key ) {
  var cols = [];

  if ( dataTable.tables[key] );

  dataTable.tables[key].tbl = $('#' + dataTable.tables[key].id).DataTable( {
    processing : true,
    // ajax : {
    //     url: tableLazy.tables[key].ajax.url
    // },
    //drawCallback : tableLazy.tables[key].ajax.drawCallback,
    responsive: true,
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
    columns: [
      { "orderable": false },
      null,
      null,
      null,
      { "orderable": false },
    ],
    columnDefs: [
      {
        orderable          : false,
        className          : 'select-checkbox',
        responsivePriority : 1,
        targets            : 0
      }
    ],
    dom: 'Blfrtip',
    select: {
        style    : 'multi',
        selector : 'td:not(:last-child)'
    },
    buttons: [
      {
        text: 'Get selected data',
        action: function () {
          var count = dataTable.tables[key].tbl.rows( { selected: true } ).count();

          // console.log( count + ' row(s) selected' );
          // console.log( dataTable.tables[key].tbl.rows( { selected: true } ).data());
          $('#' + dataTable.tables[key].tbl + ' .selected').each ( function ( index, elem ) {
            // console.log( $(this).attr('data-id') );
          });
        },
      },
    ]
  } );

  $( '.cb-all' ).removeClass ( 'sorting_asc' );

  $( '.cb-all' ).off ( 'click' );
  $( '.cb-all' ).on  ( 'click', function ( evt ) {
    if ( $(this).hasClass ('all-selected') ) {
      $(this).removeClass ( 'all-selected' );
      dataTable.tables[key].tbl.rows().deselect();
    }
    else {
      $(this).addClass ( 'all-selected' );
      dataTable.tables[key].tbl.rows().select();
    }
  });
};

dataTable.autoSizeBtnBank = function ( rowSelector, btnBankHostSelector ) {
  var w;

  $(rowSelector).each ( function ( index_tr, elem_tr ) {
    //console.log('index_tr', index_tr);
    w = 0;

    $(elem_tr).find(btnBankHostSelector + ' .btn-bank').each ( function ( index_btn, elem_btn ) {
      w += $(elem_btn).outerWidth();
      //console.log(w);
    });
    $(elem_tr).find(btnBankHostSelector).width(w + 10);
  });
};
