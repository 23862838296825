var SVG = {
  makeSVG : function ( tag, attrs, content ) {
    var el = document.createElementNS ( 'http://www.w3.org/2000/svg', tag );

    for ( var k in attrs ) {
      //console.log(k, attrs[k]);
      el.setAttribute ( k, attrs[k] );
    }

    if ( null !== content ) {
      el.innerHTML = content;
    }

    return el;
  },

  makeUse : function ( xlinkHref ) {
    var el = document.createElementNS ( 'http://www.w3.org/2000/svg', 'use' );
    el.setAttribute ( 'xlink:href', xlinkHref );

    return el;
  },
};
