OC.builder = {
  mode : null,
  openTab : 'overview',
  exerciseID : null,
  instID : null,
  siteID : null,
  exerciseLocationID : null,
  published : null,

  init : function ( mode ) {
    // - SHOW AND HIDE CERTAIN TABS
    $('.ocb-section').hide();
    $('.ocb-review-standard').show();

    OC.builder.mode = mode;

    OC.builder.settings.name = $('#exercise-name');

    if ( $('#select-inst').val() === '-' ) {
      $('#select-site').hide();
    }

    $('#oc-building').hide();
    $('#oc-building').removeClass('dn');

    OC.builder.refreshEvents();

    OC.builder.rules.executeAll();
  },

  rules : {

    executeAll : function() {
      // - THIS RULE DICTATES THAT TAB 1 MUST BE INCLUDED IN ALL COMPETENCIES
      OC.builder.rules.tab1RuleA();

      // - THIS RULE DICTATES THAT TAB 4 MUST BE INCLUDED IN ALL COMPETENCIES IF TAB 3 IS
      OC.builder.rules.tab4RuleA();
    },

    tab1RuleA : function() {
      // - THIS RULE DICTATES THAT TAB 1 MUST BE INCLUDED IN ALL COMPETENCIES
      $('#cb-include-section-1').prop ( 'disabled', true );
      $('#cb-include-section-1').prop ( 'checked', true );
      $('#cb-include-section-1').change();
    },

    tab4RuleA : function() {
      // - THIS RULE DICTATES THAT TAB 4 MUST BE INCLUDED IN ALL COMPETENCIES IF TAB 3 IS
      if ( $('#cb-include-section-3').prop ( 'checked' ) ) {
        $('#cb-include-section-4').prop ( 'disabled', true );
        $('#cb-include-section-4').prop ( 'checked', true );
        $('#cb-include-section-4').change();
      }
      else {
        $('#cb-include-section-4').prop ( 'disabled', false );
      }

    },

  },

  inputsRequired : [
    'oc-tab-1-upload',
    'oc-tab-3-upload',
    'oc-tab-4-upload',
  ],
  inputsLoaded : [],

  showDuplicatable : function ( timestamp ) {
    // - FUNCTION SHOWS THE MAKE DUPLICATE BUTTON, THE PUBLISHED STATUS AND THE TIMESTAMP
    $('#oc-duplicating').hide();
    $('#publish-controls-and-status')
      .append (
        $('<li></li>')
          .addClass ( 'tr' )
          .append (
            $('<button></button>')
              .attr ( 'id', 'oc-update-btn' )
              .addClass ( 'gen-btn positive-btn threeD-btn p-- mb--' )
              .append (
                $('<i></i>')
                  .addClass ( 'fa fa-copy' )
              )
              .append (
                ' Make a New Version of this Exercise'
              )
          )
      )
      .append (
        $('<li></li>')
          .addClass ( 'tr' )
          .append (
            $('<button></button>')
              .attr ( 'id', 'oc-publish-btn' )
              .addClass ( 'gen-btn neutral-btn threeD-btn p-- disabled' )
              .attr ( 'disabled', '' )
              .append (
                $('<i></i>')
                  .addClass ( 'fa fa-check' )
              )
              .append (
                ' Published'
              )
          )
          .append (
            $('<p></p>')
              .addClass ( 'fz--' )
              .append (
                'This version was published on ' + timestamp
              )
          )
      );
  },

  checkAllInputsLoaded : function ( id, callback ) {
    OC.builder.inputsLoaded.push ( id );

    var allLoaded = true;

    for ( var i = 0; i < OC.builder.inputsRequired.length; i++ ) {
      if ( OC.builder.inputsLoaded.indexOf ( OC.builder.inputsRequired[i] ) === -1 ) {
        allLoaded = false;
        break;
      }
    }

    if ( allLoaded ) {
      callback();
    }
  },

  deactivateIfPublished : function() {
    if ( OC.builder.published ) {
      OC.builder.deactivate();
    }
  },

  deactivate : function() {
    var selectors = '#cb-include-section-1, ' +
                    '#cb-include-section-2, ' +
                    '#cb-include-section-3, ' +
                    '#cb-include-section-4, ' +
                    '.tab-section input.custom-file-input, ' +
                    '.tab-section select#ocb-select-assessment, ' +
                    '.tab-section button.remove-doc-btn' +
                    '.tab-section button.remove-btn';

    $( selectors )
      .prop ( 'disabled', true );

    $( '.tab-section button.remove-doc-btn' )
      .off ( 'click' );
  },

  activate : function() {
    var selectors = '#cb-include-section-1, ' +
                    '#cb-include-section-2, ' +
                    '#cb-include-section-3, ' +
                    '#cb-include-section-4, ' +
                    '.tab-section input.custom-file-input, ' +
                    '.tab-section select#ocb-select-assessment, ' +
                    '.tab-section button.remove-btn';

    $( selectors )
      .prop ( 'disabled', false );

    OC.builder.refreshEvents();

  },

  refreshEvents : function() {
    if ( OC.builder.mode === 'add' ) {
      $('#oc-save-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          if ( $('#exercise-name').val() !== ''
            && $('#select-inst').val() !== '-'
            && $('#select-site').val() !== '-' ) {

            OC.builder.settings.save ( OC.builder.mode );

          }
        });
    }

    // - ADD NAV TAB EVENTS
    $('.ocb-nav a')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        $('.ocb-nav li').removeClass ( 'selected' );
        $(this).parent().addClass ( 'selected' );

        $('.ocb-section').hide();
        OC.builder.openTab = $(this).attr('data-tab-class');
        this.openTab = $(this).attr('data-tab-class');
        $('.'+this.openTab).show();

        switch ( this.openTab ) {
          case 'ocb-review-standard':
            OC.builder.learningMaterials.onShow();
            break;
          case 'ocb-assess-knowledge':
            OC.builder.knowledgeCheck.onShow();
            break;
          case 'ocb-develop-skill':
            OC.builder.practiceRuns.onShow();
            break;
          case 'ocb-assess-competence':
            OC.builder.practicalAssessments.onShow();
            break;
        }
      });

    $('#exercise-name')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        OC.builder.settings.name = $(this).val();

        if ( OC.builder.mode === 'edit' ) {
          OC.builder.settings.save ( OC.builder.mode );
        }
      });

    $('#select-inst')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( $(this).val() !== '-' ) {
          OC.builder.settings.instID = $(this).val();

          var sites = OC.builder.locations[$(this).val()].sites;

          $('#select-site')
            .empty()
            .append (
              $('<option></option>')
                .attr ( 'value', '' )
                .text ( 'Select a Site' )
            );

          for ( var i = 0; i < sites.length; i++ ) {
            $('#select-site')
              .append (
                $('<option></option>')
                  .attr ( 'value', sites[i].id )
                  .text ( sites[i].name )
              );
          }

          $('#select-site').show();

          onInstChange();// this function is in add-exercise.php and is dependent on a POST var

          $('#select-site')
            .off ( 'change' )
            .on  ( 'change', function ( evt ) {
              OC.builder.settings.siteID = $(this).val();

              // - WHEN THE SITE IS SELECTED, CHECK THE NAME IS NOT BLANK AND WE CAN CREATE AN EX LOC LINK
              if ( OC.builder.mode === 'edit' ) {
                OC.builder.settings.save ( 'edit' );
              }
            });
        }
      });

    if ( OC.builder.mode === 'add' ) {
      $('#select-inst').change();
    }

    $('.ocb-nav input[type="checkbox"]')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        evt.stopPropagation();
      });

    $('#cb-include-section-1')
      .off ( 'mousedown' )
      .on  ( 'mousedown', function ( evt ) {
        $(this).prop ( 'disabled', true );
        $(this).prop ( 'checked', true );
        $(this).change();

        main.flashData.show (
          'Tab 1 must be included in the Occupational Competence Assessment', 'negative', 4
        );
      });

    $('#cb-include-section-1')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.builder.learningMaterials.activate();
        }
        else {
          $(this).prop ( 'disabled', true );
          $(this).prop ( 'checked', true );
          $(this).change();

          main.flashData.show (
            'Tab 1 must be included in the Occupational Competence Assessment', 'negative', 4
          );
        }

        if ( OC.builder.mode === 'edit' ) {
          var sendData = {
            exerciseLocationID : OC.builder.settings.exerciseLocationID,
            include            : $(this).prop ( 'checked' ),
          };

          $.ajax({
            url: '/AJAX-occupational-competence/toggle-tab-1',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse ( returnedData );
              // console.log(returnedData);
            }
          });
        }
      });

    $('#cb-include-section-2')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.builder.knowledgeCheck.activate();
        }
        else {
          OC.builder.knowledgeCheck.deactivate();
        }

        if ( OC.builder.mode === 'edit' ) {
          var sendData = {
            exerciseLocationID : OC.builder.settings.exerciseLocationID,
            include            : $(this).prop ( 'checked' ),
          };

          $.ajax({
            url: '/AJAX-occupational-competence/toggle-tab-2',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse ( returnedData );
              // console.log(returnedData);
            }
          });
        }
      });

    $('#cb-include-section-3')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.builder.practiceRuns.activate();
        }
        else {
          OC.builder.practiceRuns.deactivate();
        }

        if ( OC.builder.mode === 'edit' ) {
          var sendData = {
            exerciseLocationID : OC.builder.settings.exerciseLocationID,
            include            : $(this).prop ( 'checked' ),
          };
          // console.log(sendData);
          $.ajax({
            url: '/AJAX-occupational-competence/toggle-tab-3',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse ( returnedData );
              // console.log(returnedData);
            }
          });
        }
      });

    $('#cb-include-section-4')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.builder.practicalAssessments.activate();
        }
        else {
          OC.builder.practicalAssessments.deactivate();
        }

        if ( OC.builder.mode === 'edit' ) {
          var sendData = {
            exerciseLocationID : OC.builder.settings.exerciseLocationID,
            include            : $(this).prop ( 'checked' ),
          };

          $.ajax({
            url: '/AJAX-occupational-competence/toggle-tab-4',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse ( returnedData );
              // console.log(returnedData);
            }
          });
        }
      });

    $('#save-oc-exercise')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        OC.builder.settings.save();
        OC.builder.learningMaterials.save();
        OC.builder.knowledgeCheck.save();
        OC.builder.practiceRuns.save();
        OC.builder.practicalAssessments.save();
        return false;
      });

    // - TAB 3 ASSESSMENT EVENTS

    $('.ocb-develop-skill .add-generic-assessment-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var obj = {
          i : $('.ocb-develop-skill .obs-assessment').length,
        };

        var genericAssessmentSRC = $('#generic-assessment-tpl').html();
        var genericAssessmentTPL = Handlebars.compile ( genericAssessmentSRC );
        var genericAssessmentHTM = genericAssessmentTPL ( obj );

        $('#OC-tab-3-assessments').append ( genericAssessmentHTM );

        // - CHECK IF TAB OBJECT IS EMPTY AN POPULATE ACCORDINGLY
        if ( ObjectHelper.isEmpty ( OC.builder.tabs[2] ) ) {
          OC.builder.tabs[2].assessments = [];
          OC.builder.tabs[2].docs = [];
        }

        OC.builder.tabs[2].assessments.push({
          "type":"generic",
          "date":"",
          "phys_ref":"",
          "notes":"",
          "status":"N",
          "attachments":[],
          "guidance":{}
        });

        OC.builder.practiceRuns.save();

        OC.builder.refreshEvents();

        if ( !$('.ocb-develop-skill .no-assessments').hasClass ( 'dn' ) ) {
          $('.ocb-develop-skill .no-assessments').addClass ( 'dn' );
        }
      });

    $('.ocb-develop-skill .add-specific-assessment-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var obj = {
          i : $('.ocb-develop-skill .obs-assessment').length,
        };

        var specificAssessmentSRC = $('#specific-assessment-tpl').html();
        var specificAssessmentTPL = Handlebars.compile ( specificAssessmentSRC );
        var specificAssessmentHTM = specificAssessmentTPL ( obj );

        $('#OC-tab-3-assessments').append ( specificAssessmentHTM );

        // - CHECK IF TAB OBJECT IS EMPTY AN POPULATE ACCORDINGLY
        if ( ObjectHelper.isEmpty ( OC.builder.tabs[2] ) ) {
          OC.builder.tabs[2].assessments = [];
          OC.builder.tabs[2].docs = [];
        }

        OC.builder.tabs[2].assessments.push({
          "type":"specific",
          "title":"",
          "desc":"",
          "date":"",
          "phys_ref":"",
          "notes":"",
          "status":"N",
          "attachments":[],
          "guidance":""
        });

        OC.builder.practiceRuns.save();

        OC.builder.refreshEvents();

        if ( !$('.ocb-develop-skill .no-assessments').hasClass ( 'dn' ) ) {
          $('.ocb-develop-skill .no-assessments').addClass ( 'dn' );
        }
      });

    $('.ocb-develop-skill .specific-assessment-title')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[2].assessments[assessmentIndex].title = $(this).val();

        OC.builder.practiceRuns.save();
      });

    $('.ocb-develop-skill .specific-assessment-desc')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[2].assessments[assessmentIndex].desc = $(this).val();

        OC.builder.practiceRuns.save();
      });

    $('.ocb-develop-skill .specific-assessment-guidance')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[2].assessments[assessmentIndex].guidance = $(this).val();

        OC.builder.practiceRuns.save();
      });

    $('.ocb-develop-skill .obs-assessment .remove-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[2].assessments.splice ( assessmentIndex, 1 );

        OC.builder.practiceRuns.save();

        $(this).closest('tbody').remove();

        $('.ocb-develop-skill .obs-assessment').each ( function ( index, elem ) {
          $(elem).attr ( 'data-index', index );
        });

        if ( $('.ocb-develop-skill .obs-assessment').length === 0 ) {
          $('.ocb-develop-skill .no-assessments').removeClass ( 'dn' );
        }
      });

    //////////////////////

    // - TAB 4 ASSESSMENT EVENTS

    $('.ocb-assess-competence .add-generic-assessment-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var obj = {
          i : $('.ocb-assess-competence .obs-assessment').length,
        };

        var genericAssessmentSRC = $('#generic-assessment-tpl').html();
        var genericAssessmentTPL = Handlebars.compile ( genericAssessmentSRC );
        var genericAssessmentHTM = genericAssessmentTPL ( obj );

        $('#OC-tab-4-assessments').append ( genericAssessmentHTM );

        // - CHECK IF TAB OBJECT IS EMPTY AN POPULATE ACCORDINGLY
        if ( ObjectHelper.isEmpty ( OC.builder.tabs[3] ) ) {
          OC.builder.tabs[3].assessments = [];
          OC.builder.tabs[3].docs = [];
        }
        else {
          if ( OC.builder.tabs[3].assessments === null ) {
            OC.builder.tabs[3].assessments = [];
          }
          if ( OC.builder.tabs[3].docs === null ) {
            OC.builder.tabs[3].docs = [];
          }
        }

        OC.builder.tabs[3].assessments.push({
          "type":"generic",
          "date":"",
          "phys_ref":"",
          "notes":"",
          "status":"N",
          "attachments":[],
          "guidance":""
        });
        
        OC.builder.practicalAssessments.save();

        OC.builder.refreshEvents();

        if ( !$('.ocb-assess-competence .no-assessments').hasClass ( 'dn' ) ) {
          $('.ocb-assess-competence .no-assessments').addClass ( 'dn' );
        }
      });

    $('.ocb-assess-competence .add-specific-assessment-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var obj = {
          i : $('.ocb-assess-competence .obs-assessment').length,
        };

        var specificAssessmentSRC = $('#specific-assessment-tpl').html();
        var specificAssessmentTPL = Handlebars.compile ( specificAssessmentSRC );
        var specificAssessmentHTM = specificAssessmentTPL ( obj );

        $('#OC-tab-4-assessments').append ( specificAssessmentHTM );

        // - CHECK IF TAB OBJECT IS EMPTY AN POPULATE ACCORDINGLY
        if ( ObjectHelper.isEmpty ( OC.builder.tabs[3] ) ) {
          OC.builder.tabs[3].assessments = [];
          OC.builder.tabs[3].docs = [];
        }
        else {
          if ( OC.builder.tabs[3].assessments === null ) {
            OC.builder.tabs[3].assessments = [];
          }
          if ( OC.builder.tabs[3].docs === null ) {
            OC.builder.tabs[3].docs = [];
          }
        }

        OC.builder.tabs[3].assessments.push({
          "type":"specific",
          "title":"",
          "desc":"",
          "date":"",
          "phys_ref":"",
          "notes":"",
          "status":"N",
          "attachments":[],
          "guidance":"",
        });

        OC.builder.practicalAssessments.save();

        OC.builder.refreshEvents();

        if ( !$('.ocb-assess-competence .no-assessments').hasClass ( 'dn' ) ) {
          $('.ocb-assess-competence .no-assessments').addClass ( 'dn' );
        }
      });

    $('.ocb-assess-competence .specific-assessment-title')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[3].assessments[assessmentIndex].title = $(this).val();
        // console.log('OC.builder.tabs[3]', OC.builder.tabs[3]);
        OC.builder.practicalAssessments.save();
      });

    $('.ocb-assess-competence .specific-assessment-desc')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[3].assessments[assessmentIndex].desc = $(this).val();
        // console.log('OC.builder.tabs[3]', OC.builder.tabs[3]);
        OC.builder.practicalAssessments.save();
      });

    $('.ocb-assess-competence .specific-assessment-guidance')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        // console.log('specific-assessment-guidance');
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[3].assessments[assessmentIndex].guidance = $(this).val();
        // console.log('OC.builder.tabs[3]', OC.builder.tabs[3]);
        OC.builder.practicalAssessments.save();
      });

    $('.ocb-assess-competence .obs-assessment .remove-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var assessmentIndex = $(this).closest('tbody').data('index');

        OC.builder.tabs[3].assessments.splice ( assessmentIndex, 1 );
        // console.log('OC.builder.tabs[3]', OC.builder.tabs[3]);
        OC.builder.practicalAssessments.save();

        $(this).closest('tbody').remove();

        $('.ocb-assess-competence .obs-assessment').each ( function ( index, elem ) {
          $(elem).attr ( 'data-index', index );
        });

        if ( $('.ocb-assess-competence .obs-assessment').length === 0 ) {
          $('.ocb-assess-competence .no-assessments').removeClass ( 'dn' );
        }
      });

    // - REMOVE DOCUMENTS BUTTON
    $('#DT-OC-docs .remove-doc-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        if ( OC.builder.learningMaterials.active ) {
          dataTable.tables['DT-OC-docs'].tbl.api().row(
            $(this).parent().parent().parent()
          ).remove().draw();

          OC.builder.learningMaterials.save();
        }
      });

    // - REMOVE DOCUMENTS BUTTON
    $('#DT-OC-tab-3-docs .remove-doc-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {

        if ( OC.builder.practiceRuns.active ) {
          dataTable.tables['DT-OC-tab-3-docs'].tbl.api().row(
            $(this).parent().parent().parent()
          ).remove().draw();

          OC.builder.practiceRuns.noAssessments = Number ( $('#develop-skill-default-no-assessments').val() );

          OC.builder.practiceRuns.save();
        }
      });

    $('#OC-tab-3-assessments .remove-spec-guidance')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var id = $(this).parent().data('id');
        var assessmentIndex = $(this).closest('tbody').data('index');

        delete OC.builder.tabs[2].assessments[assessmentIndex].guidance[id];
        $(this).parent().remove();

        OC.builder.practiceRuns.save();
      });

    // - REMOVE DOCUMENTS BUTTON
    $('#DT-OC-tab-4-docs .remove-doc-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        if ( OC.builder.practicalAssessments.active ) {
          dataTable.tables['DT-OC-tab-4-docs'].tbl.api().row(
            $(this).parent().parent().parent()
          ).remove().draw();

          OC.builder.practicalAssessments.noAssessments = Number ( $('#assess-comp-default-no-assessments').val() );

          OC.builder.practicalAssessments.save();
        }
      });

    $('#ocb-select-oc-assessment')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        if ( OC.builder.knowledgeCheck.active ) {
          OC.builder.knowledgeCheck.assessmentID = $(this).val();
          OC.builder.knowledgeCheck.save();
        }
      });

    $('#develop-skill-default-no-assessments')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        if ( !isNaN ( $(this).val() ) && $(this).val() > 0 ) {
          OC.builder.practiceRuns.noAssessments = $(this).val();
          OC.builder.practiceRuns.save();
        }
      });

    $('#assess-comp-default-no-assessments')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        if ( !isNaN ( $(this).val() ) && $(this).val() > 0 ) {
          OC.builder.practicalAssessments.noAssessments = $(this).val();
          OC.builder.practicalAssessments.save();
        }
      });

      OC.builder.learningMaterials.active    = ( $('#cb-include-section-1').prop ( 'checked' ) );
      OC.builder.knowledgeCheck.active       = ( $('#cb-include-section-2').prop ( 'checked' ) );
      OC.builder.practiceRuns.active         = ( $('#cb-include-section-3').prop ( 'checked' ) );
      OC.builder.practicalAssessments.active = ( $('#cb-include-section-4').prop ( 'checked' ) );
  },

  settings : {
    exerciseLocationID : null,
    exerciseID : null,
    instID : null,
    siteID : null,
    name : '',

    save : function ( mode ) {
      // OC.builder.settings.save()
      OC.builder.mode = mode;
      // console.log(mode);
      var sendData = {
        mode               : mode,
        exerciseLocationID : OC.builder.settings.exerciseLocationID,
        exerciseID         : OC.builder.settings.exerciseID,
        instID             : OC.builder.settings.instID,
        siteID             : OC.builder.settings.siteID,
        name               : OC.builder.settings.name,
      };

      $.ajax({
        url: '/AJAX-occupational-competence/save-settings',
        type: 'post',
        data: sendData,
        dataType: 'text',
        success: function ( returnedData ) {
          // console.log('/AJAX-occupational-competence/save-settings:',returnedData);
          returnedData = JSON.parse(returnedData);
          // console.log(returnedData);

          if ( OC.builder.mode === 'add' ) {
            OC.builder.mode = 'edit';

            OC.builder.settings.exerciseLocationID = Number ( returnedData.exLoc.id );
            OC.builder.settings.exerciseID         = Number ( returnedData.exLoc.exercise_id );
            OC.builder.settings.instID             = Number ( returnedData.exLoc.institution_id );
            OC.builder.settings.siteID             = Number ( returnedData.exLoc.site_id );

            $('#oc-save-btn').hide();
            $('#oc-save-btn').parent().find('p').hide();
            $('#oc-building').show();

            var flashMsg = 'You have now created a procedure and linked it to a location. Redirecting...';
            main.flashData.show ( flashMsg, 'positive', 4 );

            setTimeout ( function() {
              window.location.href = '/admin/occupational-competence/edit-exercise/' + returnedData.exLoc.id;
            }, 4500);

          }
        }
      });
    },
  },

  changesMade : function() {
    // - THIS FUNCTION SHOULD FLAG UP WHEN LEAVING THAT CHANGES HAVE BEEN MADE
  },

  learningMaterials : {
    // OC.builder.learningMaterials

    active : false,
    docs : {},
    save : function() {
      var sendData = {
        exerciseLocationID : OC.builder.settings.exerciseLocationID,
        exerciseID         : OC.builder.settings.exerciseID,
        instID             : OC.builder.settings.instID,
        siteID             : OC.builder.settings.siteID,
        currDocIDs         : OC.builder.learningMaterials.docs,
        newDocIDs          : [],
      };

      dataTable.tables['DT-OC-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        sendData.newDocIDs.push ( $(this.node()).attr('data-doc-id') );
      });

      $.ajax({
        url: '/AJAX-occupational-competence/save-learning-materials',
        type: 'post',
        data: sendData,
        dataType: 'text',
        success: function ( returnedData ) {
          // console.log(returnedData);
          returnedData = JSON.parse ( returnedData );
          // console.log(returnedData);

          OC.builder.learningMaterials.genDocsIDArray();
          OC.builder.refreshEvents();
        }
      });
    },

    activate : function() {
      OC.builder.learningMaterials.active = true;
    },

    deactivate : function() {
      OC.builder.learningMaterials.active = false;
    },

    genDocsIDArray : function() {
      OC.builder.learningMaterials.docs = [];

      dataTable.tables['DT-OC-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        OC.builder.learningMaterials.docs.push ( $(this.node()).attr('data-doc-id') );
      });
    },

    AddDocumentsToExercise : function ( selectedFiles ) {// learningMaterials
      for ( var id in selectedFiles ) {

        if ( $('#DT-OC-docs tr[data-doc-id="' + id + '"]').length === 0 ) {
          var newNode = dataTable.tables['DT-OC-docs'].tbl.api().row.add([
            selectedFiles[id].filename,
            '<div class="btn-bank-host fr">' +
              //'<a class="btn-bank gen-btn neutral-btn"><i class="fa fa-search"></i></a>' +
              '<button class="remove-doc-btn btn-bank gen-btn negative-btn"><i class="fa fa-trash"></i></button>' +
            '</div>'
          ]).draw().node();

          $(newNode).attr ( 'data-doc-id', id );
        }

      }

      OC.builder.learningMaterials.save();
    },

    refreshStandardDocs : function ( exLocID ) {
      // var sendData = {
      //   exLocID : exLocID,
      // };
      //
      // $.ajax({
      //   url: '/AJAX-occupational-competence/get-standard-docs',
      //   type: 'post',
      //   data: sendData,
      //   dataType: 'json',
      //   success: function ( returnedData ) {
      //     console.log(returnedData);
      //   },
      // });
    },

    onShow : function () {
      $('#oc-tab-1-upload.inline-upload').refreshInlineUploadInputHeight();
    },
  },// - END OF OC > BUILDER > LEARNING MATERIALS

  knowledgeCheck : {
    // OC.builder.knowledgeCheck

    active : false,
    assessmentID : null,

    setAssessment : function ( assessmentID ) {
      OC.builder.knowledgeCheck.assessmentID = assessmentID;
      OC.builder.knowledgeCheck.save();
    },

    save : function() {
      var sendData = {
        exerciseLocationID : OC.builder.settings.exerciseLocationID,
        exerciseID         : OC.builder.settings.exerciseID,
        instID             : OC.builder.settings.instID,
        siteID             : OC.builder.settings.siteID,
        assessmentID       : OC.builder.knowledgeCheck.assessmentID,
      };

      $.ajax({
        url: '/AJAX-occupational-competence/save-knowledge-check',
        type: 'post',
        data: sendData,
        dataType: 'text',
        success: function ( returnedData ) {
          // console.log(returnedData);
          returnedData = JSON.parse ( returnedData );
          // console.log(returnedData);

          OC.builder.refreshEvents();
        }
      });
    },

    activate : function() {
      OC.builder.knowledgeCheck.active = true;
      $('#ocb-select-assessment').removeAttr ( 'disabled' );
      // console.log('activate', $('.ocb-assess-knowledge .no-edit-cover'));
      $('.ocb-assess-knowledge .no-edit-cover')
        .addClass ( 'dn' )
        .off ( 'click' );
    },

    deactivate : function() {
      OC.builder.knowledgeCheck.active = false;
      $('#ocb-select-assessment').select_by_value ( '' );
      $('#ocb-select-assessment').attr ( 'disabled', 'disabled' );
      // console.log('deactivate', $('.ocb-assess-knowledge .no-edit-cover'));
      $('.ocb-assess-knowledge .no-edit-cover')
        .removeClass ( 'dn' )
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          main.flashData.show (
            'The \'Assess Knowledge\' tab has not been activated, to select an assessment first check the box on the tab to activate it and then use the dropdown selector to select the assessment',
            'negative',
            -1
          );
        });
    },

    onShow : function () {

    },
  },

  practiceRuns : {
    // OC.builder.practiceRuns

    active : false,
    docs : {},
    noAssessments : null,
    specificAssessmentIndex : null,

    save : function() {
      var sendData = {
        exerciseLocationID : OC.builder.settings.exerciseLocationID,
        exerciseID         : OC.builder.settings.exerciseID,
        instID             : OC.builder.settings.instID,
        siteID             : OC.builder.settings.siteID,
        assessments        : JSON.stringify ( OC.builder.tabs[2].assessments ),
        currDocIDs         : OC.builder.practiceRuns.docs,
        newDocIDs          : [],
      };

      dataTable.tables['DT-OC-tab-3-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        sendData.newDocIDs.push ( $(this.node()).attr('data-doc-id') );
      });

      $.ajax({
        url: '/AJAX-occupational-competence/save-practice-runs',
        type: 'post',
        data: sendData,
        dataType: 'json',
        success: function ( returnedData ) {
          // console.log(returnedData);

          OC.builder.practiceRuns.genDocsIDArray();

          OC.builder.refreshEvents();
        }
      });
    },

    activate : function() {
      OC.builder.practiceRuns.active = true;

      $('#cb-include-section-4').prop ( 'disabled', true );
      $('#cb-include-section-4').prop ( 'checked', true );
      $('#cb-include-section-4').change();
    },

    deactivate : function() {
      OC.builder.practiceRuns.active = false;

      $('#cb-include-section-4').prop ( 'disabled', false );
    },

    genDocsIDArray : function() {
      OC.builder.practiceRuns.docs = [];

      dataTable.tables['DT-OC-tab-3-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        OC.builder.practiceRuns.docs.push ( $(this.node()).attr('data-doc-id') );
      });
    },

    AddDocumentsToExercise : function ( selectedFiles ) {// practiceRuns
      // console.log('3:AddDocumentsToExercise');
      for ( var id in selectedFiles ) {

        if ( $('#DT-OC-tab-3-docs tr[data-doc-id="' + id + '"]').length === 0 ) {
          var newNode = dataTable.tables['DT-OC-tab-3-docs'].tbl.api().row.add([
            selectedFiles[id].filename,
            '<div class="btn-bank-host fr">' +
              //'<a class="btn-bank gen-btn neutral-btn"><i class="fa fa-search"></i></a>' +
              '<button class="remove-doc-btn btn-bank gen-btn negative-btn"><i class="fa fa-trash"></i></button>' +
            '</div>'
          ]).draw().node();

          $(newNode)
            .attr ( 'data-doc-id', id )
            .addClass ( 'oc-standard-row' );
        }

      }

      OC.builder.practiceRuns.noAssessments = Number ( $('#develop-skill-default-no-assessments').val() );

      OC.builder.practiceRuns.save();

      OC.builder.refreshEvents();
    },

    onShow : function () {
      $('#oc-tab-3-upload.inline-upload').refreshInlineUploadInputHeight();
    },
  },

  practicalAssessments : {
    // OC.builder.practicalAssessments

    active : false,
    docs : {},
    noAssessments : null,

    save : function() {
      var sendData = {
        exerciseLocationID : OC.builder.settings.exerciseLocationID,
        exerciseID         : OC.builder.settings.exerciseID,
        instID             : OC.builder.settings.instID,
        siteID             : OC.builder.settings.siteID,
        assessments        : JSON.stringify ( OC.builder.tabs[3].assessments ),
        currDocIDs         : OC.builder.practicalAssessments.docs,
        newDocIDs          : [],
      };
      // console.log('tab 4 save', sendData);

      dataTable.tables['DT-OC-tab-4-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        sendData.newDocIDs.push ( $(this.node()).attr('data-doc-id') );
      });

      $.ajax({
        url: '/AJAX-occupational-competence/save-practical-assessments',
        type: 'post',
        data: sendData,
        dataType: 'text',
        success: function ( returnedData ) {
          // console.log(returnedData);
          returnedData = JSON.parse ( returnedData );
          // console.log(returnedData);

          OC.builder.practicalAssessments.genDocsIDArray();

          OC.builder.refreshEvents();
        }
      });
    },

    activate : function() {
      OC.builder.practicalAssessments.active = true;
    },

    deactivate : function() {
      OC.builder.practicalAssessments.active = false;

      if ( $('#cb-include-section-3').prop ( 'checked' ) ) {
        $('#cb-include-section-4').prop ( 'disabled', true );
        $('#cb-include-section-4').prop ( 'checked', true );
        $('#cb-include-section-4').change();

        main.flashData.show (
          'Tab 4 must be included in the Occupational Competence Assessment if Tab 3 is present.', 'negative', 4
        );
      }
    },

    genDocsIDArray : function() {
      OC.builder.practicalAssessments.docs = [];

      dataTable.tables['DT-OC-tab-4-docs'].tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        OC.builder.practicalAssessments.docs.push ( $(this.node()).attr('data-doc-id') );
      });
    },

    AddDocumentsToExercise : function ( selectedFiles ) {// practicalAssessments
      // console.log('4:AddDocumentsToExercise', selectedFiles);
      for ( var id in selectedFiles ) {

        if ( $('#DT-OC-tab-4-docs tr[data-doc-id="' + id + '"]').length === 0 ) {
          var newNode = dataTable.tables['DT-OC-tab-4-docs'].tbl.api().row.add([
            selectedFiles[id].filename,
            '<div class="btn-bank-host fr">' +
              //'<a class="btn-bank gen-btn neutral-btn"><i class="fa fa-search"></i></a>' +
              '<button class="remove-doc-btn btn-bank gen-btn negative-btn"><i class="fa fa-trash"></i></button>' +
            '</div>'
          ]).draw().node();

          $(newNode).attr ( 'data-doc-id', id );
        }

      }

      OC.builder.practicalAssessments.noAssessments = Number ( $('#assess-comp-default-no-assessments').val() );

      OC.builder.practicalAssessments.save();

      OC.builder.refreshEvents();
    },

    onShow : function () {
      $('#oc-tab-4-upload.inline-upload').refreshInlineUploadInputHeight();
    },
  },

};
