// - ajaxTable - declared in assets/js/init.js

ajaxTable.ready = function ( evt ) {};

ajaxTable.initTable = function ( key, dc_function ) {

  var cols   = [],
      fields = JSON.parse ( ajaxTable.tables[key].fields );

  if ( ajaxTable.tables[key].multiSelect ) {
    cols.push({
      defaultContent     : '',
      orderable          : false,
      className          : 'select-checkbox',
      responsivePriority : 1,
      targets            : 0
    });
  }


  for ( var f = 0; f < fields.length; f++ ) {
    cols.push ( fields[f] );
  }

  if ( ajaxTable.tables[key].actionCol !== undefined ) {
    cols.push({
      width: '1px',
      orderable: false,
      data : function(row, type, val, meta) {
        return ajaxTable.tables[key].actionCol.split('{{recordID}}').join(row.DT_RowAttr['data-id']);
      }
    });
  }

  var ajaxTableInitObj = {
    processing: true,

    //serverSide: true,
    // - normal way
    // ajax : {
    //     url: ajaxTable.tables[key].ajax.url
    // },
    // - pipeline
    // ajax : $.fn.dataTable.pipeline( {
    //     url: ajaxTable.tables[key].ajax.url,
    //     pages: 5 // number of pages to cache
    // } ),
    // - own function
    ajax : function (data, callback, settings) {
      settings.jqXHR = $.ajax( {
        url      : ajaxTable.tables[key].ajax.url,
        data     : data,
        dataType : 'json',
        success:  function ( json ) {
          // console.log(json);
          // cacheLastJson = $.extend(true, {}, json);
          //
          // if ( cacheLower != drawStart ) {
          //     json.data.splice( 0, drawStart-cacheLower );
          // }
          // json.data.splice( requestLength, json.data.length );
          //
          callback( json );

          ajaxTable.addActionColClass ( 'td:last-child' );
        }
      } );
    },
    //deferLoading : 50,
    drawCallback : function ( settings ) {
      dc_function();
    },
    responsive   : true,
    lengthMenu   : [[10, 25, 50, -1], [10, 25, 50, "All"]],
    columns      : cols,
    dom          : 'Blfrtip',
    select       : {
        style    : 'multi',
        selector : 'td:not(:last-child)'
    },
    language : {
      search : 'Filter Results:'
    },
    buttons      : [
      /*{
        text     : 'Get selected data',
        action   : function () {
          var count = ajaxTable.tables[key].tbl.rows( { selected: true } ).count();

          // console.log( count + ' row(s) selected' );
          // console.log( ajaxTable.tables[key].tbl.rows( { selected: true } ).data());
          $('#' + ajaxTable.tables[key].id + ' .selected').each ( function ( index, elem ) {
            // console.log( $(this).attr('data-id') );
          });
        },
      },*/
    ],
  };

  if ( ajaxTable.tables[key].languageSearch !== undefined ) {
    ajaxTableInitObj.language.search = ajaxTable.tables[key].languageSearch;
  }

  ajaxTable.tables[key].tbl = $('#' + ajaxTable.tables[key].id).DataTable( ajaxTableInitObj );

  // if ( ajaxTable.tables[key].cbAll ) {
  // console.log('#' + ajaxTable.tables[key].id + ' .cb-all');
    $( '#' + ajaxTable.tables[key].id + ' .cb-all' ).removeClass ( 'sorting_asc' );

    $( '#' + ajaxTable.tables[key].id + ' .cb-all' ).off ( 'click' );
    $( '#' + ajaxTable.tables[key].id + ' .cb-all' ).on  ( 'click', function ( evt ) {
      if ( $(this).hasClass ('all-selected') ) {
        $(this).removeClass ( 'all-selected' );
        ajaxTable.tables[key].tbl.rows().deselect();
      }
      else {
        $(this).addClass ( 'all-selected' );
        ajaxTable.tables[key].tbl.rows().select();
      }
    });
  // }
  // else {
  //
  //   $( '.cb-all' ).removeClass ( 'select-checkbox' );
  //   $( '.cb-all' ).removeClass ( 'cb-all' );
  // }

  /*ajaxTable.tables[key].tbl.on( 'xhr', function () {
    var json = ajaxTable.tables[key].tbl.ajax.json();
    // console.log( json.data.length +' row(s) were loaded' );
    // console.log( json.data );
  } );*/


};

ajaxTable.addActionColClass = function ( selector ) {
  $(selector).addClass ( 'action-col' );
};
