var Cpd = {

  Cpd : null,
  doc_id : 0,
  docIDs : [],

  getDownloads : function ( selectedFiles ) {

    var docIDs = '';
    // console.log(selectedFiles);
    dataTable.tables['DTCpdDownloads'].tbl.api().clear().draw();
    $.each(selectedFiles,function(i,item){
      docIDs += item.id + ',';
      rowNode = dataTable.tables['DTCpdDownloads'].tbl.api().row.add([
        '<a href="/uploads/' + main.getSub() + '/' + item.path + '" target="_blank">' +
          item.filename +
        '</a>',
        '<a class="delete-file-btn lone-bank-btn gen-btn negative-btn">' +
          '<i class="fa fa-trash"></i>' +
        '</a>' +
        '<a class="confirm-delete-file-btn lone-bank-btn gen-btn positive-btn dn">' +
          'Confirm?' +
        '</a>',
      ]).draw().node();

      $(rowNode)
        .attr ( 'data-id', returnedData.documents[i].id )
        .attr ( 'data-temp', 0 )
        .addClass ( 'cpd-download-tr' );

      $(rowNode)
        .find ( 'td:last-child' )
        .addClass ( 'tr' );
    });

    $('body').find('#doc-ids').val(docIDs);

    $('#DT-cpd-downloads .delete-file-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        $(this).next ( '.confirm-delete-file-btn' ).removeClass ( 'dn' );
        $(this).hide();
      });

    $('#DT-cpd-downloads .confirm-delete-file-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );
        var temp       = $(this).closest ( 'tr' ).attr ( 'data-temp' );

        dataTable.tables['DTCpdDownloads'].tbl.api().row (
          $(this).closest ( 'tr' )
        ).remove().draw();

        if ( temp === '0' ) {
          var IDs = $('body').find('#doc-ids').val();
          var IDsArr = IDs.split(',');
          for ( var i = 0; i < IDsArr.length; i++ ) {
            if ( IDsArr[i] == documentID ) {
              IDsArr.splice(i,1);
            }
          }

          $('body').find('#doc-ids').val(IDsArr.join(','));
        }

        Cpd.deleteDocument ( documentID, true );
      });
  },

  refreshDownloads : function ( userID, recordID ) {
    var sendData = {
      userID : userID,
      recordID : recordID,
    };

    $.ajax({
      url: '/AJAX-cpd/get-all-downloads',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        dataTable.tables['DTCpdDownloads'].tbl.api().clear().draw();
        var rowNode;

        if ( !returnedData.error ) {

          for ( var i = 0; i < returnedData.documents.length; i++ ) {
            rowNode = dataTable.tables['DTCpdDownloads'].tbl.api().row.add([
              '<a href="/uploads/' + main.getSub() + '/' + returnedData.documents[i].path + '" target="_blank">' +
                returnedData.documents[i].title +
              '</a>',
              '<a class="delete-file-btn lone-bank-btn gen-btn negative-btn">' +
                '<i class="fa fa-trash"></i>' +
              '</a>' +
              '<a class="confirm-delete-file-btn lone-bank-btn gen-btn positive-btn dn">' +
                'Confirm?' +
              '</a>',
            ]).draw().node();

            $(rowNode)
              .attr ( 'data-id', returnedData.documents[i].id )
              .attr ( 'data-temp', 0 )
              .addClass ( 'cpd-download-tr' );

            $(rowNode)
              .find ( 'td:last-child' )
              .addClass ( 'tr' );
          }
        }

        $('#DT-cpd-downloads .delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            $(this).next ( '.confirm-delete-file-btn' ).removeClass ( 'dn' );
            $(this).hide();
          });

        $('#DT-cpd-downloads .confirm-delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {

            var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

            dataTable.tables['DTCpdDownloads'].tbl.api().row (
              $(this).closest ( 'tr' )
            ).remove().draw();

            var IDs = $('body').find('#doc-ids').val();
            var IDsArr = IDs.split(',');
            for ( var i = 0; i < IDsArr.length; i++ ) {
              if ( IDsArr[i] == documentID) {
                IDsArr.splice ( i, 1 );
              }
            }

            $('body').find('#doc-ids').val ( IDsArr.join(',') );

            Cpd.deleteDocument ( documentID, true );
          });
      }
    });

  },

  refreshTempDownloads : function ( userID ) {
    var sendData = {
      userID : userID,
    };

    $.ajax({
      url: '/AJAX-cpd/get-all-temp-downloads',
      type: 'post',
      data: sendData,
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log(returnedData);
        returnedData = JSON.parse(returnedData);
        // console.log(returnedData);
        dataTable.tables['DTCpdDownloads'].tbl.api().clear().draw();
        var rowNode;
        if ( !returnedData.error ) {

          var docIDs = [];

          for ( var i = 0; i < returnedData.documents.length; i++ ) {
            rowNode = dataTable.tables['DTCpdDownloads'].tbl.api().row.add([
              '<a href="/uploads/' + main.getSub() + '/' + returnedData.documents[i].path + '" target="_blank">' +
                returnedData.documents[i].title +
              '</a>',
              '<a class="delete-file-btn lone-bank-btn gen-btn negative-btn">' +
                '<i class="fa fa-trash"></i>' +
              '</a>' +
              '<a class="confirm-delete-file-btn lone-bank-btn gen-btn positive-btn dn">' +
                'Confirm?' +
              '</a>',
            ]).draw().node();

            $(rowNode)
              .attr ( 'data-id', returnedData.documents[i].id )
              .attr ( 'data-temp', 1 )
              .addClass ( 'cpd-download-tr' );

            $(rowNode)
              .find ( 'td:last-child' )
              .addClass ( 'tr' );

            docIDs.push ( returnedData.documents[i].id );
          }

          Cpd.updateDocIDsField ( docIDs );
        }

        $('#DT-cpd-downloads .delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            $(this).next ( '.confirm-delete-file-btn' ).removeClass ( 'dn' );
            $(this).hide();
          });

        $('#DT-cpd-downloads .confirm-delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {

            var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

            dataTable.tables['DTCpdDownloads'].tbl.api().row (
              $(this).closest ( 'tr' )
            ).remove().draw();

            var IDs = $('body').find('#doc-ids').val();
            var IDsArr = IDs.split(',');
            for ( var i = 0; i < IDsArr.length; i++ ) {
              if ( IDsArr[i] == documentID) {
                IDsArr.splice ( i, 1 );
              }
            }

            $('body').find('#doc-ids').val ( IDsArr.join(',') );

            Cpd.deleteDocument ( documentID, true );
          });
      }
    });

  },

  refreshSavedAndTempDownloads : function ( cpdID, userID ) {
    // - THIS IS USED IN EDITING A CPD IT GETS BOTH THE UPLOADS THAT HAVE BEEN SAVED
    // - AND THOSE THAT HAVE BEEN UPLAODED BUT ARE CURRENTLY TEMPORARY

    var allDocIDs = [];

    dataTable.tables['DTCpdDownloads'].tbl.api().clear().draw();

    ////////////////
    // TEMP FILES //
    ////////////////

    var sendData = {
      userID : userID,
    };

    $.ajax({
      url: '/AJAX-cpd/get-all-temp-downloads',
      type: 'post',
      data: sendData,
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log('temp');
        // console.log(returnedData);
        returnedData = JSON.parse(returnedData);
        // console.log(returnedData);

        var rowNode;
        if ( !returnedData.error ) {

          var docIDs = [];

          for ( var i = 0; i < returnedData.documents.length; i++ ) {
            rowNode = dataTable.tables['DTCpdDownloads'].tbl.api().row.add([
              '<a href="/uploads/' + main.getSub() + '/' + returnedData.documents[i].path + '" target="_blank">' +
                returnedData.documents[i].title +
              '</a>',
              '<a class="delete-file-btn lone-bank-btn gen-btn negative-btn">' +
                '<i class="fa fa-trash"></i>' +
              '</a>' +
              '<a class="confirm-delete-file-btn lone-bank-btn gen-btn positive-btn dn">' +
                'Confirm?' +
              '</a>',
            ]).draw().node();

            $(rowNode)
              .attr ( 'data-id', returnedData.documents[i].id )
              .attr ( 'data-temp', 1 )
              .addClass ( 'cpd-download-tr' );

            $(rowNode)
              .find ( 'td:last-child' )
              .addClass ( 'tr' );

            docIDs.push ( returnedData.documents[i].id );
            allDocIDs.push ( returnedData.documents[i].id );
          }

          Cpd.updateDocIDsField ( docIDs );
        }

        $('#DT-cpd-downloads .delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            $(this).next ( '.confirm-delete-file-btn' ).removeClass ( 'dn' );
            $(this).hide();
          });

        $('#DT-cpd-downloads tr[data-temp="1"] .confirm-delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

            dataTable.tables['DTCpdDownloads'].tbl.api().row (
              $(this).closest ( 'tr' )
            ).remove().draw();

            var IDs = $('body').find('#doc-ids').val();
            var IDsArr = IDs.split(',');
            for ( var i = 0; i < IDsArr.length; i++ ) {
              if ( IDsArr[i] == documentID ) {
                IDsArr.splice ( i, 1 );
              }
            }

            $('body').find('#doc-ids').val ( IDsArr.join(',') );

            Cpd.deleteDocument ( documentID, true );
          });
      }
    });

    /////////////////
    // SAVED FILES //
    /////////////////

    var sendData = {
      recordID : cpdID,
    };

    $.ajax({
      url: '/AJAX-cpd/get-downloads-of-entry',
      type: 'post',
      data: sendData,
      dataType: 'text',
      success: function ( returnedData ) {
        // console.log('saved');
        // console.log(returnedData);
        returnedData = JSON.parse(returnedData);
        // console.log(returnedData);

        var rowNode;
        if ( !returnedData.error ) {
          var docIDs = [];

          for ( var i = 0; i < returnedData.documents.length; i++ ) {
            rowNode = dataTable.tables['DTCpdDownloads'].tbl.api().row.add([
              '<a href="/uploads/' + main.getSub() + '/' + returnedData.documents[i].path + '" target="_blank">' +
                returnedData.documents[i].title +
              '</a>',
              '<a class="delete-file-btn lone-bank-btn gen-btn negative-btn">' +
                '<i class="fa fa-trash"></i>' +
              '</a>' +
              '<a class="confirm-delete-file-btn lone-bank-btn gen-btn positive-btn dn">' +
                'Confirm?' +
              '</a>',
            ]).draw().node();

            $(rowNode)
              .attr ( 'data-id', returnedData.documents[i].id )
              .attr ( 'data-temp', 0 )
              .addClass ( 'cpd-download-tr' );

            $(rowNode)
              .find ( 'td:last-child' )
              .addClass ( 'tr' );

            docIDs.push ( returnedData.documents[i].id );
            allDocIDs.push ( returnedData.documents[i].id );
          }

          Cpd.updateDocIDsField ( allDocIDs );
        }

        $('#DT-cpd-downloads .delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            $(this).next ( '.confirm-delete-file-btn' ).removeClass ( 'dn' );
            $(this).hide();
          });

        $('#DT-cpd-downloads tr[data-temp="0"] .confirm-delete-file-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {

            var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

            dataTable.tables['DTCpdDownloads'].tbl.api().row (
              $(this).closest ( 'tr' )
            ).remove().draw();

            var IDs = $('body').find('#doc-ids').val();
            var IDsArr = IDs.split(',');
            for ( var i = 0; i < IDsArr.length; i++ ) {
              if ( IDsArr[i] == documentID ) {
                IDsArr.splice ( i, 1 );
              }
            }

            $('body').find('#doc-ids').val ( IDsArr.join(',') );

            Cpd.deleteDocument ( documentID, true );
          });
      }
    });
  },


  deleteTempFiles : function ( userID ) {
    var sendData = {
      userID : userID,
    };

    $.ajax({
      url: '/AJAX-cpd/delete-temp-files',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
      },
    });
  },

  deleteDocument : function ( documentID, hardDelete ) {
    repos.deleteDocument ( documentID, hardDelete );
  },

  updateDocIDsField : function ( docIDs ) {
    var docIDsStr = docIDs.join ( ',' );
    $('#doc-ids').val ( docIDsStr );
  },

  addDocsToEntry : function ( selectedFiles ) {
    Cpd.docIDs = [];

    for ( var id in selectedFiles ) {
      Cpd.docIDs.push ( id );
    }
  },

};
