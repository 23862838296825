var nhRadioGroup = {
  activate : function ( selector, onChange ) {
    $(selector).find('.nh-radio-btn').each ( function ( index, elem ) {
      $(elem)
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          $(this).siblings().each ( function ( sIndex, sElem ) {
            $(sElem).removeClass ( 'nh-radio-btn-on' );
            $(sElem).addClass ( 'nh-radio-btn-off' );

            $(sElem).find ( 'i, svg' ).removeClass ( 'fa-check-circle' );
            $(sElem).find ( 'i, svg' ).removeClass ( 'fa-circle' );
            $(sElem).find ( 'i, svg' ).addClass ( 'fa-circle' );

            $(sElem).find ( 'input' ).prop ( 'checked', false );
          });

          $(elem).addClass ( 'nh-radio-btn-on' );

          $(elem).find ( 'i, svg' ).removeClass ( 'fa-circle' );
          $(elem).find ( 'i, svg' ).addClass ( 'fa-check-circle' );

          $(elem).find ( 'input' ).prop ( 'checked', true );

          var value = $(elem).data('value');
          $(elem).closest('.nh-radio-group').attr('data-value', value);

          if ( onChange !== null && onChange !== undefined ) {
            onChange ( value );
          }
        });
    });
  },
};
