var Help = {
  search : {
    maxExcerptCharsBefore : 40,
    maxExcerptCharsAfter  : 40,

    init : function() {
      Help.search.refreshEvents();
      Help.search.refreshResultsTable();
    },

    refreshEvents : function() {
      $('#help-search-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          evt.preventDefault();

          var sendData = {
            searchTerm            : $('#help-search-input').val(),
            maxExcerptCharsBefore : Help.search.maxExcerptCharsBefore,
            maxExcerptCharsAfter  : Help.search.maxExcerptCharsAfter,
          };
          // console.log(sendData);

          $.ajax({
            url: '/AJAX-help/search',
            type: 'post',
            data: sendData,
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log(returnedData);
              returnedData = JSON.parse(returnedData);
              // console.log(returnedData);
              Help.search.clearResults();
              Help.search.populateResults ( returnedData.articles, returnedData.searchTerm );
            },
          });
        });
    },

    destroyResultsTable : function() {
      if ( dataTable.tables['DT-help-search-results'] !== undefined
        && dataTable.tables['DT-help-search-results'].tbl !== undefined ) {
        dataTable.tables['DT-help-search-results'].tbl.api().destroy();
      }
    },

    initResultsTable : function() {
      dataTable.tables['DT-help-search-results'] = {
        tbl : $('#DT-help-search-results').dataTable({
          lengthChange : false,
          filter : false,
          columnDefs : [
            {
              width     : '200px',
              className : 'vat p',
              targets   : [0],
            },
            {
              className : 'vat p',
              targets   : [1],
            },
            {
              width     : '40px',
              className : 'vat p',
              targets   : [-1],
            },
          ],
        }),
      };
    },

    refreshResultsTable : function() {
      Help.search.destroyResultsTable();
      Help.search.initResultsTable();
    },

    populateResults : function ( data, searchTerm ) {
      var i, j;

      for ( i = 0; i < data.length; i++ ) {
        for ( j = 0; j < data[i].highlightedExcerpts.length; j++ ) {

          dataTable.tables['DT-help-search-results'].tbl.api().row.add ([
            data[i].row.title,
            data[i].highlightedExcerpts[j],
            '<a href="/help/' + data[i].row.remaining_slug + '" class="gen-btn positive-btn">' +
              'View' +
            '</a>',
          ]);
        }
      }

      dataTable.tables['DT-help-search-results'].tbl.api().draw();
    },

    clearResults : function() {
      dataTable.tables['DT-help-search-results'].tbl.api().clear().draw();
    },
  },
};
