var Documents = {
  getDocument : function ( id, callback ) {
    var sendData = {
      id : id,
    };
// console.log('sendData',sendData);
    $.ajax({
      url: '/AJAX-documents/get-document',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        callback ( returnedData );
      },
    });
  },

  getDocumentsFromIDArray : function ( files, callback ) {
    var sendData = {
      files : files,
    };

    $.ajax({
      url: '/AJAX-documents/get-documents-from-id-array',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        callback ( returnedData );
      },
    });
  },

  getDocumentsFromObject : function ( files, callback ) {
    var sendData = {
      files : files,
    };

    $.ajax({
      url: '/AJAX-documents/get-documents-from-object',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        callback ( returnedData );
      },
    });
  },
};
