var Athena = {
  course : null,
  user : null,
  userCourse : null,

  unitJSON : null,
  currentSlideIndex : null,
  currentSlideObj : null,
  skin : null,
  questionID : null,
  playall : null,
  assessmentURL : '/AJAX-athena/loadAssessment',

  skins : {},

  tempShowFooterTimeout : null,

  // - VALUES EITHER MENU OR CONTENT JUST WHETHER WE'RE SHOWING MENU OR CONTENT
  on : 'menu',

  init : function ( skin ) {
    Athena.refreshEvents();
    Athena.skin = skin;
    Athena.skins[skin].init();
    Athena.nav.hideButtons ( ['back', 'replay', 'next', 'finish'] );
    document.addEventListener("fullscreenchange", function(evt) {
      var slideVideo = document.getElementById('slide-video');
      
      if ( window.innerHeight == screen.height ) {
        slideVideo.setAttribute ( 'controls', '' );
      }
      else {
        slideVideo.removeAttribute ( 'controls' );
      }
    });
  },

  setQuestionId : function(id) {
    Athena.questionID = id;
  },

  setPlayall : function(val) {
    Athena.playall = val;
  },

  close : function() {
    $('.athena-flex').remove();
    $('.overlays').hide();

    // - REESTABLISH HTML SCROLLING
    $('html').css ( 'overflow', 'auto' );
  },

  calculateProgress : function() {
    var total = Athena.content.statuses.length * 2;
    var progress = 0;

    for ( var i = 0; i < Athena.content.statuses.length; i++ ) {
      progress += Athena.content.statuses[i];
    }

    return Math.round ( ( progress / total ) * 100 );
  },

  loadAthenaAssessment : function ( assessmentID, onPass, onPassArgs, onFail, onFailArgs, playall ) {
    Assessment.player.onPass     = onPass;
    Assessment.player.onPassArgs = onPassArgs;
    Assessment.player.onFail     = onFail;
    Assessment.player.onFailArgs = onFailArgs;
    
    // - STOP THE PARENT PAGE FROM SCROLLING
    $('html').css ( 'overflow', 'hidden' );

    var sendData = {
      assessmentID : assessmentID,
      playall : playall,
    };

    $.ajax({
      url: '/AJAX-athena/loadHTML',
      data: sendData,
      type: 'post',
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
        Athena.userCourse = {
          suspend_data : {
            content_statuses:[0],
          }
        };

        Assessment.data = {
          settings : {
            passPercentage                       : Number ( returnedData.assessment.pass_percentage ),
            pickNumFromPool                      : Number ( returnedData.assessment.pick_num_from_pool ),
            pickFromSections                     : returnedData.assessment.pick_from_sections == "1",
            shuffleQuestions                     : returnedData.assessment.shuffle_questions == "1",
            maintainOrderOfSections              : returnedData.assessment.maintain_order_of_sections == "1",
            includeSectionLabelsInQuestionNumber : returnedData.assessment.include_section_labels_in_question_number == "1",
            feedbackAfterQuestions               : Assessment.player.feedbackAfterQuestions[Number ( returnedData.assessment.feedback_after_questions )],
            feedbackModeAtSummary                : Number ( returnedData.assessment.feedback_mode_at_summary ),
            lockAfterNumAttempts                 : Number ( returnedData.assessment.lock_after_num_attempts ),
            timeLimit                            : Number ( returnedData.assessment.time_limit ),
          },
          userAssessment : returnedData.userAssessment,
        };

        Assessment.player.assessmentID = returnedData.assessment.id;

        // Athena.content.statuses = Athena.userCourse.suspend_data.content_statuses;

        if ( $('.overlays .athena-flex').length === 0 ) {
          $('.overlays').append ( returnedData.html );
        }
        $('.overlays .popup-flex').hide();

        $('.overlays').show();
        $('.overlays .blackout').show();

        $('.overlays .athena-flex').show();
        $('.overlays .athena-in-popup').show();

        TweenMax.to ( '.athena-in-popup', 0.5, {top:0} );
        $('.athena-close-btn').on ( 'click', function() {
          Athena.close();
        });
      },
    });
  },

  loadAthenaWithCourse : function ( userCourseID ) {
    // console.log(userCourseID);
    // - STOP THE PARENT PAGE FROM SCROLLING
    $('html').css ( 'overflow', 'hidden' );

    var sendData = {
      userCourseID : userCourseID
    };

    $.ajax({
      url: '/AJAX-athena/getUserAndCourseData',
      data: sendData,
      type: 'post',
      dataType: 'json',
      success: function ( returnedData ) {

        Athena.user = returnedData.user;
        Athena.course = returnedData.course;
        Athena.userCourse = returnedData.userCourse;
        Athena.content.statuses = Athena.userCourse.suspend_data.content_statuses;

        var sendData = {
          courseID : Athena.course.id,
        };

        $.ajax({
          url: '/AJAX-athena/loadHTML',
          data: sendData,
          type: 'post',
          dataType: 'json',
          success: function ( returnedData ) {
            if ( $('.overlays .athena-flex').length === 0 ) {
              $('.overlays').append ( returnedData.html );
            }

            $('.overlays .popup-flex').hide();

            $('.overlays').show();
            $('.overlays .blackout').show();

            $('.overlays .athena-flex').show();
            $('.overlays .athena-in-popup').show();

            TweenMax.to ( '.athena-in-popup', 0.5, {top:0} );

            $('.athena-close-btn').on ( 'click', function() {
              Athena.close();
            });

            TweenMax.set ( '#loading-spinner', {
              autoAlpha: 0,
            });

          },
        });
      },
    });
  },

  refreshEvents : function() {
    $('#menu-btn')
      .off ( 'click.menu' )
      .on  ( 'click.menu', function ( evt ) {
        Athena.closeContentShowMenu();
      });

    $('#full-screen-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        Athena.content.toggleFullScreen();
      });

    $('#back-btn')
      .off ( 'click.back' )
      .on  ( 'click.back', function ( evt ) {
        Athena.content.back();
      });

    $('#replay-btn')
      .off ( 'click.replay' )
      .on  ( 'click.replay', function ( evt ) {
        Athena.content.replay();
      });

    $('#next-btn')
      .off ( 'click.next' )
      .on  ( 'click.next', function ( evt ) {
        Athena.content.next();
      });

    $('#finish-btn')
      .off ( 'click.finish' )
      .on  ( 'click.finish' , function ( evt ) {
        popup.close();

        TweenMax.delayedCall ( 0.25, function() {
          window.location.href = window.location.href;
        });
      });
  },

  loadUnitJSON : function ( folder, assessmentID ) {//console.log(folder, assessmentID);
    $.ajax({
      url: '/' + folder + '/unit.json',
      dataType: 'json',
      success: function ( returnedData ) {
        //console.log(returnedData);
        Athena.unitJSON = returnedData;

        if(typeof Athena.unitJSON.menuItems[0].dynamic === 'undefined' || Athena.unitJSON.menuItems[0].dynamic === 0)
        {
          Athena.menu.render ( Athena.unitJSON );
        }
        else{
          Athena.unitJSON.menuItems[0].assessmentID = assessmentID;
          Athena.menu.render ( Athena.unitJSON );
        }
      },
    });
  },

  hideHeaderAndFooter : function() {
    Athena.hideHeader();
    Athena.hideFooter();
    Athena.activateShowHeaderWhenMouseAtBottom();
  },

  hideHeader : function() {
    TweenMax.delayedCall ( 0.5, function() {
      $('.athena > header')
        .css ( 'position', 'absolute' )
        .css ( 'width', '100%' );
    });
    TweenMax.to ( $('.athena > header'), 0.3, {top:'-80px', delay:0.5} );
    TweenMax.to ( $('.athena-content'), 0.3, {'margin-top':0, top:0, delay:0.5} );
  },

  hideFooter : function() {
    TweenMax.delayedCall ( 0.5, function() {
      $('.athena > footer')
        .css ( 'position', 'absolute' )
        .css ( 'width', '100%' );
    });
    TweenMax.to ( $('.athena > footer'), 0.3, {bottom:'-60px', delay:0.5} );
  },

  showHeaderAndFooter : function() {
    Athena.showHeader();
    Athena.showFooter();
  },

  showHeader : function() {
    TweenMax.to ( $('.athena > header'), 0.3, {top:0, delay:0.5} );

    TweenMax.to ( $('.athena-content'), 0.3, {
      'margin-top' : Athena.skins[Athena.skin].showHeaderMarginTop,
      'top'        : Athena.skins[Athena.skin].showHeaderTop,
      'delay'      : 0.5,
    });
  },

  showFooter : function() {
    TweenMax.to ( $('.athena > footer'), 0.3, {bottom:0, delay:0.5} );
  },

  activateShowHeaderWhenMouseAtBottom : function() {
    $('.athena-in-popup')
      .off ( 'mousemove' )
      .on  ( 'mousemove', function ( evt ) {
        var x = evt.pageX - $(evt.currentTarget).offset().left;
        var y = evt.pageY - $(evt.currentTarget).offset().top;

        clearTimeout ( Athena.tempShowFooterTimeout );

        if ( y > 640 ) {
          Athena.showFooter();
        }
        else {
          Athena.tempShowFooterTimeout = setTimeout ( Athena.hideFooter, 2000 );
        }
      });
  },

  deactivateShowHeaderWhenMouseAtBottom : function() {
    $('.athena-in-popup')
      .off ( 'mousemove' );

    clearTimeout ( Athena.tempShowFooterTimeout );
    Athena.showHeaderAndFooter();
  },

  nav : {
    btns : {
      'menu'   : '#menu-btn',
      'back'   : '#back-btn',
      'replay' : '#replay-btn',
      'next'   : '#next-btn',
      'finish' : '#finish-btn',
    },

    hideButtons : function ( hide ) {
      for ( var i = 0; i < hide.length; i++ ) {
        $( Athena.nav.btns[hide[i]] ).hide();
      }
    },

    showButtons : function ( show ) {
      for ( var i = 0; i < show.length; i++ ) {
        $( Athena.nav.btns[show[i]] ).show();
      }
    },

    disableNext : function() {
      $('#next-btn')
        .addClass ( 'disabled' )
        .attr ( 'src', '/assets/graphics/athena-skins/' + Athena.skin + '/NextBtnDisabled.png' )
        .off ( 'click.next' )
        .on  ( 'click.next-loading', function ( evt ) {
          main.flashData.show (
            'Content is now loading, please wait...',
            'negative',
            4
          );
        });
    },

    enableNext : function() {
      $('#next-btn')
        .removeClass ( 'disabled' )
        .attr ( 'src', '/assets/graphics/athena-skins/' + Athena.skin + '/NextBtn.png' )
        .off ( 'click.next' )
        .off ( 'click.next-loading' )
        .on  ( 'click.next', function ( evt ) {
          Athena.content.next();
        });
    },

    // - GOES THROUGH EACH TOOL TIP AND CENTRALISES THE TOOLTIP AND THE POINTERS
    positionToolTips : function() {
      $('.athena-tooltip').each(function ( index, elem ) {
        var ttWidth = $(elem).outerWidth();
        var btnWidth = $(elem).parent().outerWidth();

        $(elem).css ( 'left', ( '-' + ( (ttWidth - btnWidth) / 2 ) ) + 'px' );
      });
    },
  },

  closeContentShowMenu : function() {
    Athena.deactivateShowHeaderWhenMouseAtBottom();
    Athena.showHeaderAndFooter();
    Athena.content.hide();
    Athena.nav.hideButtons ( ['back', 'replay', 'next'] );
    Athena.menu.show ( 0.6 );

    Athena.timeline.pause();
    Athena.timeline.resetPlayhead();
    Athena.narration.currNarration.pause();

    if ( Athena.clickNextToContinue.showing ) {
      Athena.clickNextToContinue.hide();
    }
  },

  updateHeading : function ( heading, show ) {
    $('#heading').text ( heading );

    if ( show ) {
      $('#heading').css ( 'opacity', 1 );
    }
  },

  updateSubHeading : function ( subHeading, show ) {
    $('#sub-heading').text ( subHeading );

    if ( show ) {
      $('#sub-heading').css ( 'opacity', 1 );
    }
  },

  content : {
    show : function ( _delay ) {
      var delay = ( _delay === undefined ) ? 0 : _delay;

      TweenMax.to ( $('#athena-content'), 0.5, {autoAlpha : 1, delay : delay, onStart: function() {
        $('#athena-content')
          .show()
          .css('display', 'flex');
      }} );

      Athena.nav.hideButtons ( ['back', 'replay', 'next'] );

      Athena.on = 'content';
    },

    hide : function ( _delay ) {
      var delay = ( _delay === undefined ) ? 0 : _delay;

      TweenMax.to ( $('#athena-content'), 0.5, {autoAlpha : 0, delay : delay, onComplete: function() {
        $('#athena-content').hide();
      }} );

      if ( Athena.content.onClose !== null ) {
        Athena.content.onClose();
      }
    },

    slideVideo : null,
    onClose : null,

    next : function() {
      if ( Athena.content.statuses[Athena.currentSlideIndex] === 2 ) {
        Athena.currentSlideIndex++;

        if ( Athena.clickNextToContinue.showing ) {
          Athena.clickNextToContinue.hide();
        }

        Athena.nav.disableNext();

        Athena.content.load ( Athena.currentSlideIndex );
      }
      else {
        main.flashData.show ( 'All previous sections must be completed before you can view this section.', 'negative', 4 );
      }
    },

    replay : function() {
      if ( Athena.clickNextToContinue.showing ) {
        Athena.clickNextToContinue.hide();
      }

      Athena.content.load ( Athena.currentSlideIndex );
    },

    back : function() {
      if ( Athena.currentSlideIndex ) {
        Athena.currentSlideIndex--;
      }

      if ( Athena.clickNextToContinue.showing ) {
        Athena.clickNextToContinue.hide();
      }

      Athena.content.load ( Athena.currentSlideIndex );
    },

    load : function ( index ) {

      Athena.currentSlideIndex = Number ( index );
      Athena.currentSlideObj = Athena.unitJSON.menuItems[index];

      if ( Athena.currentSlideIndex === 0
        || Athena.content.statuses[Athena.currentSlideIndex - 1] === 2 ) {

        if ( typeof Athena.content.statuses !== 'undefined' && Athena.content.statuses[index] === 0 ) {
          Athena.content.updateStatus ( index, 1 );
        }

        Athena.on = 'content';

        if ( undefined !== Athena.currentSlideObj.heading
          && null !== Athena.currentSlideObj.heading ) {
          Athena.updateHeading ( Athena.currentSlideObj.heading, true );
        }
        else {
          $('#heading').css ( 'opacity', 0 );
        }

        if ( undefined !== Athena.currentSlideObj.subHeading
          && null !== Athena.currentSlideObj.subHeading ) {
          Athena.updateSubHeading ( Athena.currentSlideObj.subHeading, true );
        }
        else {
          $('#sub-heading').css ( 'opacity', 0 );
        }

        // - IF THERE'S A VIDEO
        if ( Athena.content.slideVideo !== null ) {
          // - SHOW LOADING SPINNER
          TweenMax.to ( '#loading-spinner', 0.3, {
            autoAlpha: 1,
          });
        }

        Athena.content.onClose = function() {
          // - THIS CAN BE OVERWRITTEN IN THE SLIDE'S INDEX.HTML FILE

          // - PAUSE ANY VIDEOS IN THE SLIDE
          if ( Athena.content.slideVideo !== null ) {
            Athena.content.slideVideo.pause();
          }

          // - RESET IT TO NULL
          Athena.content.onClose = null;
        };

        var ajaxObj = {
          dataType: 'html',

          success: function ( html ) {
            $('.athena-content').html ( html );

            TweenMax.to ( $('#athena-menu'), 0.5, {"autoAlpha" : 0, onComplete: function() {
              $('#athena-menu').hide();
              $('#athena-content').show();

              TweenMax.to ( $('#athena-content'), 0.5, {"autoAlpha" : 1} );
            }} );

            // - SET UP ON PLAY TO REMOVE LOADING SPINNER
            if ( Athena.content.slideVideo !== null ) {
              Athena.content.slideVideo.onplay = function() {
                TweenMax.to ( '#loading-spinner', 0.3, {
                  autoAlpha: 0,
                });
              };

              // - SHOW THE FULL SCREEN BUTTON
              // console.log('full screen');
              $('#full-screen-btn').removeClass ( 'dn' );
            }
            else {
              // - HIDE THE FULL SCREEN BUTTON
              // console.log('no full screen');
              $('#full-screen-btn').addClass ( 'dn' );
            }

            Athena.nav.positionToolTips();

            Athena.nav.enableNext();
          },
        };

        //////////////////////////////////
        /////////// IMPORTANT ////////////
        //////////////////////////////////

        switch ( Athena.currentSlideObj.type ) {
          case 'custom-slide':
            ajaxObj.url = Athena.currentSlideObj.url;
            Athena.nav.showButtons ( ['back', 'replay', 'next'] );
            break;
          case 'assessment':

            if ( Athena.currentSlideObj.assessmentID === 'passedID' ) {
              Athena.currentSlideObj.assessmentID = Assessment.player.assessmentID;
            }

            ajaxObj.url  = Athena.assessmentURL;
            ajaxObj.type = 'post';
            if( typeof Athena.questionID !== 'undefined' ) {
              ajaxObj.data = {
                assessmentID : Athena.currentSlideObj.assessmentID,
                questionID : Athena.questionID,
                playall : Athena.playall,
                saveAssessmentAttemptFuncName : 'Athena.content.saveAssessmentAttempt',
              };
            }
            else {
              ajaxObj.data = {
                assessmentID : Athena.currentSlideObj.assessmentID,
                saveAssessmentAttemptFuncName : 'Athena.content.saveAssessmentAttempt',
              };
            }

            Athena.nav.hideButtons ( ['back', 'replay', 'next', 'finish'] );
            break;
          default:
            ajaxObj.url = Athena.currentSlideObj.url;
            break;
        }

        // - SWITCH TO THIS TO BE ABLE TO ACCESS HELPERS DIRECT FROM ELEARNING SLIDES
        // - NEED TO GO THROUGH ELEARNING AND REMOVE /content/ FROM EACH MENU ITEM
        // switch ( Athena.currentSlideObj.type ) {
        //   case 'custom-slide':
        //     ajaxObj.url = '/AJAX-athena/loadSlide';
        //     ajaxObj.type = 'post';
        //     ajaxObj.data = {
        //       url : Athena.currentSlideObj.url,
        //     };
        //     Athena.nav.showButtons ( ['back', 'replay', 'next'] );
        //     break;
        //   case 'assessment':
        //     ajaxObj.url  = '/AJAX-athena/loadSlide';
        //     ajaxObj.type = 'post';
        //     ajaxObj.data = {
        //       url : Athena.assessmentURL,
        //       assessmentID : Athena.currentSlideObj.assessmentID,
        //       saveAssessmentAttemptFuncName : 'Athena.content.saveAssessmentAttempt',
        //     };
        //     Athena.nav.hideButtons ( ['back', 'replay', 'next', 'finish'] );
        //     break;
        //   default:
        //     ajaxObj.url = Athena.currentSlideObj.url;
        //     break;
        // }

        //////////////////////////////////
        /////////// IMPORTANT ////////////
        //////////////////////////////////
        // console.log(ajaxObj);
        
        $.ajax(ajaxObj);
      }
      else {
        main.flashData.show ( 'All previous sections must be completed before you can view this section.', 'negative', 4 );
      }
    },

    saveAssessmentAttempt : function ( onPassCallback, onFailCallback ) {
      var sendData = {
        assessmentID : Assessment.player.assessmentID,
        percentageScore : Assessment.player.percentageScore,
        status : Assessment.player.status,
      };

      $.ajax({
        url: '/AJAX-assessment/saveAttempt',
        data: sendData,
        type: 'post',
        dataType: 'text',
        success: function ( returnedData ) {
          //console.log('saveAssessmentAttempt:returnedData', returnedData);
        },
      });

      if ( Assessment.player.status === 'P' && Athena.userCourse.id !== undefined ) {
        Athena.content.updateStatus ( Athena.currentSlideIndex, 2, false );

        var sendData = {
          userCourseID : Athena.userCourse.id,
          status       : 'P',
        };

        $.ajax({
          url: '/AJAX-athena/setPassed',
          type: 'post',
          data: sendData,
          dataType: 'json',
          success: function ( returnedData ) {
            // console.log('setPassed:'+returnedData);
            if ( returnedData.success ) {
              ITR.refreshCourseInfo ( returnedData.userCourse );
            }
          },
        });
      }
    },

    setPassed : function ( onPassCallback ) {
      if ( Athena.userCourse.id !== undefined ) {
        Athena.content.updateStatus ( Athena.currentSlideIndex, 2, false );

        var sendData = {
          userCourseID : Athena.userCourse.id,
          status       : 'P',
        };

        $.ajax({
          url: '/AJAX-athena/setPassed',
          type: 'post',
          data: sendData,
          dataType: 'json',
          success: function ( returnedData ) {
            // console.log(returnedData);
            if ( returnedData.success ) {
              onPassCallback();
            }
          },
        });
      }
    },

    updateStatus : function ( slideIndex, newValue, _showClickNext ) {
      showClickNext = true;

      if ( _showClickNext !== undefined ) {
        showClickNext = _showClickNext;
      }

      currStatus = Athena.userCourse.suspend_data.content_statuses[slideIndex] = newValue;

      if ( newValue === 2 && showClickNext ) {
        Athena.clickNextToContinue.show();
      }

      if ( Athena.userCourse.id !== undefined ) {
        Athena.content.saveStatuses();
      }
    },

    saveStatuses : function() {
      var sendData = {
        userCourseID : Athena.userCourse.id,
        suspendData : JSON.stringify({
          content_statuses : Athena.content.statuses
        }),
        progressPerc : Athena.calculateProgress(),
      };

      $.ajax({
        url: '/AJAX-athena/saveStatuses',
        data: sendData,
        type: 'post',
        dataType: 'json',
        success: function ( returnedData ) {
          if ( returnedData.success ) {
            Athena.menu.renderStatuses();
            ITR.refreshCourseInfo ( returnedData.userCourse );
          }
        }
      });
    },

    addPlayBtnIfMobile : function ( startSlide ) {
      if ( !Modernizr.videoautoplay ) {
        Athena.currentSlideObj.startSlide = startSlide;

        $('.athena-content').append (
          $('<a></a>')
            // .addClass ( 'mobile-video-play-btn' )
            .on ( 'click', function ( evt ) {
              Athena.currentSlideObj.startSlide();
              TweenMax.to ( '.mobile-video-play-btn', 0.5, {opacity:0} );
            })
        );
      }
    },

    toggleFullScreen : function() {
      var elem = document.getElementById("slide-video");
      // console.log(elem);
      // - IN SOME BROWSERS THE CONTROLS COME ON WHEN GOING INTO FULL SCREEN, OTHERS DON'T SO WE'LL SET IT ON HERE.
      if ( elem.hasAttribute('controls') ) {
        elem.setAttribute ( 'controls', '' );
      }

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
      }
    },
  },

  menu : {

    menuItems : null,

    show : function ( _delay ) {
      var delay = ( _delay === undefined ) ? 0 : _delay;

      TweenMax.to ( $('#athena-menu'), 0.5, {autoAlpha : 1, delay : delay, onStart: function() {
        $('#athena-menu').show();
      }} );

      Athena.on = 'menu';
    },

    hide : function ( _delay ) {
      var delay = ( _delay === undefined ) ? 0 : _delay;

      TweenMax.to ( $('#athena-menu'), 0.5, {autoAlpha : 0, delay : delay, onComplete: function() {
        $('#athena-menu').hide();
      }} );
    },

    menuItemStartBtn_click : function ( evt ) {
      var slideIndex = $(this).parent().attr('data-index');

      if ( DEBUG.on ) {
        JL().info('menuItemStartBtn_click: ' + $(this).parent().attr('data-index'));
      }

      if ( Athena.clickNextToContinue.showing ) {
        Athena.clickNextToContinue.hide();
      }

      Athena.content.load ( slideIndex );
    },

    menuItemStartBtn_mouseover : function ( evt ) {
      $(this).addClass ( 'mouse-over' );
    },

    menuItemStartBtn_mouseout : function ( evt ) {
      $(this).removeClass ( 'mouse-over' );
    },

    getMenuItemStatus : function ( menuItemIndex ) {
      var symbolClass;
      if(typeof Athena.userCourse != 'undefined' && Athena.userCourse != null)
      {
      var content_statuses = Athena.userCourse.suspend_data.content_statuses;

      switch ( content_statuses[menuItemIndex] ) {
        case 0:
          symbolClass = 'fa-lock';
          break;
        case 1:
          symbolClass = 'fa-ellipsis-h';
          break;
        case 2:
          symbolClass = 'fa-check';
          break;
        default:
          symbolClass = 'fa-lock';
          break;
      }

      if ( menuItemIndex === 0 ) {
        if ( content_statuses[menuItemIndex] === 0
             && content_statuses[menuItemIndex + 1] === 0 ) {
          symbolClass = 'fa-hand-point-up';
        }
      }
      else {
        if ( content_statuses[menuItemIndex - 1] === 2 ) {
          if ( content_statuses[menuItemIndex] === 0 ) {
            symbolClass = 'fa-hand-point-up';
          }
          else if ( content_statuses[menuItemIndex] === 1 ) {
            symbolClass = 'fa-ellipsis-h';
          }
        }
      }
      }
      else{
        symbolClass = 'fa-check';
      }

      return symbolClass;
    },

    renderStatuses : function() {
      $('.athena-menu .menu-item').each ( function ( index, elem ) {
        // - REMOVE SYMBOL CLASSES
        $(elem).find('.athena-menu-icon-symbol')
          .removeClass ( 'fa-lock' )
          .removeClass ( 'fa-ellipsis-h' )
          .removeClass ( 'fa-hand-point-up-pointer-o' )
          .removeClass ( 'fa-check' );

          $(elem).find('.athena-menu-icon-symbol')
            .addClass ( Athena.menu.getMenuItemStatus ( index ) );

      });
    },

    menuItemMaxWidth : 450,

    render : function ( unitJSON ) {
      Athena.menu.menuItems = unitJSON.menuItems;

      $('#athena-menu').append (
			  $('<div></div>')
          .attr ( 'class', 'menu-list-container' )
          .append(
            $('<ul></ul>')
            	.attr ( 'id', 'menu-list' )
				  )
			);

			for ( var i = 0; i < Athena.menu.menuItems.length; i++ ) {
				//console.log(i, Athena.menu.menuItems[i]);

				$('#menu-list')
				  .append (
					 $('<li></li>')
						.addClass ( 'menu-item' )
						.attr ( 'data-index', i )
						.append (
						  $('<div></div>')
							  .addClass ( 'menu-item-text-bar' )
                .append (
                  $('<span></span>')
                    .addClass ( 'menu-item-text' )
                    .text ( Athena.menu.menuItems[i].text )
                    .attr ( 'title', Athena.menu.menuItems[i].text )
                )
                .append (
                  $('<span></span>')
                    .addClass ( 'athena-menu-icon' )// fa-stack
                    // .append (
                    //   $('<i></i>')
                    //     .addClass ( 'fa fa-circle-o fa-stack-2x' )
                    // )
                    .append (
                      $('<i></i>')
                        .addClass ( 'athena-menu-icon-symbol fa ' + Athena.menu.getMenuItemStatus(i) )// fa-stack-1x
                    )
                )

						 )
						 .append (
							$('<div></div>')
							  .addClass ( 'menu-item-start-btn' )
							  .on ( 'click', Athena.menu.menuItemStartBtn_click )
							  .on ( 'mouseover', Athena.menu.menuItemStartBtn_mouseover )
							  .on ( 'mouseout', Athena.menu.menuItemStartBtn_mouseout )
						 )
					);
			}

      $('.menu-item-text').each ( function ( index, elem ) {
        // - IF THE MENU ITEM IS OVER A CERTAIN LENGTH Athena.menu.menuItemMaxWidth
        if ( $(elem).width() >= Athena.menu.menuItemMaxWidth ) {
          // console.log('$(elem).width() >= Athena.menu.menuItemMaxWidth', $(elem).width(), Athena.menu.menuItemMaxWidth);
          // - REDUCE THE TEXT BY ONE CHAR AT A TIME UNTIL IT'S SMALL ENOUGH TO FIT IN THE MENU
          while ( $(elem).width() > Athena.menu.menuItemMaxWidth ) {
            // console.log($(elem).text().substr ( 0, $(elem).text().length - 1 ));
            $(elem).text ( $(elem).text().substr ( 0, $(elem).text().length - 1 ) );
          }

          // - ADD AN ELLIPSIS
          var abbrText = $(elem).text() + '...';
          $(elem)
            .text ( abbrText )
            .data ( 'abbr-text', abbrText );

          // - LET'S ADD A HOVER EVENT TO RESTORE IT TO IT'S FULL LENGTH AND HIGHLIGHT IT
          $(elem).addClass ( 'menu-item-ellipsis-highlight' );

          $(elem)
            .off ( 'mouseover' )
            .on  ( 'mouseover', function ( evt ) {
              $(this).text ( $(this).attr ( 'title' ) );
            })
            .off ( 'mouseout' )
            .on  ( 'mouseout', function ( evt ) {
              $(this).text ( $(this).data ( 'abbr-text' ) );
            });
        }
      });

      if ( unitJSON.menuItems.length === 1 && unitJSON.menuItems[0].type === 'assessment' ) {
        $('.menu-item-start-btn').trigger('click');
        Assessment.player.timer.start();
      }
    },

  },

  clickNextToContinue : {
    showing : false,

    customShow : function(){},
    customHide : function(){},

    show : function ( _delay ) {
      var delay = _delay || 0;
      Athena.clickNextToContinue.showing = true;
      Athena.clickNextToContinue.customShow ( delay );
    },

    hide : function ( _delay ) {
      var delay = _delay || 0;
      Athena.clickNextToContinue.showing = false;
      Athena.clickNextToContinue.customHide ( delay );
    },
  },

  slide : {
    init : function ( slide, tl, narration ) {
      Athena.narration.register ( narration, true );// - must be done first
      Athena.timeline.register ( tl );
      Athena.timeline.init ( slide );
      $('#content-begin').css ( 'display', 'block' );
    },

    start : function() {},

    showTitle : function ( callback, _delay ) {
      var delay = _delay || 1;

      TweenMax.from        ( $('#slide-title'), 2, {opacity:0} );
      TweenMax.to          ( $('#slide-title'), 2, {opacity:0, delay: delay} );

      if ( Athena.on === 'content' ) {
        TweenMax.delayedCall ( delay + 2, callback );
      }
    },
  },

  narration : {
    currNarration : null,

    register : function ( narrationElem, _play ) {
      var play = ( _play === undefined ) ? false : _play;
      Athena.narration.currNarration = narrationElem;

      if ( play ) {
        Athena.narration.currNarration.play();
      }
    },
  },

  timeline : {
    currTimeline : null,
    playing      : false,
    currTime     : 0,
    duration     : null,
    mdX          : null,
    mdY          : null,
    lowerX       : 0,
    upperX       : null,
    timeoutID    : null,

    getPlayheadPosition : function ( progress ) {
      $('#scrub-grab').css ( 'left', ( progress * Athena.timeline.upperX ) + 'px' );
    },

    onUpdate : function() {
      // console.log(Athena.timeline.currTimeline.progress(), Athena.timeline.currTimeline.totalDuration());
      Athena.timeline.currTime = Athena.timeline.currTimeline.progress() *
                                 Athena.timeline.currTimeline.totalDuration();

      Athena.timeline.getPlayheadPosition ( Athena.timeline.currTimeline.progress() );

      Athena.timeline.updateTimeDisplay();
    },

    updateTimeDisplay : function() {
      $('#curr-time').text (
        time.format ( Athena.timeline.currTime )
      );
      $('#total-time').text (
        time.format ( Athena.timeline.currTimeline.totalDuration() )
      );
    },

    play : function() {
      Athena.timeline.currTimeline.resume();
      Athena.timeline.timeoutID = setInterval ( Athena.timeline.onUpdate, 100 );
    },

    pause : function() {
      if ( Athena.currentSlideObj.type === 'custom-slide' ) {
        Athena.timeline.currTimeline.pause();
        clearInterval ( Athena.timeline.timeoutID );
      }
    },

    setPlayPauseBtn : function() {
      if ( Athena.timeline.playing ) {
        $('#play-pause-btn').find ( 'i' )
          .removeClass ( 'fa-play' )
          .addClass    ( 'fa-pause' );
      }
      else {
        $('#play-pause-btn').find ( 'i' )
          .removeClass ( 'fa-pause' )
          .addClass    ( 'fa-play' );
      }
    },

    resetPlayhead : function() {
      $('#scrub-grab').css ( 'left', 0 );
    },

    register : function ( timeline ) {

      Athena.timeline.currTimeline = timeline;
      Athena.timeline.upperX       = $('#scrub-bar').width() - $('#scrub-grab').width();

      Athena.timeline.play();
      Athena.timeline.playing = true;

      Athena.refreshWindowFocusEvents();

      $('#total-time').text (
        time.format ( Athena.timeline.currTimeline.totalDuration() )
      );

      // - THIS ADDS A TWEEN TO THE TIMELINE IF THE AUDIO IS LONGER THAN THE TWEEN TIMELINE
      // - DON'T REACTIVATE THIS IT SEEMS THIS WAS CAUSING THE ISSUE WITH THE DURATION BEING INFINITY
      // Athena.timeline.alignTimelineWithAudio();

      $('#play-pause-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          if ( Athena.timeline.playing ) {
            Athena.timeline.playing = false;
            Athena.timeline.pause();
            Athena.narration.currNarration.pause();
          }
          else {
            Athena.timeline.playing = true;
            Athena.timeline.play();
            Athena.narration.currNarration.play();
          }

          Athena.timeline.setPlayPauseBtn();

        });

      var scrubGrabMM = function ( evt ) {
        if ( evt.clientX - Athena.timeline.mdX < Athena.timeline.lowerX ) {
          $('#scrub-grab').css ( 'left', Athena.timeline.lowerX );
        }
        else if ( evt.clientX - Athena.timeline.mdX > Athena.timeline.upperX ) {
          $('#scrub-grab').css ( 'left', Athena.timeline.upperX );
        }
        else {
          $('#scrub-grab').css ( 'left', evt.clientX - Athena.timeline.mdX );
        }

        var position = $('#scrub-grab').css ( 'left' );
        var progress = pxValue ( position ) / Athena.timeline.upperX;

        Athena.timeline.currTimeline.progress ( progress );
        Athena.timeline.updateTimeDisplay();
      };

      var scrubGrabMU = function ( evt ) {
        $('body')
          .off ( 'mousemove', scrubGrabMM )
          .off ( 'mouseup', scrubGrabMU )
          .removeClass('no-select');

        Athena.narration.currNarration.currentTime = Athena.timeline.currTime;

        if ( Athena.timeline.playing ) {
          Athena.timeline.play();

          if ( Athena.narration.currNarration.currentTime < Athena.narration.currNarration.duration ) {
            Athena.narration.currNarration.play();
          }

        }
        else {
          Athena.timeline.pause();
          Athena.narration.currNarration.pause();
        }
      };

      $('#scrub-grab')
        .on ( 'mousedown', function ( evt ) {
          $('body').addClass('no-select');

          Athena.timeline.currTimeline.pause();
          Athena.narration.currNarration.pause();

          Athena.timeline.mdX = evt.clientX
                - $('#scrub-grab').offset().left
                + $('#scrub-bar').offset().left;
          Athena.timeline.mdY = evt.clientY
                - $('#scrub-grab').offset().top
                + $('#scrub-bar').offset().top;

          $('body')
            .off ( 'mousemove', scrubGrabMM )
            .on  ( 'mousemove', scrubGrabMM );

          $('body')
            .off ( 'mouseup', scrubGrabMU )
            .on  ( 'mouseup', scrubGrabMU );
        });
    },

    alignTimelineWithAudio : function() {
      setTimeout ( function () {
        // console.log('n',Athena.narration.currNarration.duration);
        Athena.timeline.currTimeline.add (
          TweenMax.delayedCall ( 0.5, function() {
          }), Athena.narration.currNarration.duration
        );
      }, 200 );
    },

    init : function ( slide ) {

    },
  },

  windowOnFocus : function() {
    // setTimeout(function(){
    //   Athena.timeline.playing = true;
    //   Athena.timeline.play();
    //
    //   Athena.timeline.setPlayPauseBtn();
    //
    //   Athena.narration.currNarration.play();
    // }, 300);
  },

  windowOnBlur : function() {
    Athena.timeline.playing = false;
    Athena.timeline.pause();

    Athena.timeline.setPlayPauseBtn();

    Athena.narration.currNarration.pause();
  },

  refreshWindowFocusEvents : function() {
    // check if browser window has focus
    var notIE = (document.documentMode === undefined),
        isChromium = window.chrome;

    if (notIE && !isChromium) {

      // checks for Firefox and other  NON IE Chrome versions
      $(window)
        .off ( 'focusin' )
        .on  ( 'focusin', Athena.windowOnFocus )
        .off ( 'focusout' )
        .on  ( 'focusout', Athena.windowOnBlur );
    }
    else {

      // checks for IE and Chromium versions
      if (window.addEventListener) {

        // bind focus event
        window.removeEventListener ( 'focus', Athena.windowOnFocus );
        window.addEventListener ( 'focus', Athena.windowOnFocus, false);

        // bind blur event
        window.removeEventListener ( 'blur', Athena.windowOnBlur );
        window.addEventListener ( 'blur', Athena.windowOnBlur, false);

      }
      else {

        // bind focus event
        window.attachEvent ( "focus", Athena.windowOnFocus );

        // bind focus event
        window.attachEvent ( "blur", Athena.windowOnBlur );
      }
    }
  },

};
