// JavaScript Document

var is_admin = true; // - CHANGE TO DYNAMIC

var repos = {};
var user = {};

repos.absoluteRoot = '/uploads/' + main.getSub() + '/';
repos.root = '../../uploads/' + main.getSub();
repos.selectedFiles = {};

/**
 * THE FOLLOWING FOLDER NAMES REPRESENT THEIR RESPECTIVE FOLDERS
 */
repos.topLevelDirMap = {
	'global' : {
		name : 'Global Docs',
		updateBreadcrumbFunc : repos.updateBreadcrumb_global,
	},
	'inst' : {
		name : 'Organisational Docs',
		updateBreadcrumbFunc : repos.updateBreadcrumb_inst,
	},
	'site' : {
		name : 'Site Docs',
		updateBreadcrumbFunc : repos.updateBreadcrumb_site,
	},
	'personal' : {
		name : 'My Documents',
		updateBreadcrumbFunc : repos.updateBreadcrumb_personal,
	},
	'ilt-cpd' : {
		name : 'Instructor-led Training',
		updateBreadcrumbFunc : repos.updateBreadcrumb_ilt_cpd,
	},
	'ilt-course' : {
		name : 'Instructor-led Training',
		updateBreadcrumbFunc : repos.updateBreadcrumb_ilt_course,
	},
	'oc-standard' : {
		name : 'Occupational Competence Standards',
		updateBreadcrumbFunc : repos.updateBreadcrumb_oc_standards,
	},
	'oc-practice-runs-guidance' : {
		name : 'Occupational Competence Guidance',
		updateBreadcrumbFunc : repos.updateBreadcrumb_oc_practice_runs_guidance,
	},
	'oc-practice-runs-user-data' : {
		name : 'Occupational Competence User Data',
		updateBreadcrumbFunc : repos.updateBreadcrumb_oc_practice_runs_user_data,
	},
	'oc-spec-task-guidance' : {
		name : 'Specific Assessment Guidance',
		updateBreadcrumbFunc : repos.updateBreadcrumb_ct_spec_task_guidance,
  },
  'scorm-package-uploads' : {
    name : 'SCORM Packages',
		updateBreadcrumbFunc : repos.updateBreadcrumb_scorm_package_uploads,
  },
};

repos.ext_types = {
	'jpg'	 : {'icon' : 'fa-file-image-o', 'preview' : 'thumb'},
	'jpeg' : {'icon' : 'fa-file-image-o', 'preview' : 'thumb'},
	'png'	 : {'icon' : 'fa-file-image-o', 'preview' : 'thumb'},
	'gif'	 : {'icon' : 'fa-file-image-o', 'preview' : 'thumb'},
	'doc'	 : {'icon' : 'fa-file-word-o', 'preview' : 'blank'},
	'docx' : {'icon' : 'fa-file-word-o', 'preview' : 'blank'},
	'xls'	 : {'icon' : 'fa-file-excel-o', 'preview' : 'blank'},
	'xlsx' : {'icon' : 'fa-file-excel-o', 'preview' : 'blank'},
	'ppt'	 : {'icon' : 'fa-file-powerpoint-o', 'preview' : 'blank'},
	'pptx' : {'icon' : 'fa-file-powerpoint-o', 'preview' : 'blank'},
	'pps'	 : {'icon' : 'fa-file-powerpoint-o', 'preview' : 'blank'},
	'ppsx' : {'icon' : 'fa-file-powerpoint-o', 'preview' : 'blank'},
	'pdf'	 : {'icon' : 'fa-file-pdf-o', 'preview' : 'blank'},
	'htm'	 : {'icon' : 'fa-internet-explorer', 'preview' : 'blank'},
	'html' : {'icon' : 'fa-internet-explorer', 'preview' : 'blank'},
	'zip'  : {'icon' : 'fa-file-archive', 'preview' : 'blank'},
};

repos.get_extension = function ( filename ) {
	var finalDot = filename.lastIndexOf ( '.' );
	var ext      = filename.substr ( finalDot + 1 );

	return ext;
};

repos.access_root = function() {
	repos.in_root = true;

	file_uploader.repos = '';

	repos.retrieveStoredFiles(function() {
		var personal_li, inst_li = '', s, site_li = '';

		$('.drive').empty();

		repos.addFolderToDriveList ( 'global', repos.topLevelDirMap['global'].name );
		repos.addFolderToDriveList ( 'personal/' + user.user_id, repos.topLevelDirMap['personal'].name );
		repos.addFolderToDriveList ( 'inst/', repos.topLevelDirMap['inst'].name );
		repos.addFolderToDriveList ( 'site/', repos.topLevelDirMap['site'].name );


		// // - ORG DOCUMENTS
		// inst_li +=		'<li class="file file_normal_size">';
		// inst_li +=			'<div class="file_icon"><i class="fa fa-folder"></i></div>';
		// inst_li +=			'<div class="file_name">';
		// inst_li +=				'<a href="javascript:repos.access_folder(\'inst/\');">Organisation Documents</a>';
		// inst_li +=			'</div>';
		// inst_li +=		'</li>';
		//
		//
		//
		// $('.drive').append(inst_li);
		//
		// // - SITE DOCUMENTS
		// for ( var siteID in user.site_ids ) {
		// 	site_li +=		'<li class="file file_normal_size">';
		// 	site_li +=			'<div class="file_icon"><i class="fa fa-folder"></i></div>';
		// 	site_li +=			'<div class="file_name">';
		// 	site_li +=				'<a href="javascript:repos.access_folder(\'site/' + siteID + '\', \'' + str.decode ( user.site_ids[siteID].name ) + '\');">Site Documents: ' + str.decode ( user.site_ids[siteID].name ) + '</a>';
		// 	site_li +=			'</div>';
		// 	site_li +=		'</li>';
		// }
		//
		// $('.drive').append(site_li);
		repos.addFolderToDriveList ( 'ilt-cpd', repos.topLevelDirMap['ilt-cpd'].name );
		repos.addFolderToDriveList ( 'ilt-course', repos.topLevelDirMap['ilt-course'].name );
		repos.addFolderToDriveList ( 'oc-standard', repos.topLevelDirMap['oc-standard'].name );

		repos.update_breadcrumb();

		repos.hide_new_folder_btns();

		// - DENY ABILITY TO UPLOAD TO CURR FOLDER
		$('select[name="dd_document_folder"] option[value="current_folder"]').remove();
		// - FORCE DD TO CHANGE
		$('select[name="dd_document_folder"]').change();
	});
};

repos.addFolderToDriveList = function ( _path, _name ) {
	$('.drive').append (
		'<li class="file file_normal_size">' +
			'<div class="file_icon"><i class="fa fa-folder"></i></div>' +
			'<div class="file_name">' +
				'<a href="javascript:repos.access_folder(\'' + _path + '\', \'' + _name + '\');">' + _name + '</a>' +
			'</div>' +
		'</li>'
	);
};

repos.access_folder = function ( _path, _name, _callback ) {
	var callback;
	if ( _callback === undefined ) {
		callback = function(){};
	}
	else {
		callback = _callback;
	}

	repos.in_root = false;
	// console.log('access_folder');
	// console.log('_path', _path);
	// console.log('_name', _name);
	repos.retrieveStoredFiles ( function() {
		var sendData = {
			path : _path,
		};

		repos.repos = _path.substr(0, _path.indexOf('/'));// i.e. personal
		repos.repos_path = repos.repos + '/';//path from repos onwards i.e. personal/2/

		var ajaxScript = '/repository/AJAX-repository/ajax-access-folder';

		if( _path.split('/')[0] === 'personal' ) {
			repos.repos_path += user.user_id + '/';
			repos.user_id = user.user_id;
			repos.site_id = null;
			repos.inst_id = user.inst_id;

			repos.repos_name_verbose = _name;
		}
		else if( _path.split('/')[0] === 'inst' ) {
			repos.repos_path += user.inst_id + '/';
			repos.user_id = user.user_id;
			repos.site_id = null;
			repos.inst_id = user.inst_id;

			repos.repos_name_verbose = _name;
		}
		else if( _path.split('/')[0] === 'site' ) {
			repos.repos_path += _path.split('/')[1] + '/';
			repos.user_id = user.user_id;
			repos.site_id = Number(_path.split('/')[1]);
			repos.inst_id = user.inst_id;

			repos.repos_name_verbose = _name;
		}
		else if( _path.split('/')[0] === 'global' ) {
			repos.repos_name_verbose = _name;
		}
		else if( _path.split('/')[0] === 'ilt-course' ) {
			repos.repos_path += _path.split('/')[1] + '/';

			repos.repos_name_verbose = _name;

			sendData.folderType = 'ilt-course';
		}
		else if( _path.split('/')[0] === 'oc-standard' ) {
			repos.repos_path += _path.split('/')[1] + '/';

			repos.repos_name_verbose = _name;

			sendData.folderType = 'oc-standard';
		}
		else if( _path.split('/')[0] === 'oc-spec-task-guidance' ) {
			repos.repos_path += _path.split('/')[1] + '/';

			repos.repos_name_verbose = _name;

			sendData.folderType = 'oc-spec-task-guidance';
		}
		else if( _path.split('/')[0] === 'cpd' ) {
			repos.repos_path += _path.split('/')[1] + '/';

			repos.repos_name_verbose = _name;

			sendData.folderType = 'cpd';
		}

		repos.upload_path = repos.root + '/' + repos.repos_path;

		// console.log('///////////////');
		$.ajax({
			url: ajaxScript,
			data: sendData,
			type: 'post',
			dataType: 'text',
			success: function ( returnedData ) {
				// console.log(returnedData);
				returnedData = JSON.parse(returnedData);
				if ( returnedData.success ) {
					repos.update_breadcrumb ( _path );
					repos.populate ( returnedData.files );
					repos.show_new_folder_btns();
				}
				// - IF NOT IN THE DRIVE ROOT ALLOW UPLOAD TO CURR FOLDER
				if( $('select[name="dd_document_folder"] option[value="current_folder"]').length === 0 ) {
					$('select[name="dd_document_folder"]').prepend( '<option value="current_folder">Upload to Current Folder</option>' );
					$('select[name="dd_document_folder"] option[value="current_folder"]').prop('selected', true);
				}
				// - FORCE DD TO CHANGE
				$('select[name="dd_document_folder"]').change();

				callback();
			}
		});
	});
};

repos.access_upload_target = function() {
	repos.curr_location = file_uploader.repos_path
	repos.curr_location_arr = ( repos.curr_location === '' ) ? '' : repos.curr_location.split('/');

	repos.refresh_folder();
};

repos.refresh_folder = function() {
	var name;

	if ( repos.curr_location_arr[0] === 'site' ) {
		name = str.decode ( user.site_ids[repos.curr_location_arr[1]].name );
	}
	else if ( repos.curr_location_arr[0] === 'inst' ) {
		name = str.decode ( user.inst_ids[repos.curr_location_arr[1]].name );
	}

	repos.access_folder( repos.curr_location, name );
};

repos.accessFile = function ( fileName, filePath, fileType, documentID, options ) {
	if ( fileType.search ( /htm|html/i ) !== -1 ) {
		repos.loadHTMLDocument ( fileName, filePath, documentID, options );
	}
	else if ( fileType.search ( /jpg|jpeg|gif|png/i ) !== -1 ) {
		repos.show_image ( fileName, filePath );
	}
	else if ( fileType.search ( /pdf/i ) !== -1 ) {
		repos.loadPDF ( fileName, filePath, documentID, options );
	}
	else if( fileType.search ( /doc|docx|xls|xlsx|ppt|pptx|pps|ppsx/i ) !== -1 ) {
		repos.show_doc ( fileName, filePath );
	}
};

repos.populate = function ( _files ) {

	var f, file_lis = '', permission_to_move;

	repos.files = _files;

	$('.drive').empty();
	if(!jQuery.isEmptyObject(_files)) {
		$.each(_files, function(i, file) {
    		file_lis += repos.addFileFolderToList ( i, file );
		});​
	}
	else {
		file_lis +=	'<li class="file file_normal_size">';
		file_lis +=		'<div class="file_name">Empty Folder</div>';
		file_lis +=	'</li>';
	}

	$('.drive').html(file_lis);

	// - CHECK THE FILES THAT ARE CURRENTLY SELECTED
	$('.drive li.file').each(function ( index, elem ) {
		//console.log($(elem).attr('data-id'));
		//console.log(repos.selectedFiles[$(elem).attr('data-id')]);
		var checkIt = repos.selectedFiles[$(elem).attr('data-id')] !== undefined;
		$(elem).find('input.cb-select-file').prop ( 'checked', checkIt );
	});

	$('.cb-select-file').on ( 'change', function ( evt ) {
		repos.addFileToSelectedFiles (
			$(this).prop ( 'checked' ),
			$(this).parent().attr('data-id'),
			$(this).parent().attr('data-path'),
			$(this).parent().find('.file_name').text(),
			repos.get_extension ( $(this).parent().find('.file_name').text() )
		);

		repos.onFileSelectionChange();

		// - THIS HAS BEEN COMMENTED OUT DUE TO ILEARN-98 TO STOP THE SYSTEM STORING SELECTED FILES
		// repos.addFileToStoredFiles (
		// 	$(this).prop ( 'checked' ),
		// 	$(this).parent().attr('data-id'),
		// 	$(this).parent().attr('data-path'),
		// 	$(this).parent().find('.file_name').text(),
		// 	repos.get_extension ( $(this).parent().find('.file_name').text() )
		// );
		//
		// repos.renderSelectedFiles();
	});

	$('.history_btn').on ( 'click', function ( evt ) {
		repos.show_history ( $(this).parent() );
	});

	$('.delete_btn').on ( 'click', function ( evt ) {
		repos.delete_file ( $(this).parent() );
	});
};

repos.addFileFolderToList = function ( _index, _file ) {
	var name;

	if ( typeof _file.overwriteName === undefined ) {
		name = _file.file_name;
	}
	else {
		name = _file.overwriteName;
	}

	var output = '';
	output +=	'<li data-index="' + _index + '" ' +
	                'data-id="' + _file.id + '" ' +
	                'data-path="' + repos.curr_location + '/' + _file.file_name + '" ' +
	                ' class="file file_normal_size">';
	output += '<input type="checkbox" class="cb-select-file">';

	if(_file.dir)
	  output +=		'<div class="file_icon"><i class="fa fa-folder"></i></div>';
	else
	  output +=		'<div class="file_icon"><i class="fa fa-lg ' + repos.ext_types[_file.ext].icon + '"></i></div>';

	output +=		'<div class="file_name">';

	if ( _file.dir ) {
	  output +=			'<a href="javascript:repos.access_folder(\'' + repos.curr_location + '/' + _file.file_name + '\', \'' + name + '\');">' + name + '</a>';
	}
	else {
	  if ( _file.deleted ) {
	  	output +=			'<span class="file_deleted">' + _file.file_name + ' FILE DELETED</span>';
		}
	  else {
	  	output +=			'<a href="javascript:repos.access_file(' + _index + ');">' + _file.file_name + '</a>';
		}
	}

	output +=	'</div>';
	output +=	'<div class="file_preview"></div>';

	// if(!_files[f].deleted && is_admin)
	// file_lis +=		'<div class="delete-file-btn gen-btn negative-btn btn-bank"><i class="fa fa-trash"></i></div>';
	//
	// if(!_files[f].dir && is_admin)
	// file_lis +=	'<div class="history-btn gen-btn neutral-btn btn-bank"><i class="fa fa-history"></i></div>';

	// - MOVE
	if(_file.perms.can_move)
	;//file_lis +=	'<div class="move_btn gen_btn blue_btn">Move</div>';

	output +=	'</li>';

	return output;
}

repos.addFileToSelectedFiles = function ( checked, documentID, path, filename, ext ) {
	if ( checked ) {
		if ( repos.selectedFiles[documentID] === undefined ) {
			repos.selectedFiles[documentID] = {
				id       : documentID,
				path     : path,
				filename : filename,
				ext      : ext,
			};
		}
	}
	else {
		if ( repos.selectedFiles[documentID] !== undefined ) {
			delete repos.selectedFiles[documentID];
		}
	}

	var sendData = {
		filesJSON : JSON.stringify ( repos.selectedFiles ),
	};

	// $.ajax({
	// 	url:'/repository/AJAX-repository/update-selected-files',
	// 	type: 'post',
	// 	data: sendData,
	// 	dataType: 'json',
	// 	success: function ( returnedData ) {
	// 		//console.log(returnedData);
	// 	}
	// });
};

repos.addFileToStoredFiles = function ( checked, documentID, path, filename, ext ) {

	if ( checked ) {
		if ( repos.selectedFiles[documentID] === undefined ) {
			repos.selectedFiles[documentID] = {
				id       : documentID,
				path     : path,
				filename : filename,
				ext      : ext,
			};
		}
	}
	else {
		if ( repos.selectedFiles[documentID] !== undefined ) {
			delete repos.selectedFiles[documentID];
		}
	}

	var sendData = {
		filesJSON : JSON.stringify ( repos.selectedFiles ),
	};

	$.ajax({
		url:'/repository/AJAX-repository/update-stored-files',
		type: 'post',
		data: sendData,
		dataType: 'json',
		success: function ( returnedData ) {
			//console.log(returnedData);
		}
	});
};

repos.retrieveStoredFiles = function ( callback ) {
	$.ajax({
		url:'/repository/AJAX-repository/retrieve-stored-files',
		dataType: 'json',
		success: function ( returnedData ) {
			repos.selectedFiles = returnedData;
			// repos.renderSelectedFiles();
			repos.checkSelectedFiles();
			callback();
		}
	});
};

repos.retrieveSelectedFiles = function ( callback ) {
	$.ajax({
		url:'/repository/AJAX-repository/retrieve-stored-files',
		dataType: 'json',
		success: function ( returnedData ) {
			repos.selectedFiles = returnedData;
			// repos.renderSelectedFiles();
			repos.checkSelectedFiles();
			callback();
		}
	});
};

repos.checkSelectedFiles = function() {
	// console.log('checkSelectedFiles');
};

repos.onFileSelectionChange = function() {
	// console.log('No function set');
};

repos.renderSelectedFiles = function() {
	var li;

	$('#selected-files ul').empty();

	for ( var file in repos.selectedFiles ) {
		$('#selected-files ul').append(
			$('<li></li>')
			  .addClass ( 'selected-file' )
				.attr ( 'data-id', repos.selectedFiles[file].id )
				.append (
					$('<div></div>')
					  .addClass ( 'file_icon' )
						.append (
							$('<i></i>')
							  .addClass ( 'fa ' + repos.ext_types[repos.selectedFiles[file].ext].icon )
						)
				)
				.append (
					$('<div></div>')
					  .addClass ( 'file_name' )
						.text ( repos.selectedFiles[file].filename )
				)
				.append (
					$('<button></button>')
					  .addClass ( 'remove-file-btn fr' )
						.append (
							$('<i></i>')
							  .addClass ( 'fa fa-remove' )
						)
				)
		);
	}

	$('#selected-files .remove-file-btn').on ( 'click', function ( evt ) {
		repos.removeFileFromSelectedFiles ( $(this).parent().attr('data-id') );
	});
};

repos.removeFileFromSelectedFiles = function ( documentID ) {

	delete repos.selectedFiles[documentID];

	var sendData = {
		filesJSON : JSON.stringify ( repos.selectedFiles ),
	};

	$.ajax({
		url:'/repository/AJAX-repository/update-selected-files',
		type: 'post',
		data: sendData,
		dataType: 'json',
		success: function ( returnedData ) {
			//console.log(returnedData);
		}
	});

	repos.renderSelectedFiles();
};

repos.breadcrumbLock = -1;

repos.update_breadcrumb = function(_location) {
	// console.log('repos.update_breadcrumb', _location);
	var breadcrumb_lis, f, folder_concat, location, name;

	if(_location === undefined) {
		location = '';
	}
	else {
		location = _location;
	}

	// - RTRIM TRAILING SLASH
	location = (location[location.length - 1] === '/')
	  ? location.substr(0, location.length - 1) : location;

	repos.curr_location = location;
	repos.curr_location_arr	= (location === '') ? [] : location.split('/');

	folder_concat = repos.curr_location_arr[0] + '/';

	$('.breadcrumbs').empty();

	// console.log('repos.breadcrumbLock', repos.breadcrumbLock);
	if ( repos.breadcrumbLock === -1 ) {
		$('.breadcrumbs').append (
			$('<li></li>')
			  .append (
					$('<a></a>')
					  .text ( 'My Drive' )
					  .on ( 'click', function ( evt ) {
							repos.access_root();
						})
				)
		);
	}

	if ( repos.curr_location_arr.length > 0 ) {
		if ( repos.breadcrumbLock <= 0 ) {

			name = repos.topLevelDirMap[repos.curr_location_arr[0]].name;
			// console.log(512, name);
			$('.breadcrumbs').append (
				$('<li></li>')
				  .append (
						$('<a></a>')
						  .text ( name )
						  .on ( 'click', function ( evt ) {
								repos.access_folder ( folder_concat, name );
							})
					)
			);
		}

		for ( f = 1; f < repos.curr_location_arr.length; f++ ) {
			folder_concat += repos.curr_location_arr[f];

			$('.breadcrumbs').append (
				$('<li></li>')
				  .append (
						$('<a></a>')
						  .text ( repos.repos_name_verbose )
						  .on ( 'click', function ( evt ) {
								repos.access_folder ( folder_concat, repos.repos_name_verbose );
							})
					)
			);

			f++; continue;
		}
	}
};

repos.setBreadcrumbLock = function ( _numberOfCrumbs ) {
	repos.breadcrumbLock = _numberOfCrumbs;
};

repos.hideBreadcrumb = function() {
	$('.breadcrumbs').hide();
};

repos.hideUploadDestSelect = function() {
	$('.document_folder').hide();
};

repos.hide_new_folder_btns = function() {
	$('.new_folder_btn').hide();
};

repos.show_new_folder_btns = function() {
	$('.new_folder_btn').show();
};

repos.show_image = function(_path, _image_obj) {
	var content, new_width, new_height;
	var screen_ratio = window.innerWidth / window.innerHeight;
	var max_percentage_w = Math.min(_image_obj.dimensions[0] / window.innerWidth, 0.8);
	var max_percentage_h = Math.min(_image_obj.dimensions[1] / window.innerHeight, 0.8);
	var max_w = Math.round(window.innerWidth * max_percentage_w);
	var max_h = Math.round(window.innerHeight * max_percentage_h);

	//console.log('img width', _image_obj.dimensions[0]);
	//console.log('img height', _image_obj.dimensions[1]);
	//console.log('screen width', window.innerWidth);
	//console.log('screen height', window.innerHeight);

	if(_image_obj.ratio > screen_ratio) {
		new_width	= max_w;
		new_height	= _image_obj.dimensions[1] * (max_w / _image_obj.dimensions[0]);
	}
	else if(_image_obj.ratio < screen_ratio) {
		new_width	= _image_obj.dimensions[0] * (max_h / _image_obj.dimensions[1]);
		new_height	= max_h;
	}
	else {
		new_width = max_w;
		new_width = max_h;
	}

	content  = '<img class="image" src="' + repos.root + '/' + _path + '/' + _image_obj.file_name + '">';

	popup._make_popup('image_viewer', 'Image: ' + _image_obj.file_name, content, {
		width			: new_width + 'px',
		height			: new_height + 'px',
		buttons_arr	: [],
		close_btn		: true,
		show			: true
	});
};

repos.loadPDF = function ( fileName, filePath, documentID, options ) {
	var content  =	'<object ';
			content += 		'id="pdf_object" ';
			content += 		'data="' + filePath + '" ';
			content += 		'type="application/pdf" ';
			content += 		'width="100%" ';
			content += 		'height="100%" ';
			content +=		'style="position:relative; z-index:0;" ';
			content +=		'onLoad="javascript:repos.pdf_loaded();">';
			content +=		'<p>If you can\'t see the document, click <a href="' + filePath + '">here</a> to download it.</p>';
			content +=		'<param value="transparent" name="wmode"/>';
			content +=	'</object>';

	popup.generatePopup({
		title: 'Viewing: ' + fileName,
		method: 'literal',
		content: content,
		maxWidth: 1000,
		maxHeight: 700,
		success: function() {
			if ( options.confirmRead ) {
				popup.footer(
					[
						$('<button>')
						  .attr ( 'id', 'confirm-read-doc-' + documentID + '-btn' )
							.attr ( 'data-document-id', documentID )
							.attr ( 'class', 'positive-btn gen-btn p-- mr--' )
							.text ( 'Click to confirm you have read and understood this document' ),
					],
					function ( documentID ) {
						options.confirmReadCallback ( documentID );
						options.callback ( documentID );
					},
					50
				);
			}
		},
	});
};

repos.show_doc = function(_file_obj) {
	content  =	'<iframe id="doc_object" src="http://www.koloko.co.uk/portal/upload/files/documents/' + repos.curr_location + '/' + _file_obj.file_name + '" width="' + (Math.round(window.innerWidth * 0.8) - 20) + '" height="' + (Math.round(window.innerHeight * 0.8) - 20) + '">';
	content +=	'</iframe>';

	popup._make_popup('word_viewer', 'Word Document: ' + _file_obj.file_name, content, {
		width		  	: Math.round(window.innerWidth * 0.8) + 'px',
		height			: Math.round(window.innerHeight * 0.8) + 'px',
		buttons_arr	: [],
		close_btn		: true,
		show			  : true
	});
};

repos.loadHTMLDocument = function ( fileName, filePath, documentID, options ) {
	popup.generatePopup({
		title: 'Viewing: ' + fileName,
		method: 'ajax',
		ajaxUrl: filePath,
		maxWidth: 1000,
		maxHeight: 700,
		success: function() {
			if ( options.confirmRead ) {
				popup.footer(
					[
						$('<button>')
						  .attr ( 'id', 'confirm-read-doc-' + documentID + '-btn' )
							.attr ( 'data-document-id', documentID )
							.attr ( 'class', 'positive-btn gen-btn p-- mr--' )
							.text ( 'Click to confirm you have read and understood this document' ),
					],
					function ( documentID ) {
						options.confirmReadCallback ( documentID );
						options.callback ( documentID );
					},
					50
				);
			}
		},
	});
};

repos.delete_file = function(_li) {
	var path = _li.attr('data-path');
	var data_str = '&path=' + path;

	$.ajax({
		url:'repository/ajax-delete-file',
		data:data_str,
		type:'post',
		dataType:'text',
		success:function(data) {
			//console.log(data);
		}
	});
	//console.log(path);
};

repos.deleteDocument = function ( documentID, hardDelete, callback ) {
	var sendData = {
		documentID : documentID,
		hardDelete : hardDelete,
	};

	$.ajax({
		url: '/repository/AJAX-repository/delete-document',
		type: 'post',
		data: sendData,
		dataType: 'json',
		success: function ( returnedData ) {
			// console.log(returnedData);

			if ( callback !== undefined ) {
				callback();
			}
		},
	});
};

repos.show_history = function(_li) {
	var path = _li.attr('data-path');
	var data_str = '&path=' + path;
	var ghost_path = repos.get_ghost_path ( path );// - THIS REPLACES personal/2/ with personal/

	$.ajax({
		url:'repository/ajax-get_history',
		data:data_str,
		type:'post',
		dataType:'json',
		success:function(data) {
			if( $('#file_history_popup').length === 0 ) {
				$.ajax({
					url:view_path + 'repository/popups/file_history.html',
					dataType:'html',
					success:function(popup_html)
					{
						$('#popups').append ( popup_html );

						repos.show_history_popup ( data.mJuncUsersReadDocuments, ghost_path );
					}
				});
			}
			else {
				repos.show_history_popup ( data.mJuncUsersReadDocuments, ghost_path );
			}
		}
	});
};

repos.show_history_popup = function(_data, _ghost_path) {
	var i, lis = '';

	$('#file_history_popup .popup_close_btn').bind('click', function(evt) {
		popup.close_popup($(this).parent().parent());
	});

	$('#file_history_popup .popup_title').html('File History: ' + _ghost_path);

	if(_data.length === 0) {
		$('#read_by_the_following').html("This file has not been read by any users");
	}
	else {
		$('#read_by_the_following').html("This file has been read by the following users:");

		for(i = 0; i < _data.length; i++)
		{
			if(_data[i].fld_read === '1')
				lis += '<li>' + _data[i].mTblUsers[0].fld_first_name + ' ' + _data[i].mTblUsers[0].fld_surname + '</li>';
		}
	}

	$('#file_history_popup #read_by').html(lis);

	popup.active_popup = $('#file_history_popup');

	$('#popups').show();
	popup.active_popup.show();
	popup.centre_popup(popup.active_popup, 1000);
};

repos.get_ghost_path = function(_path) {
	var seg_1, seg_2, ghost_path;
	//console.log('_path.substr',_path.substr(0, 18));
	if(_path.substr(0, 12) === 'personal') {
		seg_1 = 'personal';
		seg_2 = _path.substr(_path.indexOf('/', 13));
		ghost_path = seg_1 + seg_2;
	}
	else if(_path.substr(0, 14) === 'site') {
		seg_1 = 'site';
		seg_2 = _path.substr(_path.indexOf('/', 15));
		ghost_path = seg_1 + seg_2;
	}
	else if(_path.substr(0, 22) === 'inst') {
		seg_1 = 'inst';
		seg_2 = _path.substr(_path.indexOf('/', 23));
		ghost_path = seg_1 + seg_2;
	}
	else if(_path.substr(0, 16) === 'global') {
		seg_1 = 'global';
		seg_2 = _path.substr(_path.indexOf('/', 17));
		ghost_path = seg_1 + seg_2;
	}

	return ghost_path;
};

repos.new_folder = function() {
	if ( repos.curr_location === '' ) {
		popup._make_popup('cannot_create_folder', 'Permission Denied', 'Cannot create folder in drive root.', {
			width			: '250px',
			height			: 'auto',
			buttons_arr	: [],
			close_btn		: true,
			show			: true
		});
	}
	else {
		var new_folder_content = '<input type="text" name="tf_folder_name">';

		popup._make_popup('enter_folder_name', 'Enter Folder Name', new_folder_content, {
			width			: '250px',
			height			: 'auto',
			buttons_arr	: [{href : 'javascript:repos.createFolder();', name : 'Create Folder', colour : 'green'}],
			close_btn		: true,
			show			: true
		});
	}
};

repos.createFolder = function() {
	var data = new FormData();
	data.append('upload_path', repos.upload_path);
	data.append('repos_path', repos.repos_path);
	data.append('repos', repos.repos);
	data.append('folder_name', $('input[name="tf_folder_name"]').val());
	data.append('user_id', file_uploader.user_id);
	data.append('site_id', file_uploader.site_id);
	data.append('inst_id', file_uploader.inst_id);

	$.ajax({
		url: '/repository/ajax-create-folder',
		data: data,
		type: 'post',
		dataType: 'text',
		contentType: false,
		processData: false,
		success: function ( returnedData ) {
			// console.log('repos.createFolder - return from AJAX');
			// console.log(returnedData);
			// console.log('//////////////');
		}
	});
};

repos.autoCreatePath = function ( path ) {
	/**
	 * CREATES THE PATH PASSED FROM UPLOADS ROOT
	 * SEQUENTIALLY GOES THROUGH EACH FOLDER, IF IT EXISTS IT DRILLS DOWN,
	 * IF NOT CREATES AND THEN DRILLS DOWN
	 */

	var sendData = {
		path: path,
	};

	$.ajax({
		url: '/AJAX-repository/auto-create-path',
		data: sendData,
		type: 'post',
		dataType: 'text',
		success: function ( returnedData )	{
			//console.log(data);
		}
	});
};

repos.getFiles = function ( docIDs ) {

};
