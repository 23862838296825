var MyAccount = {

  init : function() {
    MyAccount.shareData.init();
  },

  setProfilePhoto : function ( userID, documentID ) {
    var sendData = {
      userID : userID,
      documentID : documentID,
    };

    $.ajax({
      url: '/admin/AJAX-users/set-profile-photo',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
      },
    });
  },

  displayProfilePhoto : function ( userID ) {
    var ts = Date.now();

    var sendData = {
      userID : userID,
    };

    $.ajax({
      url: '/admin/AJAX-users/get-profile-photo',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        $('#profile-photo')
          .css ( 'background-image', 'url(\'/uploads/' + main.getSub() + '/' + returnedData.document.path + '?dt=' + ts + '\')' )
          .css ( 'background-size', 'cover' )
          .css ( 'background-position', 'center center' )
          .find ( 'i' )
            .remove();
      },
    });
  },

  removeProfilePhoto : function ( userID ) {
    // var sendData = {
    //   userID : userID,
    // };
    //
    // $.ajax({
    //   url: '/admin/AJAX-users/remove-profile-photo',
    //   type: 'post',
    //   data: sendData,
    //   dataType: 'json',
    //   success: function ( returnedData ) {
        $('#profile-photo')
          .css ( 'background-image', 'none' )
          .append ( '<i></i>' )
            .addClass ( 'fa fa-file-image' );
    //   },
    // });
  },

  shareData : {
    init : function() {
      MyAccount.shareData.refreshEvents();
    },

    refreshEvents : function() {
      $('.dd-inst-privacy')
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          MyAccount.shareData.setPrivacy ( $(this).attr ( 'data-il-inst-id' ), $(this).val() );
        });
    },

    setPrivacy : function ( IL_instID, privacySetting ) {
      var sendData = {
        IL_instID : IL_instID,
        privacySetting : privacySetting,
      };

      $.ajax({
        url: '/admin/AJAX-users/set-client-privacy',
        type: 'post',
        data: sendData,
        dataType: 'json',
        success: function ( returnedData ) {
          // console.log(returnedData);
          if ( returnedData.success ) {
            main.flashData.show (
              "Your privacy settings have been updated.",
              "positive",
              3
            );
          }
        },
      });
    },
  },
};
