var validate = {
  isInt : function ( input ) {
    return null !== String ( input ).match ( /^[0-9]+$/ );
  },

  isNumber : function ( input ) {
    return null !== String ( input ).match ( /^[0-9]+\.*[0-9]+$/ );
  },

  isFloat : function ( input ) {
    return null !== String ( input ).match ( /^[0-9]+\.[0-9]+$/ );
  },
};
