Athena.skins.bp = {
  showHeaderMarginTop : 0,
  showHeaderTop : '80px',
  hideHeaderMarginTop : 0,
  hideHeaderTop :'-80px',

  init : function() {
    Athena.clickNextToContinue.customShow = function() {
      $('.click-next-to-continue')
        .addClass ( 'in' )
        .removeClass ( 'out' );
    };

    Athena.clickNextToContinue.customHide = function() {
      $('.click-next-to-continue')
        .addClass ( 'out' )
        .removeClass ( 'in' );
    };
  },
};
