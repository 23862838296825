var DEBUG = {
  getStack : function() {
    var err = new Error();
    console.log(err.stack);
  },

  on : false,

  logger : {
    logMessages : [],

    refreshClickLogger : function() {
      $('body')
        .off ( 'click.clickLogger' )
        .on  ( 'click.clickLogger', function ( evt ) {
          DEBUG.logger.saveClick ( evt );
        });
    },

    saveClick : function ( evt ) {
      var sendData = {
        path       : window.location.href,
        x          : evt.pageX,
        y          : evt.pageY,
        width      : window.innerWidth,
        height     : window.innerHeight,
      };

      $.ajax({
        url: '/admin/AJAX-logger/log-click',
        type: 'post',
        data: sendData,
        dataType: 'json',
        success: function ( returnedData ) {

        },
      });
    },
  },
};

$(function() {
  if ( DEBUG.on ) {
    DEBUG.logger.refreshClickLogger();
  }
});
